import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@support/services/project.service';

@Component({
  selector: 'app-security-code-form',
  templateUrl: './security-code-form.component.html',
  styleUrls: ['./security-code-form.component.scss']
})
export class SecurityCodeFormComponent implements OnInit {

  @Input() displayCodeModal;
  @Output() showModal = new EventEmitter<boolean>;

  @Output() showLoading = new EventEmitter<boolean>;
  @Output() valid = new EventEmitter<boolean>;
  @Output() complete = new EventEmitter<any>;

  public form: FormGroup;
  constructor(
    private _project: ProjectService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({
      code: new FormControl(null, Validators.required),
    });
  }

  sendCode(): void {
    const data = {
      ...this.form.getRawValue()
    }

    const encryptedCode = localStorage.getItem('security_code');

    if (data?.code === encryptedCode) {
      this.showLoading.emit(false);
      this.showModal.emit(false);
      setTimeout(() => {
        this.complete.emit({status: true, message: 'Código de seguridad válido'});
        this.valid.emit(true);
      }, 4000);
    } else {
      this.complete.emit({status: false, message: 'El codigo de seguridad es invalido'});
      this.showModal.emit(false);
      this.valid.emit(false);

    }
  }

  closeModal(): void {
    this.showModal.emit(false);
  }
}
