import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigColumnsService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get (params: HttpParams|null = null): Observable<any> {
    let url = `${this.apiUrl}config-columns/list`;
    return this.http.get(url,{
      params: params ?? null
    });
  }

  createOrUpdate (body: any): Observable<any> {
    let url = `${this.apiUrl}config-columns/configure`;
    return this.http.post(url, body);
  }
}
