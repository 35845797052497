import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrashImageDirective } from './crash-image/crash-image.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CrashImageDirective],
  exports: [
    CrashImageDirective
  ],
})
export class DirectivesModule { }
