import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { StorageService } from 'app/core/services/storage.service';
import { Observable } from 'rxjs';
import { StorageService } from '../../tools/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotLoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageSrv: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      const isValidToken = this.storageSrv.getToken();

      console.log(isValidToken);

      if (isValidToken) {
        // navigate to dashboard page
        this.router.navigate(['/']);
        console.log('No lo deja pasar')
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
      } else {
        console.log('Lo deja pasar')
        return true;
      }
  }
}
