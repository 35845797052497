import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportsService } from '@support/services/reports.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PlanListComponent implements OnInit, OnChanges {
  @Input() list: any;
  @Input() business: any;
  @Input() permissions: any = false;
  @Input() paymentsPlan: any;
  public paymentQuotes: any;

  @Output() quotesForDelete = new EventEmitter<any>();
  @Output() totalPlan = new EventEmitter<any>();
  @Output() quotesList = new EventEmitter<object[]>();
  @Output() status = new EventEmitter<boolean>();
  @Output() paymentPlanDelete = new EventEmitter<boolean>();
  @Output() isSaveButtonAble = new EventEmitter<boolean>();
  @Output() reconciliate = new EventEmitter<boolean>();

  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public selectedRows: any[] = [];
  public disabledDelete: boolean = true;
  public disabledAdd: boolean = true;
  public showQuoteModal: boolean = false;
  public showPaymentForm: boolean = false;
  public deletePaymentPlan: boolean = false;
  public editing: boolean = false;
  public isSuccess: boolean = false;
  public totalAppliedAmount: number = 0;
  public items: any = [];

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private _reports:ReportsService,
  ) { }

  ngOnInit(): void {
    console.log(this.permissions);
    this.setColumns();
    this.setItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['paymentsPlan']) {
      const plan = changes['paymentsPlan'];
      if (!plan.previousValue && !plan.firstChange) {
        this.paymentQuotes = plan?.currentValue?.quotes;
      }
    }
    if(changes['list']) {
      const quotes = changes['list'];
      if (!quotes.previousValue && !quotes.firstChange) {
        const arr = quotes?.currentValue;
        console.log(arr);
        this.totalAppliedAmount = arr.reduce((total, item) => total + parseFloat(item.AppliedAmount), 0);
        console.log(this.totalAppliedAmount, 'total aplicado');
      }
    }
  }

  setColumns(): void {
    this.cols = [
      { field: 'number', header: 'Id Cuota', dataKey: 'number', editable: false },
      { field: 'name', header: 'Cuota', dataKey: 'name', editable: false },
      { field: 'date', header: 'Fecha', dataKey: 'date', editable: false },
      { field: 'amount', header: 'Monto', dataKey: 'amount', editable: true },
      { field: 'AppliedAmount', header: 'Monto aplicado', dataKey: 'AppliedAmount', editable: false },
      { field: 'balance', header: 'Balance', dataKey: 'balance', editable: false },
      { field: 'status', header: 'Estado', dataKey: 'status', editable: false }
    ];
    this.globalFilterFields = this.cols.filter(col => col.dataKey).map(col => col.dataKey);
  }

  selectRow(row): void {
    this.disabledDelete = this.selectedRows.length === 0 || false;
  }

  unselectRow(row): void {
    this.disabledDelete = this.selectedRows.length === 0 || false;
  }

  selectAllRows(e): void {
    this.disabledDelete = this.selectedRows.length === 0 || false;
  }

  deleteQuotes(): void {
    if(this.disabledDelete) return;
    this.confirmationService.confirm({
      message: 'Luego de eliminar cuotas ya no podrá recuperar la información de las mismas',
      header: 'Confirmación',
      accept: () => {
        this.quotesForDelete.emit(this.selectedRows);
        this.list = this.list?.filter((item) => !this.selectedRows.includes(item));
        this.calculateDiff();
        this.disabledDelete = true;
        this.calculateApplications();
      },
      reject: (type) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            console.log(type);
            break;
            case ConfirmEventType.CANCEL:
              console.log(type);
            break;
        }
      }
    });
  }

  openCreateQuoteModal(): void{
    if(!this.permissions.update) return;
    this.showQuoteModal = true;
  }

  closeQuoteModal(): void {
    this.showQuoteModal = false;
  }

  addQuote($event): void {
    const quote = $event;
    let insertIndex = 0;
    for (let i = 0; i < this.list.length; i++) {
      if (new Date(quote.date) < new Date(this.list[i].date)) {
        insertIndex = i;
        break;
      }
    }
    this.list.splice(insertIndex, 0, quote);
    this.calculateDiff();
    this.calculateApplications();
  }

  createPlan(): void {
    if(!this.permissions.delete) return;
    this.confirmationService.confirm({
      message: 'Al crear un nuevo plan el actual será eliminado',
      header: 'Confirmación',
      accept: () => {
        this.showPaymentForm = true;
      },
      reject: (type) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            console.log(type);
            break;
            case ConfirmEventType.CANCEL:
              console.log(type);
            break;
        }
      }
    });
  }

  paymentsConciliation(): void {
    this.reconciliate.emit(true);
  }

  setCreationStatus(e): void {
    this.isSuccess = e;
    this.status.emit(this.isSuccess);
  }

  deletePlan() {
    if (!this.permissions.delete) return;
    this.confirmationService.confirm({
      message: '¿Estás seguro de que deseas eliminar el plan de pago?',
      header: 'Confirmación',
      accept: () => {
        this.paymentPlanDelete.emit(true);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log('Eliminación cancelada');
            break;
          case ConfirmEventType.CANCEL:
            console.log('Diálogo de confirmación cancelado');
            break;
        }
      }
    });
  }

  editingAmount(e): void {
    this.editing = true;
  }

  completeAmount(e): void {
    this.calculateApplications(e.index, e.data);
  }

  calculateApplications(index: number = null, amount: any = null): void {
    // const selected = this.list[i];
    // const newAmount: number = parseFloat(e);

    let totalPaid: number = 0;

    this.list?.forEach((element, i) => {
      if(totalPaid < this.totalAppliedAmount) {
        let remainingAmount = parseFloat(element?.amount) - (this.totalAppliedAmount - totalPaid);
        if(remainingAmount <= 0) {
          element.status = 'Pagada';
          element.balance = 0;
          element.AppliedAmount = parseFloat(element?.amount);
          totalPaid += parseFloat(element?.amount);
        } else {
          element.status = 'Parcial';
          element.balance = remainingAmount;
          element.AppliedAmount = this.totalAppliedAmount - totalPaid;
          totalPaid += this.totalAppliedAmount;
        }
      } else {
        element.status = 'Pendiente';
        element.balance = parseFloat(element?.amount);
        element.AppliedAmount = 0;
      }
      console.log({element, i, totalPaid});
    });
    this.editing = false;
    this.calculateDiff(index, amount);
  }


  recalculateUp(index: number, newAmount: number): void {
    let remainingAmount = newAmount;
    const currentIndex = index;

    // Recalcular el monto aplicado de la cuota actual
    this.list[currentIndex].AppliedAmount = newAmount;
    this.list[currentIndex].balance = 0;

    // Recalcular el monto aplicado de las cuotas posteriores
    for (let i = currentIndex + 1; i < this.list.length; i++) {
      if (remainingAmount > 0) {
        const remainingBalance = this.list[i].amount - this.list[i].AppliedAmount;
        const amountToApply = Math.min(remainingBalance, remainingAmount);
        this.list[i].AppliedAmount =
          this.list[i].AppliedAmount === this.list[i].amount
          ? this.list[i].AppliedAmount : this.list[i].AppliedAmount < this.list[i].amount ;
        this.list[i].balance -= amountToApply;
        remainingAmount -= amountToApply;
      } else {
        break;
      }
    }

    // Verificar que la suma de los montos aplicados siga siendo igual al totalAppliedAmount
    const newTotalAppliedAmount = this.list.reduce((total, item) => total + parseFloat(item.AppliedAmount), 0);
    if (newTotalAppliedAmount !== this.totalAppliedAmount) {
      console.error('Error en los cálculos del monto aplicado');
    }
  }

  setValue(e, i): void {
    const selected = this.list[i];
    const newAmount: number = parseFloat(e);

    if (newAmount < selected.AppliedAmount) {
      // Si el nuevo monto es menor al monto aplicado, recalculamos el monto aplicado
      this.recalculateAppliedAmount(i, newAmount);
    } else {
      // Si el nuevo monto es mayor o igual al monto aplicado, actualizamos el balance
      let totalAppliedAmountBeforeCurrent = 0;
      for (let j = 0; j < i; j++) {
        totalAppliedAmountBeforeCurrent += parseFloat(this.list[j].AppliedAmount);
      }
      if (totalAppliedAmountBeforeCurrent + newAmount <= this.totalAppliedAmount) {
        this.list[i].balance = newAmount - this.list[i].AppliedAmount;
      } else {
        this.recalculateAppliedAmount(i, newAmount);
      }
    }

    this.calculateDiff();
  }

  recalculateAppliedAmount(index: number, newAmount: number): void {
    let remainingAmount = newAmount;
    const currentIndex = index;
    let totalAppliedAmountBeforeCurrent = 0;

    // Calcular el monto aplicado total hasta la cuota actual
    for (let i = 0; i < currentIndex; i++) {
      totalAppliedAmountBeforeCurrent += parseFloat(this.list[i].AppliedAmount);
    }

    // Recalcular el monto aplicado de la cuota actual
    this.list[currentIndex].AppliedAmount = newAmount;
    this.list[currentIndex].balance = 0;

    // Verificar si el monto aplicado de la cuota actual sumado al monto aplicado anterior es menor o igual a totalAppliedAmount
    if (totalAppliedAmountBeforeCurrent + newAmount <= this.totalAppliedAmount) {
      // Si es así, recorrer las cuotas posteriores y restarles el monto aplicado
      for (let i = currentIndex + 1; i < this.list.length; i++) {
        const amountToSubtract = Math.min(this.list[i].AppliedAmount, newAmount - remainingAmount);
        this.list[i].AppliedAmount -= amountToSubtract;
        this.list[i].balance += amountToSubtract;
        remainingAmount -= amountToSubtract;
        if (remainingAmount <= 0) {
          break;
        }
      }
    } else {
      // Si no, recorrer las cuotas posteriores y aplicar el monto restante
      for (let i = currentIndex + 1; i < this.list.length; i++) {
        if (remainingAmount > 0) {
          const remainingBalance = this.list[i].amount - this.list[i].AppliedAmount;
          const amountToApply = Math.min(remainingBalance, remainingAmount);
          this.list[i].AppliedAmount += amountToApply;
          this.list[i].balance -= amountToApply;
          remainingAmount -= amountToApply;
        } else {
          break;
        }
      }
    }

    // Verificar que la suma de los montos aplicados siga siendo igual al totalAppliedAmount
    const newTotalAppliedAmount = this.list.reduce((total, item) => total + parseFloat(item.AppliedAmount), 0);
    if (newTotalAppliedAmount !== this.totalAppliedAmount) {
      console.error('Error en los cálculos del monto aplicado');
    }
  }

  calculateDiff(index: number = null, amount: any = null): void {
    const total = this.list?.map(el => parseFloat(el.amount));
    let totalAmount = total?.reduce((acc, curr) => acc + curr, 0);
    let balance = this.business?.property?.price_base - (totalAmount ?? 0);
    balance = parseFloat(balance.toFixed(2));
    if (balance <= -1 && balance >= 1) {
      this.disabledAdd = false;
      this.isSaveButtonAble.emit(true);
    } else {
      this.disabledAdd = balance === 0;

      let quotesAmountDiff = false;
      if (this.paymentQuotes !== this.list && balance > -1 && balance < 1) {
        this.isSaveButtonAble.emit(false);
      } else if (this.paymentQuotes === this.list && index !== null && amount !== null) {
        quotesAmountDiff = parseFloat(amount) !== this.list[index]?.amount;
        this.isSaveButtonAble.emit(!quotesAmountDiff || !(balance > -1 && balance < 1));
      } else {
        this.isSaveButtonAble.emit(true);
      }
    }

    totalAmount = parseFloat((totalAmount ?? 0).toFixed(2));
    console.log(totalAmount);
    this.totalPlan.emit(totalAmount);
    this.quotesList.emit(this.list);
  }

  setItems() {
    this.items = [
      {
        label: 'Detallado',
        icon: 'pi pi-users',
        command: () => {
          this.getStatement({ type: 1 });
        },
      },
      {
        label: 'Resumen',
        icon: 'pi pi-users',
        command: () => {
          this.getStatement({ type: 2 });
        },
      },
    ];
  }

  getStatement({ type }): void {
    const data: any = {
      client_id: this.business?.client?.id,
      property_id: this.business?.property?.id,
      subscription_id: this.business?.subscription?.id,
      type: type,
    };

    this.showInfo({
      sticky: true,
      severity: 'info',
      summary: 'Exportando estado de cuenta',
      detail: 'El estado de cuenta está siendo generado, por favor espere',
    });

    this._reports.generateAccountStatement(data).subscribe(
      (res: Blob) => {
        if (this.isMobileDevice()) {
          // Descargar el archivo PDF en dispositivos móviles
          saveAs(res);
          this.clear();
          this.showInfo({
            sticky: true,
            severity: 'success',
            summary: 'Estado de cuenta generado',
            detail: 'El estado de cuenta se ha generado correctamente',
          });
        } else {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.clear();
          this.showInfo({
            sticky: true,
            severity: 'success',
            summary: 'Estado de cuenta generado',
            detail: 'El estado de cuenta se ha generado correctamente',
          });
        }

      },
      (err: HttpErrorResponse) => {
        this.clear();
        if (err.error?.message) {
          this.showInfo({
            sticky: false,
            severity: 'warn',
            summary: 'Advertencia',
            detail: err.error.message,
          });
        } else {
          this.showInfo({
            sticky: false,
            severity: 'warn',
            summary: 'error',
            detail: "No hay cobros conciliados para este negocio",
          });
        }
      }
    );
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

}
