import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { typesDocumentsService } from '@support/services/typesDocuments.service'
import { PropertyService } from '@support/services/property.service'
import { ClientsService } from '@support/services/clients.service'
import { TileStyler } from '@angular/material/grid-list/tile-styler';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit, OnChanges {

  public PROPERTY_MODULE = 10;
  public CLIENT_MODULE = 7;

  @Input() visible: boolean = false;
  @Input() id: number;
  @Input() moduleId: number;

  @Output() success = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  @Output() failed = new EventEmitter<string>();

  public form: FormGroup;
  public options: Object[];
  public loading: boolean = true;


  constructor(
    private fb: FormBuilder,
    private _typesD: typesDocumentsService,
    private _properties: PropertyService,
    private _clients: ClientsService,
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.hasOwnProperty('id') && changes['id'].currentValue !== null) {
      await this.getTypesDocuments();
      this.createForm();
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      id: [null, Validators.required],
      description: [null, Validators.required],
      document: [null, Validators.required],
    });

    this.form.get('id').setValue(this.id);
    this.loading = false;
  }

  getTypesDocuments(): void {
    this._typesD.get().subscribe(
      (response: any) => {
        console.log(response);
        this.options = response?.data;
      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  // changeOption(e: any): void {
  //   this.form.get('type_document_id').setValue(e.value);
  // }

  onSelectFile(event: any) {
    const file: File = event.currentFiles[0];
    this.form.get('document').setValue(file);
  }

  onRemoveFile () {
    this.form.get('document').setValue(null);
  }

  saveDocument(): void {
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false;
      console.error('Formulario inválido:', this.form.errors);
      console.log('primero error log');
      return;
    }

    const formData = new FormData();
    let url = null;

    formData.append('description', this.form.get('description')?.value);
    formData.append('document', this.form.get('document')?.value);

    switch (this.moduleId) {
      case this.PROPERTY_MODULE:
        formData.append('property_id', this.form.get('id')?.value);
        url = this._properties.uploadDocument(formData);
        break;
      case this.CLIENT_MODULE:
        formData.append('client_id', this.form.get('id')?.value);
        url = this._clients.uploadDocument(formData);
        break;
      default:
        break;
    }

    url.subscribe(
      (response: any) => {
        if (response?.data) {
          this.success.emit(true);
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log({error});
        if (error?.error?.message) {
          console.log(error);
          console.log('error en el front');
          this.failed.emit(error?.error?.message);
          this.loading = false;
          return;
        }
        this.success.emit(false);
        this.loading = false;
        console.log(error, 'error 3');
      }
    );
  }

  closeModal(): void {
    this.close.emit(true);
  }

}
