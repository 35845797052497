import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { CollectionService } from '@support/services/collection.service';
import { MioService } from '@support/services/mio.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { ApproveChargesComponent } from '../approve-charges/approve-charges.component';
import { CancelChargesComponent } from '../cancel-charges/cancel-charges.component';
import { RejectChargesComponent } from '../reject-charges/reject-charges.component';
import { Collection } from '@support/models/collection.model';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api'
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ApprovalPaymentsService } from '@support/services/approvalPayments.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-list-by-business',
  templateUrl: './list-by-business.component.html',
  styleUrls: ['./list-by-business.component.scss'],
  providers: [MessageService, DialogService]
})
export class ListByBusinessComponent implements OnInit, OnChanges {

  @Input() collections: any;
  @Input() canEdit: any;
  @Input() canCreate: any;
  @Input() user: any;
  @Input() business: any;

  @Output() complete = new EventEmitter<boolean>();
  @Output() chekoutModal = new EventEmitter<any>();
  @Output() orderStatus = new EventEmitter<any>();

  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public isLoading = false;
  public showRateModal = false;
  public selectedCollection: any = null;

  constructor(
    private _collections:CollectionService,
    private _mio:MioService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private approvalPaymentsService: ApprovalPaymentsService,
    private datePipe: DatePipe,
    @Inject(HttpClient) private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['collections']) {
      this.setColumns();
      console.log(this.collections, 'collections');
    }
  }
  
  identifyFormatDate(date: string): string {
    if (date.charAt(4) === '/') {
      return 'YYYY/MM/DD';
    }

    if (date.charAt(2) === '/') {
      return 'DD/MM/YYYY';
    }

    if (date.charAt(4) === '-') {
      return 'YYYY-MM-DD';
    }

    if (date.charAt(2) === '-') {
      return 'DD-MM-YYYY';
    }

    else if (date.indexOf('T') !== -1) {
      return 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }

    else {
      return null;
    }
  }
  
  convertDate(date: string): string {
    const formato = this.identifyFormatDate(date);
    return moment(date, formato).format('DD/MM/YYYY');
  }

  setColumns(): void {
    this.collections.forEach(element => {
      console.log('element.transaction_date: ',element.transaction_date)
      element.transaction_date = this.convertDate(element.transaction_date);
      element.bankName = element.bank.name;
      element.account = element.bank_account.number_account;
      element.type = element.collections_type.name;
      element.status = element.collection_status.name;
      element.amount = this.formatCurrency(element.amount);
    });
    this.cols = [
      { field: 'transaction_date', header: 'Fecha de transacción', dataKey: 'transaction_date' },
      { field: 'bankName', header: 'Banco', dataKey: 'bankName' },
      { field: 'account', header: 'Cuenta Bancaria', dataKey: 'account' },
      { field: 'type', header: 'Tipo de pago', dataKey: 'type' },
      { field: 'amount', header: 'Monto', dataKey: 'amount' },
      { field: 'status', header: 'Estado', dataKey: 'status' }
    ];
    this.globalFilterFields = this.cols.filter(col => col.dataKey).map(col => col.dataKey);
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return decodeURIComponent(fileName);
    }
    return '';
  }

  async downloadFile(fileUrl: string) {
    // if (!this.isMobileDevice()) {
    //   const headers = new HttpHeaders({ 'Accept': 'image/jpeg, image/png' });
    //   this.http.get(fileUrl, { headers: headers, responseType: 'blob' })
    //   .subscribe(response => {
    //     const blob = response;
    //     const file = new File([blob], this.getFileNameFromUrl(fileUrl));
    //     saveAs(file, this.getFileNameFromUrl(fileUrl));
    //   });
    //   this.clear();
    //   this.showInfo({
    //     sticky: true,
    //     severity: 'success',
    //     summary: 'Comprobante de pago generado',
    //     detail: 'El comprobante de pago se ha generado correctamente'
    //   });
    // } else {
      let a: any = document.createElement('a');
      a.download = true;
      a.target = '_blank';
      a.href= fileUrl;
      a.click();
    // }
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  generatePaymentReceipt(id: any): void {
    this._collections.getPdfVoucher(id).subscribe((response: Blob) => {
      if (this.isMobileDevice()) {
        // Descargar el archivo PDF en dispositivos móviles
        saveAs(response);
        this.clear();
        this.showInfo({
          sticky: true,
          severity: 'success',
          summary: 'Recibo generado',
          detail: 'El recibo se ha generado correctamente'
        });
      } else {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.clear();
        this.showInfo({ sticky: true, severity: 'success', summary: 'Recibo generado', detail: 'El recibo se ha generado correctamente' });
      }
    });
  }


  openApprovalPaymentDialog(collectionId: number) {
    console.log('conciliar 2');
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    const approvalDialog = this.dialogService.open(ApproveChargesComponent, {
      data: {
        collection,
        business: this.business
      },
      header: 'Conciliación de cobro',
      width: '50vw',
      height: '80vh',
      maximizable: true,
    });

    approvalDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
  }

  openRejectionPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    const rejectDialog = this.dialogService.open(RejectChargesComponent, {
      data: {
        collection
      },
      header: 'Rechazo de cobro',
      width: '50vw',
      maximizable: true,
    });

    rejectDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
  }

  openCancelPaymentDialog(id: number) {
    console.log(id);
    const collection = this.collections.find((item: Collection) => item.id === id);
    this.isLoading = true;
    const cancelDialog = this.dialogService.open(CancelChargesComponent, {
      data: {
        collection
      },
      header: 'Anular cobro',
      width: '50vw',
      maximizable: true,
    });
    cancelDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    });
    this.isLoading = false;
  }

  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

  showOrderStatus(collection: any): void {
    console.log(collection);
    const credentials = collection?.mioCredentials;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.verifyOrder(mioData, collection?.order?.uuid).subscribe(
      (res: any) => {
        console.log(res);
        const order = res?.data;
        if (order) {
          if (order?.attributes?.status === 'SUCCESS') {
            this.approvePayment(collection, order?.attributes, collection?.order?.rate);
            console.log('la orden se proceso correctamente conciliar');
          } else if (order?.attributes?.status === 'EXPIRED') {
            this.orderStatus.emit({status: 'expired'});
          } else {
            this.orderStatus.emit({status: 'pendding'});
          }
          this._mio.updateOrder(collection?.order?.id, order?.attributes?.status).subscribe(
            (res: any) => {
              console.log(res);
              if(res?.ok) {
                this.complete.emit(true);
              }
            },
            (error: any) => {
              console.log(error);
              this.complete.emit(true);
            }
          );
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  approvePayment(collection: any, order: any, rate: any): void {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const day = String(date.getDate()).padStart(2, '0');


    const formattedDate = `${year}-${month}-${day}`;
    const formattedAmount = parseFloat((order?.price?.amount / 100).toFixed(2));

    if (rate === '1.00' && collection?.convertion_rate !== '1.00') {
      rate = collection?.convertion_rate;
    }
    let conciliateData = {
      receipt_number: null,
      transaction_date: formattedDate,
      transaction_number: order?.payment?.reference_number,
      received_amount: formattedAmount,
      currency_id: 2,
      rate: rate,
      total_amount: collection?.amount,
      collection_id: collection?.id
    }
    this.approvalPaymentsService.get(collection?.id).subscribe(
      (res:any)=> {
        conciliateData.receipt_number = res.receipt_number;
        this.approve(conciliateData);
      },
      (err:any)=>{
        console.log(err);
      }
    );
  }

  approve(data: any): void {
    this.approvalPaymentsService.create(data).subscribe(
      async(res: any) => {
        if (res.data) {
          setTimeout(() => {
            this.orderStatus.emit({status: 'conciliate'});
            this.complete.emit(true);
          }, 4000);
        }
      },
      (err: any) => {
        if (err.error) {
          this.orderStatus.emit({status: 'conciliation failed'});
          console.log(err);
        } else {
          this.orderStatus.emit({status: 'conciliation failed'});
          console.log(err);
        }
      }
    )
  }

  createOrder(collection: any): void {
    console.log(collection, 'cobro');
    let { order, letters_amount, convertion_rate, transaction_amount, amount } = collection || {};
    let rate, amountValue;

    if (!order) {
        if (letters_amount.includes('Dollar')) {
            if (convertion_rate !== '1.00') {
                rate = convertion_rate;
                amountValue = transaction_amount;
            } else {
              this.showRateModal = true;
              this.selectedCollection = collection;
              return;
            }
        } else {
            rate = 1;
            amountValue = amount;
        }
    } else {
        amountValue = order.amount;
        rate = order.rate;
    }
    const credentials = collection?.mioCredentials;
    collection.mio_amount = amountValue;
    collection.mio_rate = rate;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.getMioToken(mioData, collection).subscribe(
      (res: any) => {
        console.log(res);
        if (res?.displayModal) {
          this.chekoutModal.emit({displayModal: res?.displayModal, paymentLink: res?.link});
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  saveOrder(e: any): void {
    if (!this.selectedCollection) {
      return;
    }
    this.selectedCollection.mio_amount = e?.amount;
    this.selectedCollection.mio_rate = e?.rate;
    const credentials = this.selectedCollection?.mioCredentials;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.getMioToken(mioData, this.selectedCollection).subscribe(
      (res: any) => {
        console.log(res);
        if (res?.displayModal) {
          this.chekoutModal.emit({displayModal: res?.displayModal, paymentLink: res?.link});
          // this.completeTransaction(true, res?.displayModal, res?.link);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    console.log(e, 'order 356');
  }

  formatCurrency(numero, locale = 'en-US') {
    if (numero.indexOf(',')<0) {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 2
      });
      return formatter.format(numero);
    }
    return numero;
  }
}
