<p-dialog [header]="header" [(visible)]="visible" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false" (onHide)="hideModal()">
  <ng-template pTemplate="content">
    <form style="" [formGroup]="form" class="team-sales-form">
      <div class="row">

        <div *ngIf="!isEdit" class="col-12 col-md-6 my-2 md:my-0 w-100">
          <div class="flex flex flex-column gap-2">
            <label for="description">Cliente</label>
            <p-listbox
              [(ngModel)]="client_id"
              formControlName="client_id"
              [options]="clientsOptions"
              optionLabel="name"
              optionValue="id"
              [placeholder]="getOptionName(clientsOptions, client_id)"
              [filterPlaceHolder]="getOptionName(clientsOptions, client_id)"
              (onChange)="changeUsers($event)"
              [filter]="true"
              filterBy="name"
              styleClass="h-200"
              [listStyle]="{'max-height':'130px'}"
            >
              <ng-template pTemplate="footer"> 
                <button class="w-full bg-[#2d3e50] text-[white] text-center p-2" (click)="createClient()" ><i class="pi pi-plus" ></i> Crear nuevo cliente</button>
              </ng-template>
            </p-listbox>
          </div>
        </div>

      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="save()" label="Guardar" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
