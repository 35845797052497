import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BusinessService } from '@support/services/business.service';
import { ClientsService } from '@support/services/clients.service';
import { SalesTeamsService } from "@support/services/salesTeams.service";
import { UsersService } from '@admin/services/users.service';
import { TasksService } from '@support/services/tasks.service';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-detail-task',
  templateUrl: './detail-task.component.html',
  styleUrls: ['./detail-task.component.scss'],
  providers: [MessageService]
})
export class DetailTaskComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() selectedTask: any = null;
  @Input() moduleId: any = null;
  @Input() objectId: any = null;
  @Input() messageService: MessageService = null;

  @Output() isShowing = new EventEmitter<boolean>();
  @Output() complete = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();

  constructor(
    // private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('visible') && changes['visible'].currentValue !== null && changes['visible'].currentValue) {
      if (this.visible && this.selectedTask) {
        this.setMessageAlert()
      } else {

      }
    }

    if (changes.hasOwnProperty('selectedTask') && changes['selectedTask'].currentValue !== null && changes['selectedTask'].currentValue) {
      if (this.selectedTask?.sales_team) {
        this.selectedTask.salesTeams = this.selectedTask?.sales_team;
      }
    }
  }

  setMessageAlert(): void {
    // let resp = this.getColorByDate(this.selectedTask?.expiration_date)

    if (this.selectedTask?.expiration_message.indexOf("Su tarea expiró") >= 0) {
      this.messageService.add({ sticky: true, severity: 'error', summary: 'Tarea vencida', detail: this.selectedTask?.expiration_message });
    } else if (this.selectedTask?.expiration_message.indexOf("Su tarea vencerá") >= 0) {
      this.messageService.add({ sticky: true, severity: 'warn', summary: 'Tarea por vencer', detail: this.selectedTask?.expiration_message });
    }
  }

  hideModal(): void {
    this.visible = false;
    this.isShowing.emit(this.visible);
  }

  getColorByDate(dateString) {
    try {
      const date = moment(dateString);
      const today = moment();

      if (date.isBefore(today)) {
        return 2;
      } else if (date.diff(today, 'days') <= 1) {
        return 1;
      } else {
        return 0;
      }

    } catch (error) {
      console.log('error: ', error)
      return 0;
    }
  }

}
