import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-card-payment-rate',
  templateUrl: './card-payment-rate.component.html',
  styleUrls: ['./card-payment-rate.component.scss']
})
export class CardPaymentRateComponent implements OnInit, OnChanges {

  @Input() displayModal;
  @Input() collection;

  @Output() orderData = new EventEmitter<any>();
  @Output() showModal = new EventEmitter<boolean>();

  public form: FormGroup;


  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void{
    console.log(changes);
    if(changes.hasOwnProperty('collection') && changes['collection'].currentValue){
      // this.paymentLink = changes['paymentLink'].currentValue;
      this.createForm();
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      collection_amount: new FormControl(null),
      rate: new FormControl(1),
      amount: new FormControl(null),
    });

    this.form.controls['amount'].disable();
    this.form.controls['collection_amount'].disable();
    this.form.get('collection_amount').setValue(this.collection?.amount);
    this.subscribeToFormChanges()
  }

  subscribeToFormChanges(): void {
    this.form.get('collection_amount').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('rate').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });
  }

  calculateTotalAmount(): void {
    const receivedAmount: number = this.form.get('collection_amount').value;
    const rate: number = this.form.get('rate').value;
    let totalAmount: number = 0;

    totalAmount = receivedAmount * rate;

    this.form.get('amount').setValue(totalAmount);
  }

  save(): void {
    const data = {
      ...this.form.getRawValue()
    }

    this.orderData.emit(data);
    this.close();
  }

  close(): void {
    this.showModal.emit(false);
  }

}
