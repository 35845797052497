import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }


get(params: HttpParams|null = null): Observable<any>{
  const url = `${this.apiUrl}logs/list`;

  return this.http.get(url,{
    params: params ?? null
  });
}

}
