import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PaymentsPlanService } from '@support/services//payments-plan.service';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import moment from 'moment-timezone';


@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PaymentPlanComponent implements OnInit, OnChanges {

  @Input() business: any;
  @Input() paymentsPlan: any;
  @Input() permissions: any;

  @Output() status = new EventEmitter<boolean>();
  @Output() isSaveButtonAble = new EventEmitter<boolean>();

  public form: FormGroup;
  public diff: any = null;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _PaymentPlan: PaymentsPlanService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.hasOwnProperty('paymentsPlan') && changes['paymentsPlan'].currentValue){
      this.createForm();
    }
  }

  async createForm(): Promise<void> {
    this.loading = true;
    this.form = this.fb.group({
      property_value: ['', Validators.required],
      final_value: ['', Validators.required],
      total_amount: ['', Validators.required],
      diference: ['', Validators.required],
    });

    if(this.paymentsPlan) {
      this.form.patchValue({
        property_value: this.business?.property?.price_base,
        final_value: this.business?.property?.price_base,
        total_amount: this.paymentsPlan ? parseFloat(this.paymentsPlan?.total_amount).toFixed(2) : 0,
        diference: this.paymentsPlan ? this.business?.property?.price_base - parseFloat(this.paymentsPlan?.total_amount) : this.business?.property?.price_base,
      });

      this.diff = this.form.get('diference').value;

      this.form.controls['property_value'].disable();
      this.form.controls['final_value'].disable();
      this.form.controls['total_amount'].disable();
      this.form.controls['diference'].disable();

      this.paymentsPlan?.quotes?.forEach(element => {
        element.date = this.convertDate(element.date);
        if (!element.AppliedAmount) {
          element.AppliedAmount = 0;
          element.balance = element.amount;
        }
      });
    }

    this.loading = false;

  }

  deleteQuotes(e): void {
    this.paymentsPlan.quotes = this.paymentsPlan?.quotes?.filter(el => !e.includes(el));
    const total = this.paymentsPlan.quotes.map(el => parseFloat(el.amount));
    const totalAmount = total.reduce((acc, curr) => acc + curr, 0);
    this.setTotalPlan(totalAmount);
    this.setQuotes(this.paymentsPlan.quotes);
  }

  setTotalPlan($event): void {
    this.diff = this.business?.property?.price_base - $event;
    this.diff = parseFloat(this.diff.toFixed(2));
    console.log({diff: this.diff});
    this.form?.get('total_amount')?.setValue($event);
    this.form?.get('diference')?.setValue(this.diff);
  }

  setQuotes($event): void {
    this.paymentsPlan.quotes = $event;
  }

  emitStatus(e): void {
    this.status.emit(e);
  }

   deletePaymentPlan(e): void {
    this.loading = true;
    this._PaymentPlan.delete(this.business.id).subscribe(
      (response) => {
        if(response) {
          this.status.emit(true);
          this.showNotify('success', 'Completado', 'El plan de pagos se ha eliminado con éxito');
        }
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      },
      (error) => {
        this.showNotify('error', 'Falló', 'Ha ocurrido un error al eliminar el plan de pagos');
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      }
    );
  }

  setSaveButtonstatus(e): void {
    this.isSaveButtonAble.emit(e);
  }

  showNotify(severity, summary, detail): void {
    this.messageService.add({key: 'tr', severity: severity, summary: summary, detail: detail});
  }

  reconciliation(e): void {
    this.loading = true;
    this._PaymentPlan.reconciliate(this.paymentsPlan?.id).subscribe(
      (response) => {
        console.log(response);
        if(response) {
          this.status.emit(true);
          this.showNotify('success', 'Completado', 'Se ha completado la reconciliacion con éxito');
        }
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      },
      (error) => {
        this.showNotify('error', 'Falló', 'No se pudo reconciliar');
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      }
    );
  }
  
  identifyFormatDate(date: string): string {
    if (date.charAt(4) === '/') {
      return 'YYYY/MM/DD';
    }

    if (date.charAt(2) === '/') {
      return 'DD/MM/YYYY';
    }

    if (date.charAt(4) === '-') {
      return 'YYYY-MM-DD';
    }

    if (date.charAt(2) === '-') {
      return 'DD-MM-YYYY';
    }

    else if (date.indexOf('T') !== -1) {
      return 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }

    else {
      return null;
    }
  }
  
  convertDate(date: string): string {
    const formato = this.identifyFormatDate(date);
    return moment(date, formato).format('DD/MM/YYYY');
  }
}
