import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargesComponent } from './charges.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ApproveChargesModule } from './approve-charges/approve-charges.module';
import { CancelChargesModule } from './cancel-charges/cancel-charges.module';
import { RejectChargesModule } from './reject-charges/reject-charges.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { ConfigureTableModule } from '../settings/configure-table/configure-table.module';
import { ListByBusinessComponent } from './list-by-business/list-by-business.component';
import { CardPaymentRateComponent } from './card-payment-rate/card-payment-rate.component';
import {DialogModule} from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MioComponentModule } from '@support/pages/business/mio-component/mio-component.module';

@NgModule({
  declarations: [
    ChargesComponent,
    ListByBusinessComponent,
    CardPaymentRateComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    DividerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    TableModule,
    ToolbarModule,
    TooltipModule,
    DynamicDialogModule,
    SplitButtonModule,
    ToastModule,
    DialogModule,
    InputNumberModule,
    MioComponentModule
  ],
  entryComponents: [
    ApproveChargesModule,
    RejectChargesModule,
    CancelChargesModule,
    ConfigureTableModule
  ],
  exports: [
    ListByBusinessComponent,
    CardPaymentRateComponent
  ]
})
export class ChargesModule { }
