<p-dialog
  [header]="header"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <ng-template pTemplate="content">
    <app-spinner *ngIf="loading"></app-spinner>
    <form *ngIf="!loading" [formGroup]="form" class="team-sales-form">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="flex-column gap-2 pt-3">
            <span class="p-float-label">
              <p-dropdown
                appendTo="body"
                id="type_document_id"
                class="w-full flex"
                placeholder="Tipo de documento"
                aria-describedby="type_document_id-help"
                formControlName="type_document_id"
                [options]="typesDocuments"
                (onChange)="changeType($event)"
                optionLabel="name"
                optionValue="id"
              ></p-dropdown>
              <label for="type_document_id">Tipo de documento</label>
            </span>
            <small *ngIf="!showTypeInput" id="type_document_id-help" class="text-danger small">{{
              getError("type_document_id")
            }}</small>
          </div>
        </div>

        <div *ngIf="showTypeInput" class="col-12 col-md-12 col-lg-12">
          <div class="flex-column gap-2 pt-3">
            <span class="p-float-label">
              <input
                id="description"
                type="text"
                pInputText
                class="w-100"
                formControlName="description">
              <label for="description">Tipo de documento</label>
            </span>
            <small id="description-help" class="text-danger small">{{
              getError("description")
            }}</small>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12 my-2 md:my-0">
          <p-fileUpload
            id="document"
            [showUploadButton]="false"
            chooseLabel="Subir documento"
            accept="image/*,application/pdf"
            (onSelect)="onSelectFile($event)"
            (onClear)="onRemoveFile()"
            (onRemove)="onRemoveFile()"
          >
          </p-fileUpload>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="save()"
      label="Guardar"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
