<p-dialog header="Orden de pago" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
[draggable]="false" [resizable]="false" [closable]="false" [closeOnEscape]="false">
  <h5 style="color: cornflowerblue;">* Complete los datos soliticados a continuación</h5>
    <form *ngIf="form" [formGroup]="form">
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-4 mt-2">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <p-inputNumber
                id="collection_amount"
                type="text"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                [min]="0"
                class="w-100"
                formControlName="collection_amount">
                </p-inputNumber>
              <label for="collection_amount">Monto del cobro</label>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-4 mt-2">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <p-inputNumber
                id="rate"
                type="text"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                [min]="0"
                class="w-100"
                formControlName="rate">
                </p-inputNumber>
              <label for="rate">Tasa de conversión</label>
            </span>
            <button type="button" pButton pRipple icon="pi pi-calculator" (click)="calculateTotalAmount()"></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-4 mt-2">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <p-inputNumber
                id="amount"
                type="text"
                pInputNumber
                mode="decimal"
                locale="en-US"
                [minFractionDigits]="2"
                [min]="0"
                class="w-100"
                formControlName="amount">
                </p-inputNumber>
              <label for="amount">Total a cobrar</label>
            </span>
          </div>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-send" label="Crear Orden" (click)="save()" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" label="Cerrar" (click)="close()" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
