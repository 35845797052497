import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
// import { SalesTeamsService } from "../../../services/salesTeams.service";
import { ProjectService } from '@support/services/project.service';
import { UsersService } from '@admin/services/users.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { UserData } from '@core/models/user.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';
import { PropertyService } from '@support/services/property.service';
import { HttpParams } from '@angular/common/http';
import { BusinessService } from '@support/services/business.service';

@Component({
  selector: 'app-create-transfer-unit',
  templateUrl: './create-transfer-unit.component.html',
  styleUrls: ['./create-transfer-unit.component.scss'],
})
export class CreateTransferUnitComponent implements OnInit, OnChanges {
  public allProjectsOptions: any[];
  public allPropertiesOptions: any[];
  public propertiesOptions: any[];
  public projectsOptions: any[];
  public selectedProjects: any[];
  public selectedProperties: any[];
  public project_id: any;
  public property_id: any;

  public isLoading = false;
  private _subcriptions: Record<string, Subscription> = {};

  @Input() name: string;
  @Input() description: string;
  @Input() visible: boolean = true;
  @Input() isEdit: boolean = false;
  @Input() business: any = null;
  @Input() businessAmount: number = 0;
  @Input() savedTeam: any = null;
  @Input() projects: any[] = null;

  @Output() emitVisible = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();

  public header: string = 'Cambio de clientes';
  public form: FormGroup;
  public currentUser: UserData;

  constructor(
    private fb: FormBuilder,
    private _project: ProjectService,
    private _users: UsersService,
    private _property: PropertyService,
    private _snackBar: NotificationsService,
    private _auth: AuthService,
    private _business: BusinessService
  ) {
    this._subcriptions['user'] = this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.setHeader();
    this.getProjects();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('business') &&
      changes['business'].currentValue !== null
    ) {
      this.business = changes['business'].currentValue;
      this.savedTeam = changes['business'].currentValue;
      this.businessAmount = this.getTotalCostofCollections(
        this.business.collections
      );
    } else {
      this.business = null;
    }

    if (
      changes.hasOwnProperty('visible') &&
      changes['visible'].currentValue !== null &&
      changes['visible'].currentValue
    ) {
      if (this.savedTeam) {
        this.business = this.savedTeam;
        this.completeForm();
      }
    }

    if (
      changes.hasOwnProperty('projects') &&
      changes['projects'].currentValue !== null
    ) {
      this.projectsOptions = this.projects
        .filter((item) =>
          item.id === this.business?.project?.id ||
          item.currency_id === this.business.subscription?.currency_id
        )
        .map((item) => {
          return {
            ...item,
            name: item.name_project,
          };
        });
    }

    this.setHeader();
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      project_id: [null, Validators.required],
      property_id: [null, Validators.required],
    });
    this.completeForm();
  }

  completeForm(): void {
    if (this.form && this.business) {
      this.form.patchValue({
        project_id: this.business?.project.id || null,
        property_id: this.business?.property.id || null,
      });

      this.project_id = this.business?.project.id || null;
      this.property_id = this.business?.property.id || null;

      this.getUnits();
    }
  }

  getOptionName(options, id): string {
    let name = '';

    let object = options.find((item) => item.id === id);

    name = object?.name;

    return name;
  }

  setHeader(): void {
    this.header = 'Cambio de unidad';
  }

  hideModal(): void {
    this.emitVisible.emit(false);
  }

  changeProject(data): void {
    this.project_id = data.value || null;
    this.propertiesOptions = [];

    if (this.project_id) {
      this.property_id = null;
      this.propertiesOptions = [];
      this.getUnits();
    }
  }

  changeUnit(data): void {
    this.property_id = data.value || null;
  }

  save(): void {
    if (this.form.invalid) {
      this._snackBar.openSnackBar(
        'Por favor, complete los datos del formulario.',
        'OK'
      );
      this.form.markAllAsTouched();
      return;
    }

    let data = {
      ...this.form.getRawValue(),
    };

    data.project_id = this.project_id || null;
    data.property_id = this.property_id || null;
    data.business_id = this.business?.id || null;

    console.log('data: ', data);

    const url = this._business.transferProperty(data);

    url.subscribe(
      (response) => {
        if (response.res) {
          this.isSuccess.emit(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.hideModal();
  }

  getProjects(): void {
    this._project.get().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allProjectsOptions = res.data.map((item) => {
            return {
              ...item,
              name: item.name_project,
            };
          });

          this.projectsOptions = this.allProjectsOptions;
        }
      },
      (err: any) => {
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.isLoading = false;
      }
    );
  }

  getTotalCostofCollections(collections: any[]): number {
    let result = 0;

    for (const iterator of collections) {
      result += +iterator?.amount || 0;
    }

    return result;
  }

  getUnits(): void {
    let query = {};

    if (this.project_id) {
      query['project_id'] = this.project_id;
      query['status'] = 1;
    }

    const params = new HttpParams({ fromObject: query });

    this._property.get(params).subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allPropertiesOptions = res.data
            .filter((item) =>
              item.id === this.business?.property?.id ||
              (item.status?.id === 1 &&
                (!this.business?.collections ||
                  this.business?.collections.length < 1 ||
                  item?.price_base >= this.businessAmount))
            )
            .map((item) => {
              return {
                ...item,
                name: item.name_property,
              };
            });

          this.propertiesOptions = this.allPropertiesOptions;
        }
      },
      (err: any) => {
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.isLoading = false;
      }
    );
  }

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
    }
    return '';
  }

  getErrorMessage(controlName: string): string {
    const field = this.form.get(controlName);

    if (!field || !field.invalid) return '';

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido';
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return field && field.invalid && field.touched;
  }
}
