import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadMainImagenComponent } from './upload-main-imagen.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';



@NgModule({
  declarations: [
    UploadMainImagenComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule
  ],
  exports: [
    UploadMainImagenComponent
  ],
})
export class UploadMainImagenModule { }
