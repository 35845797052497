<p-toast [baseZIndex]="20000"></p-toast>
<div class="card-body card" >
  <ng-container *ngIf="isLoading; else loadClient">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-template #loadClient>

  <p-table
    #dt
    styleClass="p-datatable-striped p-datatable-sm"
    [value]="filteredCollections"
    dataKey="id"
    editMode="row"
    [loading]="busy"
    [paginator]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}"
    [rows]="20"
    scrollHeight="370px"
    [scrollable]="true"
    [globalFilterFields]="globalFilterFields"
    >
    <ng-template pTemplate="caption">
      <p-toolbar>
        <div class="p-toolbar-group-left">

          <p-dropdown
            [options]="projects"
            [(ngModel)]="selectedProject"
            optionLabel="name_project"
            [filter]="true"
            filterBy="name_project"
            [showClear]="true"
            placeholder="Seleccione un proyecto"
            style="min-width: 50%; width: 50%; margin-right: 1rem;"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            (onChange)="onChangeProject()">
          </p-dropdown>

          <span class="p-input-icon-left max-w-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
          </span>

          <button
            pButton
            class="p-button-rounded p-button-text"
            icon="pi pi-filter-slash"
            (click)="clearFilters(dt)"
            pTooltip="Limpiar filtros"
            tooltipPosition="top">
          </button>
        </div>

        <div class="p-toolbar-group-right">
          <p-splitButton
            pRipple
            label="Exportar"
            icon="pi pi-upload"
            [model]="exportItems"
            styleClass="mr-1"
            [disabled]="!filteredCollections.length">
          </p-splitButton>
          <button
            pButton pRipple
            *ngIf="canCreate"
            icon="pi pi-plus"
            label="Nuevo"
            class="p-button-raised p-button-secondary"
            [routerLink]="['/create-charges']">
          </button>
        </div>
      </p-toolbar>
      <p-toolbar>
        <div class="p-toolbar-group-left gap-2 overflow-x-auto">
          <button *ngFor="let status of collectionStatuses"
            pButton
            pRipple
            type="button"
            [label]="status.name"
            [class]="'p-button-outlined p-button-rounded button-status '+ status.cssClass"
            [class.active]="selectedStatus?.id === status.id"
            [disabled]="!selectedProject"
            (click)="onChangeStatus(status)">
          </button>
        </div>
      </p-toolbar>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of columns">
          <th [hidden]="(col.hide && selectedStatus?.id !== 4) || (col.title === 'Acción' && selectedStatus?.id === 4)"
           [pSortableColumn]="col.sortable && col.dataKey">
            <div class="flex justify-content-between align-items-center">
              {{ col.title }}
              <span *ngIf="col.dataKey">
                <p-sortIcon *ngIf="col.sortable" [field]="col.dataKey"></p-sortIcon>
                <p-columnFilter *ngIf="col.filterable" type="text" [field]="col.dataKey" display="menu" class="ml-auto"></p-columnFilter>
              </span>
            </div>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-editing="editing" let-rowIndex="rowIndex">
      <tr [pEditableRow]="row">
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.client?.fullname  || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.business?.project?.name_project || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.business?.property?.name_property || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.transaction_date || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.amount | number:'.2' || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.bank_account?.name_account || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="text-align: center;">
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.applied ? 'Si' : 'No' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.collection_status?.name || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.approval_payment?.receipt_number || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="selectedStatus?.id !== 4">
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.reason_name || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="selectedStatus?.id !== 4">
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.approval_payment?.observations || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="selectedStatus?.id !== 4">
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.approval_payment?.cancel_date || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="selectedStatus?.id !== 4">
          <p-cellEditor>
            <ng-template pTemplate="output">
              {{ row?.user_cancel || '-' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="selectedStatus?.id === 4">
          <div class="flex align-items-center justify-content-center gap-2" *ngIf="row?.collection_status?.id !== 3; else emptyColumn">
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                   *ngIf="
                    (row?.collection_status?.id === 1 &&
                    row?.payment_method === 'Tarjeta de crédito/débito' &&
                    row?.order?.available &&
                    !row?.order?.complete) ||
                    (row?.collection_status?.id === 1 &&
                    row?.payment_method === 'Tarjeta de crédito/débito' &&
                    !row?.order?.available &&
                    row?.order?.complete &&
                    !row?.approval_payment)
                    "
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-info"
                    icon="pi pi-sync"
                    pTooltip="Verificar estado"
                    (click)="showOrderStatus(row)"></button>
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                   *ngIf="
                    (row?.collection_status?.id === 1 && row?.payment_method === 'Tarjeta de crédito/débito' && !row?.order) ||
                    (row?.collection_status?.id === 1 && row?.payment_method === 'Tarjeta de crédito/débito' && !row?.order?.available && !row?.order?.complete)
                    "
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-help"
                    icon="pi pi-credit-card"
                    pTooltip="Generar orden de pago"
                    (click)="createOrder(row)"></button>
            <button
              *ngIf="row?.collection_status?.id === 1 && row?.payment_method !== 'Tarjeta de crédito/débito'"
              pButton
              pRipple
              type="button"
              icon="pi pi-check-circle"
              class="p-button-rounded p-button-text p-button-success"
              (click)="openApprovalPaymentDialog(row?.id)"
              pTooltip="Conciliar"
              tooltipPosition="top"
              [disabled]="(!canCreate && !canEdit) || currentUser?.user?.role?.external_access === 1"
             >
            </button>
            <button
              *ngIf="
                (row?.collection_status?.id === 1 &&
                row?.payment_method !== 'Tarjeta de crédito/débito') ||
                (row?.collection_status?.id === 1 &&
                row?.payment_method === 'Tarjeta de crédito/débito' &&
                !row?.order?.available &&
                !row?.order?.complete)"
              pButton
              pRipple
              type="button"
              icon="pi pi-times-circle"
              class="p-button-rounded p-button-text p-button-danger"
              (click)="openRejectionPaymentDialog(row?.id)"
              pTooltip="Rechazar"
              tooltipPosition="top"
              [disabled]="(!canCreate && !canEdit) || currentUser?.user?.role?.external_access === 1">
            </button>
            <button
              *ngIf="row?.collection_status?.id === 2"
              pButton
              pRipple
              type="button"
              icon="pi pi-file"
              class="p-button-rounded p-button-text p-button-info"
              (click)="generatePaymentReceipt(row?.id)"
              pTooltip="Generar recibo"
              tooltipPosition="top">
            </button>
            <button
              *ngIf="row?.collection_status?.id === 2"
              pButton
              pRipple
              type="button"
              icon="pi pi-ban"
              class="p-button-rounded p-button-text p-button-info"
              (click)="openCancelPaymentDialog(row?.id)"
              pTooltip="Anular cobro"
              tooltipPosition="top"
              [disabled]="(!canCreate && !canEdit) || currentUser?.user?.role?.external_access === 1">
            </button>
          </div>
          <ng-template #emptyColumn >
            <div class="flex align-items-center justify-content-center">
              -
            </div>
          </ng-template>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colspan]="columns.length">No se han encontrado resultados</td>
      </tr>
    </ng-template>

  </p-table>
  </ng-template>
<app-card-payment-rate [displayModal]="showRateModal" [collection]="selectedCollection" (orderData)="saveOrder($event)" (showModal)="showRateModal = $event"></app-card-payment-rate>
<app-show-link-component [displayModal]="displayModal" [paymentLink]="paymentLink" (showModal)="closeLinkModal($event)" [clientId]="selectedCollection?.client?.id"></app-show-link-component>
</div>
