import { Component,EventEmitter,Input,OnChanges,OnInit,Output, SimpleChanges } from '@angular/core';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import { NoteService } from '@tools/services/note.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-detail-note',
  templateUrl: './detail-note.component.html',
  styleUrls: ['./detail-note.component.scss'],
  providers: []
})

export class DetailNoteComponent implements OnInit, OnChanges {

  @Input() visible: boolean = false;
  @Input() selectedNote: any = null;
  @Input() moduleId: any = null;
  @Input() objectId: any = null;

  @Output() isShowing = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();

  constructor(
    private noteService: NoteService,
    private fb: FormBuilder,
  ) {
}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {

    if (changes['moduleId']) {
      console.log(changes['moduleId']);
    }
  
    if (changes.hasOwnProperty('visible') && changes['visible'].currentValue !== null && changes['visible'].currentValue) {
      if (this.visible) {
        
      } else {

      }
    }
  
    if (changes.hasOwnProperty('selectedNote') && changes['selectedNote'].currentValue !== null && changes['selectedNote'].currentValue) {
      console.log('this.selectedNote: ', this.selectedNote);
    }

  }

  hideModal(): void {
    this.visible = false;
    this.isShowing.emit(this.visible);
  }

  async downloadDocument(fileUrl: string) {
    let a: any = document.createElement('a');
    a.download = true;
    a.target = '_blank';
    a.href= fileUrl;
    a.click();
  }

  convertDate = (dateString: string): string => {
    const date = new Date(dateString);
  
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getUTCFullYear();
    
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  
    // Convertir hora UTC a formato de 12 horas con AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    
    return `${day}-${month}-${year} - ${formattedHours}:${minutes}:${seconds} ${period}`;
  };
  
  getFileNameFromUrl = (url: string): string => {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    return decodeURIComponent(fileName);
  };
}
