<div class="content-breadcrumb">
  <h3 class="module cursor-pointer title-breadcrumb" [routerLink]="routeModule" >{{ module }}</h3>
  <p class="p-breadcrumb" *ngIf="page">
    <a class="link" [routerLink]="routePage">{{ page }} </a>
    <span *ngIf="subPage">
      <!-- <i class="pi pi-minus" *ngIf="subPage" ></i> -->
      <span class="mx-3">-</span> 
      <a *ngIf="subPage" class="link">{{ subPage }} </a>
    </span>
  </p>
</div>
