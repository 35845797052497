import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { ConfigureTableComponent } from './configure-table.component';
import { OrderListModule } from 'primeng/orderlist';
import { CheckboxModule } from 'primeng/checkbox';
import { PickListModule } from 'primeng/picklist';
import {ToastModule} from 'primeng/toast';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    ListboxModule,
    OrderListModule,
    CheckboxModule,
    PickListModule,
    ToastModule
  ],
  declarations: [ConfigureTableComponent]
})
export class ConfigureTableModule { }
