import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BankAccountModel } from '@admin/models/bankAccount';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<any> {
    let url = `${this.apiUrl}bank-account/list`;
    return this.http.get(url);
  }

  create(body:BankAccountModel): Observable<any> {
    let url = `${this.apiUrl}bank-account/create`;
    return this.http.post(url, body);
  }

  update(body:BankAccountModel, accountId:number): Observable<any> {
    let url = `${this.apiUrl}bank-account/update/${accountId}`;
    return this.http.post(url, body);
  }

  detail(accountId:number): Observable<any>{
    const url = `${this.apiUrl}bank-account/${accountId}`
    return this.http.get(url);
  }

  delete(accountId:number): Observable<any> {
    let url = `${this.apiUrl}bank-account/delete/${accountId}`;
    return this.http.delete(url);
  }

}
