import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import {ToastModule} from 'primeng/toast';
import { ConfigureTableModule } from '../settings/configure-table/configure-table.module';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { ToolsModule } from '@tools/tools.module';
import { CreateTaskComponent } from './create-task/create-task.component';
import { DetailTaskComponent } from './detail-task/detail-task.component'
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {RadioButtonModule} from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {MultiSelectModule} from 'primeng/multiselect';


@NgModule({
  declarations: [TasksComponent, CreateTaskComponent, DetailTaskComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    ToolbarModule,
    DropdownModule,
    TooltipModule,
    SplitButtonModule,
    ToastModule,
    ConfigureTableModule,
    ConfirmPopupModule,
    ToolsModule,
    DialogModule,
    ConfirmDialogModule,
    InputTextareaModule,
    RadioButtonModule,
    CalendarModule,
    ProgressSpinnerModule,
    MultiSelectModule
  ],
  exports: [CreateTaskComponent, DetailTaskComponent]
})
export class TasksModule { }
