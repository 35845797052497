<p-toolbar styleClass="px-0 py-0 w-full h-[70px] bg-[#2D3E50] md:bg-[white] fixed top-0 z-999" [style]="{}">

  <div
    class="h-[70px] bg-[#FFFFFF] cursor-pointer p-3 opacity-99 w-full md:w-[260px] flex justify-between md:justify-center"
    [ngClass]="''"
    (mouseover)="onMouseToggle(true, $event)"
    (mouseout)="onMouseToggle(false, $event)"
  >
    <div class="pl-3 flex md:hidden">
      <button (click)="toggleShowNavPanel()" mat-icon-button style="color: #2d3e50;">
        <mat-icon> menu </mat-icon>
      </button>
    </div>

    <div class="form-header flex justify-center md:justify-start">
      <img
        [routerLink]="['/']"
        class="main-lock-icon h-full max-h-[36px] object-contain m-0"
        [src]="
          user?.user?.subscription?.bussines_image ??
          '/assets/img/logo_login.png'
        "
      />
    </div>

    <div class="pr-3 flex md:hidden">
      <div
        mat-icon-button
        class="flex justify-center items-center"
        style="color: #2d3e50;"
        (click)="toggleShowNavBar($event)"
      >
        <mat-icon>more_vert</mat-icon>
      </div>
    </div>
  </div>

  <div class="action_section h-[70px] justify-end flex bg-[white]" [class]="isMovil?'mobile mt-[-8px]':''" *ngIf="!isMovil || (isMovil && showNavBar)">
    <div class="h-full">
      <div class="toolbar_elements h-full flex justify-end items-center">
        <div class="toolbar_elements">
          <button mat-icon-button class="mr-2">
            <i
              class="pi pi-bell text-4xl"
              pBadge
              [value]="totalNotifications"
            ></i>
          </button>

          <span> {{ user?.user.email }}</span>
          <button mat-icon-button [matMenuTriggerFor]="menu2" class="rounded-full border border-[grey] ml-2">
            <i class="pi pi-user text-4xl" style="font-size: 2rem" ></i>
          </button>

          <mat-menu #menu2="matMenu">
            <button (click)="logout()" mat-menu-item>
              <mat-icon>logout</mat-icon>
              <span class=""> Cerrar sesión</span>
            </button>
          </mat-menu>

        </div>
      </div>
    </div>
  </div>

</p-toolbar>

<div class="flex flex-row flex-nowrap bg-[#f4f5fa]" [class]="'mt-[70px]'">
  <div id="panel-lateral" class="h-content justify-content-center h-full w-full md:w-[260px] md:min-w-[260px] bg-[#2D3E50] fixed z-20" [class]="isMovil?'fixed':''+' '+showNavPanel?'showNavPanel':''" *ngIf="!isMovil || (isMovil && showNavPanel)">
    <ng-container class="custom-menu h-full w-full bg-[#2D3E50]">
      <div class="flex w-full h-full overflow-y-auto flex-column justify-between">
        <p-accordion class="w-full bg-[#2D3E50]">
          <p-accordionTab *ngFor="let item of menu" class="bg-[#2D3E50]">
            <ng-template pTemplate="header" class="bg-[#2D3E50]">
              <!-- <span *ngIf="item.icon" [class]="item.icon"></span> -->
              <mat-icon *ngIf="item.icon" class="icons">{{ item.icon }}</mat-icon>
              <span class="ml-2"
                >{{ item.id }}</span
              >
            </ng-template>

            <ng-template pTemplate="content">
              <div *ngIf="(showNavMini && isHovering) || !showNavMini">
                <ng-container *ngFor="let menu of item.items">

                  <p-accordion *ngIf="menu.subMenu">
                    <p-accordionTab>
                      <ng-template pTemplate="header">
                        <span class="ml-0 my-0">{{ menu.label }}</span>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <div class="flex flex-column column pl-3">
                          <a
                            *ngFor="let sub of menu.subMenu"
                            [routerLink]="sub.url"
                            [class]="isActive(2, sub.url) ? 'active' : 'no-active'"
                            class="w-full my-2"
                            (click)="(isMovil?toggleShowNavPanel():null)"
                            >{{ sub.label }}</a
                          >
                        </div>
                      </ng-template>
                    </p-accordionTab>
                  </p-accordion>

                  <div *ngIf="!menu.subMenu" class="mt-2 mb-4 pl-3 ml-2 mr-0">
                    <a
                      [class]="isActive(1, menu.url) ? 'active' : 'no-active'"
                      [routerLink]="menu.url"
                      class="w-full my-2"
                      (click)="(isMovil?toggleShowNavPanel():null)"
                    >
                      {{ menu.label }}
                    </a>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
        <div class="sidenav__logo-wrapper mx-auto my-3">
            <a class="" [routerLink]="['/']">
            <img
              class="sidenav__logo"
              src="/assets/img/logo-nearby-light.png"
              alt="Logo de Nearby"
            />
          </a>
        </div>
      </div>
    </ng-container>
  </div>

  <div id="module-content" class="h-content p-content content shadow-2 p-3">
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>
</div>
