import { Component, OnInit,EventEmitter,Input,Output, SimpleChanges  } from '@angular/core';
import { TasksService } from '@support/services/tasks.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { SalesTeamsService } from "@support/services/salesTeams.service";
import { UsersService } from '@admin/services/users.service';

@Component({
  selector: 'app-create-assignment-task',
  templateUrl: './create-assignment-task.component.html',
  styleUrls: ['./create-assignment-task.component.scss'],
  providers: [MessageService]
})
export class CreateAssignmentTaskComponent implements OnInit {

  public form: FormGroup;
  public isLoading: boolean = false;
  public salesTeams: any[] = [];
  public users: any[] = [];
  public formCreated: boolean = false;
  @Input() visible: boolean = false;
  @Input() taskId: any = null;

  @Output() complete = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();
  @Output() emitVisible = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();
  public loading: boolean = false;
  public selectedOwner: any = null;

  public ownerOptions: any[] = [
    {name:'Equipo de ventas', key: 1},
    {name:'Usuario', key: 2}
  ]

  constructor(
    private _tasks: TasksService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private _salesTeams: SalesTeamsService,
    private _users:UsersService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      user_id: [null],
      sales_team_id: [null],
      selectedOwnerOption: [null],
    });
    this.loading = false;
  }
  assignmentSave(): void {
    let data = this.form.getRawValue();
    console.log(data)
    
    // Verificar si se ha seleccionado un propietario
    if (!this.selectedOwner || (!data.sales_team_id && !data.user_id)) {
      this.confirmationService.confirm({
        target: event.target,
        message: '¿Desea guardar sin seleccionar un cliente o equipo de ventas?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save();
        },
        reject: () => {
          console.log('rejected');
        },
        key: "confirmDelete"
      });
    } else {
      this.save();
    }
  }
  
  save(): void {
    this.loading = true;
  
    // Crear un objeto solo con los campos necesarios
    const dataToSave = {
      sales_team_id: this.form.get('sales_team_id').value,
      user_id: this.form.get('user_id').value,
    };

    console.log('Datos a guardar:', dataToSave);
  
    if (!dataToSave.sales_team_id && !dataToSave.user_id) {
      console.error('Se debe seleccionar un cliente o un equipo de ventas.');
      this.loading = false;
      return;
    }
  
    // Crear un FormData
    const formData = new FormData();
    if (dataToSave.sales_team_id) {
      formData.append('sales_team_id', dataToSave.sales_team_id);
    }
    if (dataToSave.user_id) {
      formData.append('user_id', dataToSave.user_id);
    }
  
    if (this.taskId) {
      console.log(this.taskId, 'el id');
      this._tasks.asignment(formData, this.taskId).subscribe(
        (res) => {
          this.isSuccess.emit(true);
          this.finishRequest();
        },
        (err) => {
          this.loading = false;
          this.error.emit(err.error.message);
          this.isSuccess.emit(false);
        }
      );
    }
  }

  async selectOwnerOption(e: any): Promise<void> {
    this.isLoading = true;
    this.selectedOwner = e.value;
    switch (this.selectedOwner) {
      case 1:
        this.form.get('sales_team_id').setValidators([Validators.required]);
        this.form.get('user_id').clearValidators();
        this.form.get('user_id').setValue(null);
        await this.getTeams();
        this.users = [];
        this.isLoading = false;
        break;
      case 2:
        this.form.get('user_id').setValidators([Validators.required]);
        this.form.get('sales_team_id').clearValidators() ;
        this.form.get('sales_team_id').setValue(null);
        await this.getUsers();
        this.salesTeams = [];
        this.isLoading = false;
        break;
      default:
        this.isLoading = false;
        break;
    }
  }
  
  getTeams(): void {
    // this.isLoading = true;
    this._salesTeams.get().subscribe(
      (res) => {
        this.salesTeams = res.data;
        // this.isLoading = false;
      },
      (error) => {
        console.log(error);
        // this.isLoading = false;
      }
    );
  }

  getUsers():void{
    // this.isLoading = true;
    this._users.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.users = res.data;
          // this.isLoading = false;
        }
      },
      (err:any)=>{
        console.log(err);
        // this.isLoading = false;
      }
    )
  }
 

  finishRequest(): void {
    this.loading = false;
    this.form.reset({
      user_id: null,
      sales_team_id: null,

      selectedOwnerOption: null,
    });
    this.complete.emit(true);
    this.hideModal();
  }

  getErrorMessage (controlName: string): string {

    const field = this.form.get(controlName);

    if(!field || !field.invalid) return ''

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido'
  }

  changeTeam(e: any): void {
    console.log(e);
  }

  changeUser(e: any): void {
    console.log(e);
  }

  hideModal(): void {
    this.emitVisible.emit(false);
  }


}
