import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ManualModalComponent} from '../manual-modal/manual-modal.component';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';

@Component({
  selector: 'app-manual-quotes-list',
  templateUrl: './manual-quotes-list.component.html',
  styleUrls: ['./manual-quotes-list.component.scss'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class ManualQuotesListComponent implements OnInit {

  @Output() totalPlan = new EventEmitter<number>();
  @Output() quotesList = new EventEmitter<object[]>();


  public quotes: any[] = [];
  public cols: any[];
  public showQuoteModal: boolean = false;

  ref: DynamicDialogRef;

  constructor(
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'number', header: 'N°' },
      { field: 'name', header: 'Nombre' },
      { field: 'date', header: 'Fecha' },
      { field: 'amount', header: 'Monto' }
    ];
  }


  openCreateQuoteModal(): void{
    this.showQuoteModal = true;
  }

  closeQuoteModal(): void {
    this.showQuoteModal = false;
  }

  addQuote($event): void {
    const quote = $event;
    const repeatQuote = this.quotes?.find(el => el?.number === quote?.number);
    if (repeatQuote) {
      this.messageService.add({severity:'error', summary: 'Error', detail: `Ya existe una cuota con el número ${quote?.number}`});
      return;
    }
    let insertIndex = 0;
    for (let i = 0; i < this.quotes.length; i++) {
      if (new Date(quote.date) < new Date(this.quotes[i].date)) {
        insertIndex = i;
        break;
      }
    }
    this.quotes?.splice(insertIndex, 0, quote);
    const total = this.quotes?.map(el => el.amount);
    const totalAmount = total.reduce((acc, curr) => acc + curr, 0);
    this.totalPlan.emit(totalAmount);
    this.quotesList.emit(this.quotes);
  }

  deleteQuote(quote): void {
    this.confirmationService.confirm({
      message: 'Al eliminar la cuota perderá toda la información de la misma',
      header: 'Confirmación',
      accept: () => {
        this.quotes = this.quotes?.filter(el => el?.number !== quote?.number);
        const total = this.quotes.map(el => el.amount);
        const totalAmount = total.reduce((acc, curr) => acc + curr, 0);
        this.totalPlan.emit(totalAmount);
        this.quotesList.emit(this.quotes);
      },
      reject: (type) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            console.log(type);
            break;
            case ConfirmEventType.CANCEL:
              console.log(type);
            break;
        }
      }
    });

  }

}
