import { Component, OnInit, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CollectionService } from '@support/services/collection.service';
import { MioService } from '@support/services/mio.service';

@Component({
  selector: 'app-show-link-component',
  templateUrl: './show-link-component.component.html',
  styleUrls: ['./show-link-component.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ShowLinkComponentComponent implements OnInit, OnChanges {

  @Input() displayModal;
  @Input() paymentLink;
  @Input() clientId;
  @Output() showModal = new EventEmitter<boolean>;
  public form: FormGroup;

  public showLink: boolean = false;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private _collection:CollectionService,
    private _mio:MioService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.hasOwnProperty('paymentLink') && changes['paymentLink'].currentValue){
      // this.paymentLink = changes['paymentLink'].currentValue;
      this.createForm();
    }
  }

  async createForm(): Promise<void> {
    this.form = new FormGroup({
      payment_link: new FormControl('')
    });
    console.log(this.paymentLink);
    await this.form.get('payment_link')?.setValue(this.paymentLink);
    this.form.controls['payment_link'].disable();
  }

  copiarAlPortapapeles(): void {
    const link = this.form.get('payment_link')?.value;
    if (link) {
      navigator.clipboard.writeText(link).then(() => {
        console.log('Link copiado al portapapeles');
      }).catch(err => {
        console.error('Error al copiar el link: ', err);
      });
    }
  }

  sendByMail(): void {
    const data = {
      link: this.paymentLink,
      client_id: this.clientId
    }
    this._mio.sendPaymentLink(data).subscribe(
      (res: any) => {
        if (res?.ok) {
          this.messageService.add({key: 'tr', severity: 'success', summary: 'Link enviado', detail: 'Se ha enviado el link de pago por correo'});
          console.log(res);
        }
      },
      (error: any) => {
        this.messageService.add({key: 'tr', severity: 'error', summary: 'Link no enviado', detail: 'Ha ocurrido un error al enviar el link de pago'});
        console.log(error);
      }
    )
    console.log('enviar por correo');
  }

  close(): void {
    this.showModal.emit(false);
  }

}
