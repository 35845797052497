import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manual-modal',
  templateUrl: './manual-modal.component.html',
  styleUrls: ['./manual-modal.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ManualModalComponent implements OnInit, OnChanges {

  public form: FormGroup;
  public minDate: Date;
  public maxDate: Date;
  public invalidDates: Date;
  @Output() modalClosed = new EventEmitter<void>();
  @Output() data = new EventEmitter<Object>();
  @Input() displayModal: boolean = false;
  @Input() quotes: any;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(changes['quotes']) {
      const reserva = this.quotes?.find((el)=>el.name.toLowerCase() === 'reserva');
      const entrega = this.quotes?.find((el)=>el.name.toLowerCase() === 'entrega');
      this.invalidDates = this.quotes?.map((el) => {
        const date = new Date(el.date);
        date.setDate(date.getDate() + 1);
        return date;
      });
      if(reserva) {
        this.minDate = new Date(reserva.date);
      }
      if(entrega) {
        this.maxDate = new Date(entrega.date);
      }
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      number: [null, Validators.required],
      name: [null, Validators.required],
      date: [null, Validators.required],
      amount: [null, Validators.required],
    });
  }

  async saveQuote(): Promise<void> {

    const validate = {
      ...this.form.getRawValue()
    }

    Object.entries(validate).forEach(([key, value]) => {
      if (value === null || value === '') {
        this.form.get(key).setErrors({ required: true });
        return;
      }
    });

    const number = this.form.get('number').value;
    if (this.quotes.some(quote => parseInt(quote.number) === number)) {
      this.form.get('number').setErrors({ repeated: true });
      return;
    }


    if (this.form.invalid) {
      this.showNotify('error', 'Formulario incompleto', 'Por favor complete el formulario');
      return;
    }


    const payDate = await this.form.get('date').value;
    const payDateFormatted = await this.datePipe.transform(payDate, 'yyyy-MM-dd');
    const data = {
      ...this.form.getRawValue(),
      date: payDateFormatted,
      AppliedAmount: 0,
      balance: this.form.get('amount').value,
      status: 'pendiente'
    };
    await this.data.emit(data);
    this.closeModal();
  }

  closeModal(): void {
    this.modalClosed.emit();
    this.form.reset();
  }

  showError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && control.touched;
  }

  showNotify(severity, summary, detail): void {
    this.messageService.add({key: 'tr', severity: severity, summary: summary, detail: detail});
  }

}
