import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserLogin } from '../../models/userLogin.model';
import { UserRegister, UserRecoveryPass, UserResetPass } from '../../models/userRegsiter.model';
import { UserData } from '@core/models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from '../../../tools/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public apiUrl = environment.apiUrl;
  public user$ = new BehaviorSubject<UserData>(null);
  public isUserLogged$ = new BehaviorSubject(undefined);

  constructor(private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private storageSrv: StorageService) { }

  login(body: UserLogin): Observable<any>{
    const url = `${this.apiUrl}login`;
    return this.http.post(url, body);
  }

  register(body: UserRegister): Observable<any>{
    const url = `${this.apiUrl}user/register`;
    return this.http.post(url, body);
  }

  update(body: UserRegister, userId): Observable<any>{
    const url = `${this.apiUrl}users/${userId}`;
    return this.http.put(url, body);
  }

  recoveryPassword(body: UserRecoveryPass): Observable<any>{
    const {email} = body;
    const url = `${this.apiUrl}forgot?email=${email}`;
    return this.http.post(url, body);
  }

  resetPassword(body: UserResetPass): Observable<any>{

    const url = `${this.apiUrl}reset`;
    return this.http.post(url, body);
  }

  profile(): Observable<any>{
    const url = `${this.apiUrl}users/profile`;
    return this.http.get(url);
  }

  logout() {
    // localStorage.removeItem('token');
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    const token = this.storageSrv.getToken();
    if (this.jwtHelper.isTokenExpired(token)) {
      // token expired
      return false;
    } else {
      // token valid
      return true;
    }
  }


}
