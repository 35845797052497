<app-spinner *ngIf="loading"></app-spinner>
<div class="full-width">
  <div *ngIf="!loading" class="form-wraper">
    <div class="form-container p-card">
      <div class="form-body">
        <form [formGroup]="form" class="row mx-0 md:mx-5">
          <div class="row">
            <div class="col-lg-12 mb-3 d-flex justify-content-end">
            </div>
          </div>

          <!-- Datos generales del Cobro -->
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div>
                  <span class="p-float-label w-100 mb-4">
                    <p-dropdown appendTo="body" [options]="reasons" formControlName="reason_id" [filter]="true"
                      optionLabel="name" optionValue="id" [style]="{ width: '100%' }"></p-dropdown>
                    <label>Motivo de anulación</label>
                    <small *ngIf="showError('reason_id')" class="p-error block">El campo es obligatorio</small>
                  </span>
                </div>
                <div class="col-lg-12">
                  <span class="p-float-label w-100">
                    <textarea pInputTextarea formControlName="observations" rows="3" [style]="{ width: '100%' }"></textarea>
                    <label>Observaciones</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="form-footer">
        <button pButton pRipple type="submit" (click)="cancelPayment()" label="Guardar" class="p-button-accent p-mr-1"></button>
        <button pButton pRipple (click)="closeDialog()" label="Cancelar" class="p-button-accent p-button-text"></button>
      </div>
    </div>
  </div>
</div>
