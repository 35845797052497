import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@tools/services/notifications.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from '@core/models/user.model';
import { Project } from '@support/models/project.model';
import { ConfigColumnsService } from '@support/services/configColumns.service';
import { ConfigColumn } from '@support/models/configColumn.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-configure-table',
  templateUrl: './configure-table.component.html',
  styleUrls: ['./configure-table.component.css'],
  providers: [MessageService]
})
export class ConfigureTableComponent implements OnInit {

  public submitted: boolean = false
  public validationErrors: Record<string,any>[] = []
  public inactivecolumns: ConfigColumn[] = []
  public activeColumns: ConfigColumn[] = []
  public loading: boolean = false
  public user: User
  public table: string
  public project: Project

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _snackBar: NotificationsService,
    private _config: ConfigColumnsService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.user = this.config.data.user
    this.project = this.config.data.project
    this.table = this.config.data.table
    this.getColumns()
  }

  getColumns(): void{

    let query = {
      table: this.table,
      subscription_id: this.user?.subscription?.id
    }

    if(this.project?.id) {
      query['project_id'] = this.project.id
    }

    const params = new HttpParams({fromObject: query})
    this._config.get(params).subscribe({
      next:(res:any)=>{
        if (res && res.data) {
          this.inactivecolumns = res.data.filter(item => !item.active)
          this.activeColumns = res.data.filter(item => item.active)
        }
      },
      error:(err: any) => {
        this._snackBar.openSnackBar('Error al consultar las columnas', 'OK');
      }
    })
  }

  register() {
    this.submitted = true

    this.validateData()

    if(this.validationErrors.length > 0) return

    this.activeColumns = this.activeColumns.map(column => ({...column, active: 1}))
    this.inactivecolumns = this.inactivecolumns.map(column => ({...column, active: 0}))
    let mergedColumns : ConfigColumn[] = this.activeColumns.concat(this.inactivecolumns)


    mergedColumns = mergedColumns.map((column,index: number) => ({
      ...column,
      position: index + 1,
      project_id: this.project?.id ?? null,
      subscription_id: this.user?.subscription?.id ?? null
    }))


    this._config.createOrUpdate(mergedColumns).subscribe({
      next: (res: any) => {
        this._snackBar.openSnackBar(res.msg, 'OK');
        this.closeDialog(true)
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 422) {
          this._snackBar.openSnackBar(err.error.message, 'OK');
        } else {
          this._snackBar.openSnackBar('Error. Por favor intente más tarde.', 'OK');
        }
      }
    });
  }

  validateData() {
    this.validationErrors = [];

    if(!this.activeColumns.length) {
      this.messageService.addAll([
        {severity:'error', summary:'No hay columnas visibles', detail:'La lista de columnas visibles no debe estar vacía.'}
      ]);
      this.validationErrors.push({ message:'La lista de columnas visibles no debe estar vacía.'})
    }
    
    if(this.activeColumns.length > 12) {
      this.messageService.addAll([
        {severity:'error', summary:'Máximo excedido', detail:'La cantidad de columnas visibles no debe ser mayor a 12.'}
      ]);
      this.validationErrors.push({ message:'La cantidad de columnas visibles no debe ser mayor a 12.'})
    }
    
    
    if(this.project?.id) {
      const requiredColumns = ['Inmueble', 'Estado', 'Precio Base'];
      const missingColumns = [];
      requiredColumns.forEach(columnName => {
        const columnExists = this.activeColumns.some(column => column.column === columnName);
        if (!columnExists) {
          missingColumns.push(columnName);
        }
      });
      
      if (missingColumns.length > 0) {
        this.messageService.addAll([
          {severity:'error', summary:'Columnas obligatorias', detail:`Las siguientes columnas deben ser visibles: ${missingColumns.join(', ')}`}
        ]);
        const errorMessage = `Las siguientes columnas deben ser visibles: ${missingColumns.join(', ')}`;
        this.validationErrors.push({ message: errorMessage })
      }
    }
  }

  closeDialog(success: boolean = false): void {
    this.ref.close(success);
  }
}
