<p-confirmPopup key="confirmDelete"></p-confirmPopup>
<p-toast [baseZIndex]="20000"></p-toast>
<div class="card-body card content-view">
  <app-spinner [overlay]="true" *ngIf="isLoading"></app-spinner>
  <p-table
    #tasksTable
    styleClass="p-datatable-striped p-datatable-sm"
    [value]="filteredTasks || tasks"
    [columns]="cols"
    [globalFilterFields]="globalFilterFields"
    [tableStyle]="{'min-width': '50rem'}"
    [paginator]="true"
    [rows]="20"
    scrollHeight="430px"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}">

    <ng-template pTemplate="caption">
      <p-toolbar styleClass="d-flex justify-content-between flex-wrap max-w-100 gap-2 w-full p-0">
        <div class="p-toolbar-group-left gap-2 max-w-100 flex-wrap">
          <span class="p-input-icon-left max-w-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="p-inputtext-sm" (input)="tasksTable.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
          </span>
          <!-- MultiSelect para Referente -->
          <p-multiSelect
            *ngIf="currentUser?.user?.isAdminSubs"
            [options]="referenteOptions"
            [(ngModel)]="selectedReferente"
            placeholder="Referente"
            [showHeader]="false"
            (onChange)="applyReferenteFilter()">
          </p-multiSelect>

          <!-- MultiSelect para Propietarios -->
          <p-multiSelect
            *ngIf="currentUser?.user?.isAdminSubs"
            [options]="propietarioOptions"
            [(ngModel)]="selectedPropietario"
            placeholder="Propietarios"
            [showHeader]="false"
            (onChange)="applyPropietarioFilter()">
          </p-multiSelect>

          <button
            pButton
            *ngIf="currentUser?.user?.isAdminSubs"
            class="p-button-rounded p-button-text"
            icon="pi pi-filter-slash"
            (click)="clearFilters(tasksTable)"
            pTooltip="Limpiar filtros"
            tooltipPosition="top">
          </button>
        </div>

        <div class="p-toolbar-group-right gap-2 max-w-100">
          <button *ngIf="canCreateTask" pButton pRipple icon="pi pi-plus" label="Crear tarea" class="p-button-raised p-button-secondary" (click)="showCreationModa()"></button>
        </div>
      </p-toolbar>
      <p-toolbar>
        <div class="p-toolbar-group-left gap-2 d-flex overflow-x-auto">
          <button *ngFor="let status of tasksStatuses"
            pButton
            pRipple
            type="button"
            [label]="status.name"
            [class]="'p-button-outlined p-button-rounded button-status '+ status.cssClass"
            [class.active]="selectedStatus?.id === status.id"
            (click)="onChangeStatus(status)">
          </button>
        </div>
      </p-toolbar>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable && col.dataKey">
          {{col.header}}
          <span *ngIf="col.dataKey">
            <p-sortIcon *ngIf="col.sortable" [field]="col.dataKey"></p-sortIcon>
            <p-columnFilter *ngIf="col.filterable" type="text" [field]="col.dataKey" display="menu" class="ml-auto"></p-columnFilter>
          </span>
        </th>
        <th style="text-align: center">Acciones</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td *ngFor="let col of columns" [key]="index">

          <ng-container #dinamicStatus *ngIf="col.field === 'status' && canEditTask; else staticStatus">
            <select [(ngModel)]="rowData[col.field]" (change)="onStatusChange(rowData)">
              <option value="Sin iniciar">Sin iniciar</option>
              <option value="Iniciado">Iniciado</option>
              <option value="Completado">Completado</option>
              <option value="Cerrado">Cerrado</option>
            </select>
          </ng-container>

          <ng-template #staticStatus>
            <ng-container *ngIf="col.field === 'expiration_date_format'; else otherField">
              <span [class]="getColorByDate(rowData.expiration_message) === 2 ? 'text-[red]' : (getColorByDate(rowData.expiration_message) === 1 ? 'text-[orange]' : 'text-[black]')">{{rowData[col.field]}}</span>
            </ng-container>

            <ng-template #otherField>
              {{rowData[col.field]}}
            </ng-template>
          </ng-template>

        </td>

        <td style="text-align: center">
          <div class="flex align-items-center justify-content-center gap-2">

            <button *ngIf="canEditTask" pButton class="p-button-text p-button-primary" type="button" icon="pi pi-cog" (click)="showCreationModa(rowData)"></button>

            <button pButton class="p-button-text p-button-primary" type="button" icon="pi pi-eye" (click)="showDetailModal(rowData)"></button>

            <button *ngIf="canDeleteTask" pButton class="p-button-text p-button-danger" type="button" icon="pi pi-trash" (click)="deleteElement(rowData.id)"></button>

          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No se encontraron resultados.</td>
      </tr>
    </ng-template>
  </p-table>

  <app-create-task [visible]="isVisible" (isShowing)="isVisible = $event" (error)="showError($event)" (complete)="creationCompleted($event)" [selectedTask]="selectedTask" [messageService]="messageService"></app-create-task>

  <app-detail-task [visible]="isVisibleDetail" (isShowing)="isVisibleDetail = $event" (error)="showError($event)" (complete)="creationCompleted($event)" [selectedTask]="selectedTask" [messageService]="messageService"></app-detail-task>

  <p-confirmDialog [style]="{width: '40vw'}"
    acceptLabel="Continuar"
    rejectLabel="Cancelar"
    acceptIcon="pi pi-check-circle"
    rejectIcon="pi pi-times"
    rejectButtonStyleClass="p-button-text p-button-secondary"
    acceptButtonStyleClass="p-button-text p-button-primary"
    [baseZIndex]="10000"
    key="statusTask">
  </p-confirmDialog>
</div>
