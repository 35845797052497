import { ISubscription, StatusSubscription } from '@admin/models/subscriptions.model';
import { SubscriptionService } from '@admin/services/subscription.service';
import { UsersService } from '@admin/services/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@tools/services/notifications.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PermissionUserService } from '@tools/services/permission-user.service';
import {
  EMAIL_REGEXP,
  NAME_PATTERN,
} from 'src/app/shared/Enums/regexAuth.enum';
import { ClientsService } from '@support/services/clients.service';
import { Client, Country, InconmesOrigin, MaritalStatus, OriginClient, TypeClient, TypeDocuments } from '@support/models/client.model';
import { AutocompleteInputService } from '@tools/services/autocomplete-input.service';
import { DatePipe } from '@angular/common';
import { Currency } from '@support/models/project.model';
import { ProjectService } from '@support/services/project.service';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';

@Component({
  selector: 'app-create-client-from-business',
  templateUrl: './create-client-from-business.component.html',
  styleUrls: ['./create-client-from-business.component.scss'],
})
export class CreateClientFromBusinessComponent implements OnInit {
  public form: FormGroup;
  public loading = false;
  public isSubmit = false;
  public clientId: number = 0;
  public client: Client;
  public NAME_PATTERN: any = NAME_PATTERN;
  public EMAIL_REGEX: any = EMAIL_REGEXP;
  public isEditing = false;
  public originClient: OriginClient[] = [];
  public typeClient: TypeClient[] = [];
  public countries: Country[] = [];
  public maritalStatus: MaritalStatus[] = [];
  public inconmeOrigins: InconmesOrigin [] = [];
  public status: StatusSubscription[] = [];
  public isSingleClient = true;
  public clientFormattedAddress: string;
  public spouseFormattedAddress: string;
  public currencys:Currency[]=[];
  public typesDocuments:TypeDocuments[]=[];
  public additionalTypesDocuments:TypeDocuments[]=[];
  public canSetUser = false;
  public users: any = [];
  public showModal = false;
  public selectedUser: any;

  minDate: Date;
  maxDate: Date;

  constructor(
    private _clients: ClientsService,
    private _project: ProjectService,
    private _snackBar: NotificationsService,
    private router:Router,
    private route: ActivatedRoute,
    public zone: NgZone,
    private _places:AutocompleteInputService,
    public _permission:PermissionUserService,
    private datePipe: DatePipe,
    private _users:UsersService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<CreateClientFromBusinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 18, currentMonth, currentDay);
    this.maxDate = new Date(currentYear - 120, currentMonth, currentDay);
  }

  ngOnInit() {
    this.init();
  }

  async init(): Promise<void>{
    this.canSetUser = this._permission.setConfigPermission().includes('Asignar registro');
    await this.createForm();
    await this.getStatus();
    await this.getTypeClient();
    await this.getCountries();
  }

  createForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      country_origin_id: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.EMAIL_REGEX)]),
      mobile_phone: new FormControl(''),
      mobile_phone_code: new FormControl('')
    });
  }

  onValidator(fieldTag: string, validatorTag: string) {
    const field = this.form.controls[fieldTag];
    return (
      field?.errors &&
      field.errors[validatorTag] &&
      (this.isSubmit || field.touched)
    );
  }

  onSelectChange(event: any) {
    console.log('Valor seleccionado:', event.value);
    if(event.value === 2){
      this.isSingleClient = false;
      this.setValidators();
    }else{
      this.isSingleClient = true;
      this.removeValidators();
    }
  }

  setValidators() {

  }

  removeValidators() {
  }

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
      if (control.hasError('minLength')) {
        return 'El valor del campo es menor al permitido';
      }
      if (control.hasError('maxLength')) {
        return 'El valor del campo es mayor al permitido';
      }
      if (control.hasError('sumaPorcentajesExcede100')) {
        return 'Valor no permitido, la suma de los porcentajes no puede exceder el 100%';
      }
      if (control.hasError('negativeValue')) {
        return 'No se permiten valores negativos en este campo';
      }
    }
    return '';
  }

  getStatus():void{
    this.loading = true;
    this._clients.statusClients().subscribe(
      (res:any)=>{
      if(res && res.data){
        this.status = res.data;
      }
      this.loading = false;
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this.loading = false;
    })
  }

  getTypeClient():void{
    this.loading = true;
    this._clients.typesClients().subscribe(
      (res:any)=>{
      if(res && res.data){
        this.typeClient = res.data;
      }
      this.loading = false;
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this.loading = false;
    })
  }

  getCountries():void{
    this.loading = true;
    this._clients.getCountries().subscribe(
      (res:any)=>{
      if(res && res.data){
        this.countries = res.data;
      }
      this.loading = false;
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this.loading = false;
    })
  }

  getAddressClient(place: any) {
    console.log(place['formatted_address']);
    this.clientFormattedAddress = place['formatted_address'];
    this.zone.run(() => this.clientFormattedAddress = place['formatted_address']);

    if(this.clientFormattedAddress){
      this.form.patchValue({
        direction: this.clientFormattedAddress
      })
    }

    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.form.patchValue({
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      })
    }

    this.form.patchValue({
      country: this._places.getCountry(place),
      province: this._places.getState(place),
      city: this._places.getCity(place),
    })
  }

  getAddressSpouse(place: any) {
    console.log(place)
    this.spouseFormattedAddress = place['formatted_address'];
    this.zone.run(() => this.spouseFormattedAddress = place['formatted_address']);

    if(this.spouseFormattedAddress){
      this.form.patchValue({
        spouse_direction: this.spouseFormattedAddress
      });
    }

    console.log(this.form.get('spouse_direction').value, this.spouseFormattedAddress);

    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.form.patchValue({
        spouse_latitude: latitude.toString(),
        spouse_longitude: longitude.toString(),
      })
    }

    this.form.patchValue({
      spouse_country: this._places.getCountry(place),
      spouse_province: this._places.getState(place),
      spouse_city: this._places.getCity(place),
    })
  }

  register(): void {
    // console.log(this.form.get('spouse_direction').value);
    console.log(this.form.value);
    if (this.form.invalid) {
      this._snackBar.openSnackBar('Por favor, complete los datos del formulario.', 'OK');
      return;
    }
    this.loading = true;

    let data = {
      ...this.form.value
      // birthdate,
      // spouse_birthdate
    }

    Object.keys(data).forEach(key => {
      console.log(`${key}: ${data[key]}`);
      // if (data[key] === 'null') {
      //   data[key] = ''; // Reemplazar 'null' por un string vacío
      // }
    });


    const url = this._clients.create(data);

    url.subscribe(
      (res: any) => {
        this.loading = false;
        const message = 'Cliente registrado exitosamente.'
        this._snackBar.openSnackBar(message, 'OK');
        this.close({completed: true});
      },
      (err: HttpErrorResponse) => {
        console.log(err.status);
        this.loading = false;
        if (err.status === 422) {
          this._snackBar.openSnackBar(err.error.message, 'OK');
          this.close({completed: false});
        } else {
          this._snackBar.openSnackBar('Error. Por favor intente más tarde.', 'OK');
          this.close({completed: false});
        }
      }
    );
  }

  async asignNewUSer() {
    this.confirmationService.confirm({
      message: 'Al realizar esta acción el cliente será asignado a otro usuario',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Actual logic to perform a confirmation
          this._clients.setNewUser(this.client.id, this.selectedUser).subscribe((res: any)=> {
            this.loading = false;
            const message = 'Asignacion exitosa'
            this._snackBar.openSnackBar(message, 'OK');
            this.router.navigate(['/clients']);
          },
          (err:HttpErrorResponse)=>{
            this.loading = false;
            if (err.status === 422) {
              this._snackBar.openSnackBar(err.error.message, 'OK');
            } else {
              this._snackBar.openSnackBar('Error. Por favor intente más tarde.', 'OK');
            }
          });
        },
        reject: (type) => {
          switch(type) {
            case ConfirmEventType.REJECT:
                this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
                this.showModal = false;
            break;
            case ConfirmEventType.CANCEL:
                this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
                this.showModal = false;
            break;
          }
        }
      });
    console.log(this.selectedUser);
  }
  
  getUsers():void{
    this._users.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.users = res.data
          this.showModal = true;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.loading = false;
      }
    )
  }

  close(data: any): void {
    this.dialogRef.close(data);
  }
}
