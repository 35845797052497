import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RejectionReasonsService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

get(status?:number): Observable<any> {
  let url = `${this.apiUrl}rejection-reason/list?`;
  if(status) url +=`status=${status}`

  return this.http.get(url);
}
}
