<p-toast></p-toast>
<p-table
  #quotesTable
  *ngIf="list?.length > 0"
  [value]="list"
  styleClass="p-datatable-sm p-datatable-striped"
  [columns]="cols"
  (onRowSelect)="selectRow($event.data)"
  (onRowUnselect)="unselectRow($event.data)"
  (onHeaderCheckboxToggle)="selectAllRows($event.checked)"
  (onEditInit)="editingAmount($event)"
  (onEditComplete)="completeAmount($event)"
  [(selection)]="selectedRows"
  [reorderableColumns]="true"
  [globalFilterFields]="globalFilterFields"
  [tableStyle]="{'min-width': '50rem'}"
  [scrollable]="true"
  scrollHeight="300px"
  >
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap align-items-center justify-content-between">
      <span class="p-buttonset">
        <p-button label="Añadir cuota" [disabled]="!permissions?.update || disabledAdd" styleClass="p-button-text p-button-success" (click)="openCreateQuoteModal()"></p-button>
        <p-button label="Borrar" styleClass="p-button-danger p-button-text" [disabled]="disabledDelete" (click)="deleteQuotes()"></p-button>
        <!-- <p-button label="Reconciliar cobros" styleClass="p-button-info p-button-text" (click)="paymentsConciliation()"></p-button> -->
        <p-button label="Nuevo plan" [disabled]="!permissions?.delete" styleClass="p-button-text" (click)="createPlan()"></p-button>
        <p-button label="Borrar plan" [disabled]="!permissions?.delete" styleClass="p-button-secondary p-button-text" (click)="deletePlan()"></p-button>
        <p-splitButton *ngIf="items.length > 0 && permissions?.export" label="Exportar" [model]="items" styleClass="p-button-info p-button-text p-buttom-sm"></p-splitButton>
      </span>
      <app-create-plan *ngIf="business" [visible]="showPaymentForm" [business]="business" (showPlanForm)="showPaymentForm = $event" (isSuccess)="setCreationStatus($event)"></app-create-plan>
      <app-manual-modal [displayModal]="showQuoteModal" (modalClosed)="closeQuoteModal()" (data)="addQuote($event)" [quotes]="list"></app-manual-modal>
      <p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptButtonStyleClass="p-button-text p-button-secondary" acceptLabel="Aceptar" rejectLabel="Cancelar"></p-confirmDialog>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="p-inputtext-sm" (input)="quotesTable.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
          <th style="width:3rem">
            <p-tableHeaderCheckbox [disabled]="!permissions?.update"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
            <p-tableCheckbox [value]="rowData" [disabled]="!permissions?.update"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" [pEditableColumn]="rowData[col.dataKey]" [pEditableColumnField]="rowData[col.field]" [pEditableColumnRowIndex]="index">
          <div *ngIf="!col.editable || !permissions?.update">
            {{
              col.header === 'Monto'
                || col.header === 'Monto aplicado'
                || col.header === 'Balance'
                ? (rowData[col.field] | number:'.2')
                : rowData[col.field]
            }}
          </div>
          <p-cellEditor *ngIf="col.editable && permissions?.update">
            <ng-template pTemplate="input">
                <input
                  currencyMask
                  [options]="{ prefix: '', thousands: ',', decimal: '.' }"
                  pInputText type="text"
                  class="p-inputtext-sm"
                  [(ngModel)]="rowData[col.field]"
                  required
                >
              <!-- <p-button
                label="Calcular"
                styleClass="p-button-text p-button-success p-button-sm"
                (click)="calculateApplications(rowData[col.field], index)
              "></p-button> -->
            </ng-template>
            <ng-template pTemplate="output">
              {{
                col.header === 'Monto'
                  || col.header === 'Monto aplicado'
                  || col.header === 'Balance'
                  ? (rowData[col.field] | number:'.2')
                  : rowData[col.field]
              }}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
</p-table>
