import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-percentage-plan',
  templateUrl: './percentage-plan.component.html',
  styleUrls: ['./percentage-plan.component.scss']
})
export class PercentagePlanComponent implements OnInit {

  public form: FormGroup;
  public minDate: Date;
  public minSeparationDate: Date;
  public minQuotesDate: Date;
  public minDeliveryDate: Date;
  public frencuencies: any[] = [];

  @Input() business: any;
  @Input() valid: boolean = true;
  @Input() messages: any[] = [];
  @Output() formData = new EventEmitter<object>();

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.setOptions();
    this.createForm();
    this.completeForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      reserve_amount: [null, Validators.required],
      reserve_date: [null, Validators.required],
      separation_percentage: [null, Validators.required],
      separation_amount: [null, Validators.required],
      separation_date: [null, Validators.required],
      quotes_percentage: [null, Validators.required],
      quote_amount: [null, Validators.required],
      quote_count: [null, Validators.required],
      start_date: [null, Validators.required],
      frequency_id: [null, Validators.required],
      delivery_percentage: [null, Validators.required],
      delivery_amount: [null, Validators.required],
      delivery_date: [null, Validators.required]
    });

    this.disableFields();
  }

  completeForm(): void {
    if (this.business) {
      this.patchFormValues();
      this.formData.emit({ ...this.form.getRawValue() });
    }
  }

  setOptions(): void {
    this.frencuencies = [
      {id: 1, label: 'Mensual'},
      {id: 2, label: 'Bimensual'},
      {id: 3, label: 'Trimestral'},
      {id: 4, label: 'Semestral'},
      {id: 5, label: 'Anual'},
    ]
  }

  private disableFields(): void {
    this.form.controls['separation_amount'].disable();
    this.form.controls['quote_amount'].disable();
    this.form.controls['delivery_amount'].disable();
    this.form.controls['separation_date'].disable();
    this.form.controls['start_date'].disable();
    this.form.controls['delivery_date'].disable();
  }

  private patchFormValues(): void {
    const separation = (this.business?.property?.price_base * this.business?.project?.percent_separation) / 100;
    const quotes = (this.business?.property?.price_base * this.business?.project?.percent_cuotes) / 100;
    const delivery = (this.business?.property?.price_base * this.business?.project?.percent_delivery) / 100;

    this.form.patchValue({
      separation_percentage: this.business?.project?.percent_separation,
      separation_amount: parseFloat(separation.toFixed(2)),
      quotes_percentage: this.business?.project?.percent_cuotes,
      quote_amount: parseFloat(quotes.toFixed(2)),
      delivery_percentage: this.business?.project?.percent_delivery,
      delivery_amount: parseFloat(delivery.toFixed(2))
    });
  }

  changeOption(): void {
    this.enableStartDate();
    this.calculatePaymentSchedule();
    this.formData.emit({ ...this.form.getRawValue() });
  }

  private enableStartDate(): void {
    if (this.form.get('quote_count').value && this.form.get('frequency_id').value && this.form.get('quotes_percentage').value) {
      this.form.controls['start_date'].enable();
    } else {
      this.form.controls['start_date'].disable();
    }
  }

  calculateSeparation(e: any, key: number): void {
    console.log({...this.form.getRawValue(), file: 'percentage-plan ~ 111'});
    switch (key) {
      case 1:
        let sep_per = this.form.get('separation_percentage').value || this.business?.project?.percent_separation;
        let amount = (this.business?.property?.price_base * sep_per) / 100;
        amount = amount - this.form.get('reserve_amount').value;
        amount = parseFloat(amount.toFixed(2));
        this.validateAmount(amount);
        this.validatePercent();
        this.form.get('separation_amount').setValue(amount);
        this.formData.emit({...this.form.getRawValue()});
        break;
        case 2:
          this.form.get('separation_percentage').setValue(e.target.value);
          let sep = this.form.get('separation_percentage').value || this.business?.project?.percent_separation;
          let monto = (this.business?.property?.price_base * sep) / 100;
          monto = monto - this.form.get('reserve_amount').value;
          monto = parseFloat(monto.toFixed(2));
          this.validateAmount(monto);
          this.validatePercent();
        this.form.get('separation_amount').setValue(monto);
        this.formData.emit({...this.form.getRawValue()});
        break;

      default:
        break;
    }
  }

  calculateQuotes(e: any, key: number): void {
    switch (key) {
      case 1:
        const value: any = parseFloat(e.target.value);
        if (value > 0 && value !== 'NaN') {
          this.form.get('quote_count').setValue(value);
        }
        const quo_per = this.form.get('quotes_percentage').value || this.business?.project?.percent_cuotes;
        const amount = (this.business?.property?.price_base * quo_per) / 100;
        let quotes = amount / value;
        quotes = parseFloat(quotes.toFixed(2));
        this.form.get('quote_amount').setValue(quotes);
        if(this.form.get('quote_count').value && this.form.get('frequency_id').value && this.form.get('quotes_percentage').value) {
          this.form.controls['start_date'].enable();
        } else {
          this.form.controls['start_date'].disable();
        }
        if(this.form.get('start_date').value) {
          const schedule = this.calculatePaymentSchedule();
        }
        break;
      case 2:
        const val = parseFloat(e.target.value);
        this.form.get('quotes_percentage').setValue(val);
        this.validatePercent();
        const quo = this.form.get('quotes_percentage').value || this.business?.project?.percent_cuotes;
        let monto = (this.business?.property?.price_base * quo) / 100;
        monto = parseFloat(monto.toFixed(2));
        const count = this.form.get('quote_count').value || 1;
        let cuota = monto / count;
        cuota = parseFloat(cuota.toFixed(2));
        this.form.get('quote_amount').setValue(cuota);
        if(this.form.get('quote_count').value && this.form.get('frequency_id').value && this.form.get('quotes_percentage').value) {
          this.form.controls['start_date'].enable();
        } else {
          this.form.controls['start_date'].disable();
        }
        if(this.form.get('start_date').value) {
          const schedule = this.calculatePaymentSchedule();
        }
        break;

      default:
        break;
    }
    this.formData.emit({...this.form.getRawValue()});
  }

  onDateChange(event, param): void {
    this.updateMinDates(param);
    this.formData.emit({ ...this.form.getRawValue() });
  }

  private updateMinDates(param: string): void {
    switch (param) {
      case 'reserve':
        this.minSeparationDate = new Date(this.form.get('reserve_date').value);
        this.minSeparationDate.setDate(this.minSeparationDate.getDate() + 1);
        this.form.controls['separation_date'].enable();
        break;
      case 'separation':
        this.minQuotesDate = new Date(this.form.get('separation_date').value);
        this.minQuotesDate.setDate(this.minQuotesDate.getDate() + 1);
        this.enableStartDate();
        break;
      case 'quotes':
        if(this.form.get('quote_count').value && this.form.get('frequency_id').value) {
          const schedule = this.calculatePaymentSchedule();
          const lastElementDate = schedule[schedule.length - 1].date;
          this.minDeliveryDate = new Date(lastElementDate);
          this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 2);
          this.form.controls['delivery_date'].enable();
        }
        break;
    }
  }

  private calculatePaymentSchedule(): any {
    let schedule: any[] = [];
    if (this.form.get('quote_count').value && this.form.get('start_date').value) {
      schedule = this.calculateSchedule(
        this.form.get('quote_count').value,
        this.form.get('frequency_id').value,
        this.form.get('start_date').value
      );
    }
    return schedule;
  }

  private calculateSchedule(quantity: number, frencuency: number, startPayment: Date): any[] {
    const paymentSchedule: any[] = [];
    const datePipe = new DatePipe('en-US');

    for (let i = 1; i <= quantity; i++) {
      let paymentDate = this.getPaymentDate(frencuency, startPayment, i);
      paymentSchedule.push({
        date: datePipe.transform(paymentDate, 'yyyy-MM-dd'),
        quote_id: i
      });
    }

    return paymentSchedule;
  }

  private getPaymentDate(frencuency: number, startPayment: Date, index: number): Date {
    let paymentDate: Date;
    switch (frencuency) {
      case 1:
        paymentDate = new Date(startPayment);
        paymentDate.setMonth(paymentDate.getMonth() + (index - 1));
        break;
      case 2:
        paymentDate = new Date(startPayment);
        paymentDate.setMonth(paymentDate.getMonth() + (index - 1) * 2);
        break;
      case 3:
        paymentDate = new Date(startPayment);
        paymentDate.setMonth(paymentDate.getMonth() + (index - 1) * 3);
        break;
      case 4:
        paymentDate = new Date(startPayment);
        paymentDate.setMonth(paymentDate.getMonth() + (index - 1) * 6);
        break;
      case 5:
        paymentDate = new Date(startPayment);
        paymentDate.setFullYear(paymentDate.getFullYear() + (index - 1));
        break;
    }
    return paymentDate;
  }

  validateAmount(amount: number): void {
    if (amount < 0) {
      this.valid = false;
      if (this.messages.length > 0) {
        const finded = this.messages.find(item => item.label === 'El monto de separación no debe ser negativo');
        if (!finded) {
          this.messages = [...this.messages, {label: 'El monto de separación no debe ser negativo'}];
        }
      } else {
        this.messages = [...this.messages, {label: 'El monto de separación no debe ser negativo'}];
      }
    } else {
      const finded = this.messages.findIndex(item => item.label === 'El monto de separación no debe ser negativo');
        if (finded !== -1) {
          this.messages.splice(finded, 1)[0]
        }
    }
  }

  validatePercent(): void {
    const sep = this.form.get('separation_percentage').value;
    const quo = this.form.get('quotes_percentage').value;
    const dely = this.form.get('delivery_percentage').value;
    const sum = parseFloat(sep) + parseFloat(quo) + parseFloat(dely);
    console.log(sum);
    if (sum !== 100) {
      if (this.messages.length > 0) {
        const finded = this.messages.find(item => item.label === 'La suma de los campos % debe ser igual a 100');
        if (!finded) {
          this.messages = [...this.messages, {label: 'La suma de los campos % debe ser igual a 100'}];
        }
      } else {
        this.messages = [...this.messages, {label: 'La suma de los campos % debe ser igual a 100'}];
      }
      this.valid = false;
      return;
    } else {
      const finded = this.messages.findIndex(item => item.label === 'La suma de los campos % debe ser igual a 100');
        if (finded !== -1) {
          this.messages.splice(finded, 1)[0]
        }
    }
  }

  calculateDelivery(e: any): void {
    const price = parseFloat(this.business?.property?.price_base);
    const percent = parseFloat(e.target.value);
    this.form.get('delivery_percentage').setValue(percent);
    this.validatePercent();
    let delAmount = (price * percent) / 100;
    delAmount = parseFloat(delAmount.toFixed(2));
    this.form.get('delivery_amount').setValue(delAmount);
    this.formData.emit({ ...this.form.getRawValue() });
  }

}
