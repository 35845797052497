import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProjectService } from '@support/services/project.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { Subscription } from 'rxjs';
import { AdvanceProject, Bank, Currency, Project, StatusProject, TypeAccount } from '@support/models/project.model';
import { EMAIL_REGEXP, NAME_PATTERN } from 'src/app/shared/Enums/regexAuth.enum';
import { ICompanyPromoter } from '@support/models/companiePromoter';
// import { MatDialogRef } from '@angular/material/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Collection } from '@support/models/collection.model';
import { CollectionService } from '@support/services/collection.service';
import { RejectionReasonsService } from '@support/services/rejectionReasons.service';

@Component({
  selector: 'app-cancel-charges',
  templateUrl: './cancel-charges.component.html',
  styleUrls: ['./cancel-charges.component.css']
})
export class CancelChargesComponent implements OnInit {
  public form: FormGroup;
  public loading = false;
  public isSubmit = false;
  public projectId: number;
  public project: Project;
  public NAME_PATTERN: any = NAME_PATTERN;
  public EMAIL_REGEX: any = EMAIL_REGEXP;
  public isEditing = false;
  public logoPromoterCompanyImg:File;
  public logoProjectImg:File;
  public signReceiptImg:File;
  public logoPromoterCompanyImgUrl:string;
  public logoProjectImgUrl:string;
  public signReceiptImgUrl:string;
  public loadImage = false;
  public loadDirection = false;
  public advanceProject:AdvanceProject[]=[];
  public currencys:Currency[]=[];
  public banks:Bank[]=[];
  public typesAccount:TypeAccount[]=[];
  public subscription:Subscription;
  public promoterCompanie:ICompanyPromoter[]=[];
  public notificacionSellerValue = false;
  public notificacionClientValue = false;
  public currentLocation:string;
  public status:StatusProject[] = [];
  public statusProject = false;
  public collection: Collection
  public reasons: any;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _project: ProjectService,
    private _snackBar: NotificationsService,
    private router:Router,
    private route: ActivatedRoute,
    private _collections:CollectionService,
    private _rejection: RejectionReasonsService,
    // public dialogRef: MatDialogRef<CancelChargesComponent>
  ) { }

  ngOnInit() {
    this.init();
  }

  init():void{
    this.collection = this.config.data.collection
    this.createForm();
    this.getRejectionReasons();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.projectId = Number(params.get('id'));
      if (this.projectId) {
        this.isEditing = true;
        // this.getProject();
      }else{

      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      reason_id: ['', Validators.required],
      observations: [''],
    });
  }


  // getProject(): void {
  //   this._project.detail(this.projectId).subscribe(
  //     (response: any) => {
  //       if (response && response.data) {
  //         this.project = response.data;
  //         // if (this.project) this.completeForm();
  //       }
  //     },
  //     (error: HttpErrorResponse) => {
  //       this._snackBar.openSnackBar('Ha ocurrido un error al consultar el detalle del rol', 'OK');
  //     }
  //   );
  // }


  cancelPayment() {
    this.loading = true;
    const data = {
      ...this.form.getRawValue(),
      collection_id: this.collection.id,
    };
    console.log('aca', data);
    this._collections.cancel(this.collection.id, data).subscribe(
      async(res: any) => {
        this._snackBar.openSnackBar('Cobro Anulado', 'OK');
        this.form.reset();
        this.closeDialog(true);
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error) {
          console.log(err);
          this._snackBar.openSnackBar(err.error.error, 'OK');
        } else {
          this._snackBar.openSnackBar('Ocurrio un error al conciliar el cobro', 'OK');
        }
        this.closeDialog();
        this.loading = false;
      }
    )
  }

  showError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && control.touched;
  }

  closeDialog(success: boolean = false): void {
    this.ref.close(success);
  }

  getRejectionReasons():void{
    this._rejection.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.reasons = res.data
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar los motivos de rechazo', 'OK');
        // this.loading = false;
      }
    )
  }
}
