import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BankAccountsService } from '@support/services/bankAccounts.service';
import { ProjectService } from '@support/services/project.service';
import { CollectionTypesService } from '@support/services/collectionTypes.service';
import { CollectionStatusService } from '@support/services/collectionStatus.service';
import { CollectionService } from '@support/services/collection.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MioService } from '@support/services/mio.service';
import { now } from 'moment';
import { AuthService } from '@core/auth/services/auth.service';
import { UserData } from '@core/models/user.model';

@Component({
  selector: 'app-create-charge',
  templateUrl: './create-charge.component.html',
  styleUrls: ['./create-charge.component.scss']
})
export class CreateChargeComponent implements OnInit, OnChanges {

  @Input() visible: boolean = false;
  @Input() data: any = null;

  @Output() showModal = new EventEmitter<boolean>();
  @Output() showErrorMsg = new EventEmitter<any>();
  @Output() showSuccessMsg = new EventEmitter<any>();
  @Output() mioData = new EventEmitter<any>();

  public loading: boolean = true;
  public form: FormGroup;
  public bankAccounts: any = [];
  public project: any;
  public showRateInput: boolean = false;
  public submitted: boolean = false;
  public collectionTypes: any = [];
  public collectionStatus: any = [];
  public showMioFields: boolean = false;
  public registerDisabled: boolean = false;
  public mioMessage: string = '';
  public showTransactionFields: boolean = true;
  public paymentOptions: any = [];
  public displayModal: boolean = false;
  public paymentLink: string;
  public newCollection: any = null;
  public currentUser: UserData;

  constructor(
    private _bankAccounts:BankAccountsService,
    private _project:ProjectService,
    private _collectionTypes:CollectionTypesService,
    private _collectionStatus:CollectionStatusService,
    private _collection:CollectionService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private _mio:MioService,
    private _auth: AuthService,
  ) {
    this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
   }

  async ngOnInit(): Promise<void> {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.hasOwnProperty('data') && changes['data'].currentValue) {
      await this.getCollectionTypes();
      this.paymentOptions = [
        {value: 'Efectivo',label: 'Efectivo'},
        {value: 'Transferencia',label: 'Transferencia'},
        {value: 'Deposito',label: 'Deposito'},
        {value: 'Cheque',label: 'Cheque'}
      ];
      await this.getCollectionStatus();
      await this.getBankAccounts();
      await this.getProject();
      await this.createForm();
      await this.getCollections();
      await this.subscribeToFormChanges();
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      concept: new FormControl('', Validators.required),
      bank_account_id: new FormControl('', Validators.required),
      transaction_number: new FormControl(''),
      payment_method: new FormControl('', Validators.required),
      transaction_amount: new FormControl(0,[Validators.required, Validators.min(0.1)]),
      convertion_rate: new FormControl(1,[Validators.min(0.1)]),
      amount: new FormControl( 0, [Validators.required, Validators.min(0.1)]),
      mio_convertion_rate: new FormControl(1,[Validators.min(0.1)]),
      mio_amount: new FormControl( 0, [Validators.min(0.1)]),
      transaction_date: new FormControl(''),
      payment_receipt: new FormControl(''),
      collection_type_id: new FormControl('', Validators.required),
      collection_status_id: new FormControl('', Validators.required),
    });
    this.form.controls['collection_status_id'].disable();
    this.form.controls['amount'].disable();
    this.form.controls['mio_amount'].disable();
    this.loading = false;
  }

  hideModal(): void {
    this.showModal.emit(false);
  }

  getBankAccounts():void{
    this._bankAccounts.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.bankAccounts = res.data.filter((el: any) => el.project?.id === this.data?.projectId);
          console.log(this.bankAccounts);
          this.loading = false;
        }
      },
      (err:any)=>{
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Error en la consulta',
        };
        this.showErrorMsg.emit(message);
        this.loading = false;
      }
    )
  }

  getProject(): void{
    this._project.detail(this.data?.projectId).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.project = response.data;
          const {gateways} = this.currentUser?.user?.subscription
          if(gateways.length > 0 && this.project?.hasMioCredentials) {
            const mio = gateways.find(item => item.name === 'MIO')
            if(mio && mio?.pivot?.status === 1) {
              console.log('144')
              const newOption = {value: 'Tarjeta de crédito/débito', label: 'Tarjeta de crédito/débito'}
              this.paymentOptions.push(newOption);
            }
          }
        }
      },
      (error: HttpErrorResponse) => {
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Ha ocurrido un error al consultar el proyecto',
        };
        this.showErrorMsg.emit(message);
      }
    );
  }

  getCollectionTypes():void{
    this._collectionTypes.get().subscribe(
      (res:any)=>{
        console.log(res);
        if (res && res.data) {
          this.collectionTypes = res.data
        }
      },
      (err:any)=>{
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Error en la consulta',
        };
        this.showErrorMsg.emit(message);
        // this.isLoading = false;
      }
    )
  }

  getCollectionStatus():void{
    this._collectionStatus.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.collectionStatus = res.data;
          this.form.get('collection_status_id')?.setValue(this.collectionStatus[0]?.id);
        }
      },
      (err:any)=>{
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Error en la consulta',
        };
        this.showErrorMsg.emit(message);
      }
    )
  }

  calculateTotalAmount(isMioPay: boolean = false): void {
    const receivedAmount : number = this.form.get('transaction_amount').value;
    const rate: number = this.form.get('convertion_rate').value;
    const mioRate: number = this.form.get('mio_convertion_rate').value;
    const payment: string = this.form.get('payment_method').value;
    const bank = this.bankAccounts.find((el)=> el.id === this.form.get('bank_account_id').value);

    console.log(bank);

    let totalAmount: number = 0;
    let paymentAmount: number = 0;

    if (this.showRateInput && rate) {
      if (this.project?.currency_id === 1) {
        totalAmount = receivedAmount / rate;
      } else {
        totalAmount = receivedAmount * rate;
      }
    } else {
      totalAmount = receivedAmount;
    }

    if (isMioPay) {
      if (this.showMioFields && mioRate) {
        paymentAmount = receivedAmount * mioRate;
      }
    } else if (payment === 'Tarjeta de crédito/débito') {
      paymentAmount = receivedAmount;
    }

    console.log({paymentAmount});
    this.registerDisabled = paymentAmount > 500000;

    this.form.get('mio_amount').setValue(paymentAmount);

    this.form.get('amount').setValue(totalAmount);
  }


  async setBank(e: any): Promise<void> {
    this.calculateTotalAmount();
    const bank = await this.bankAccounts.find((el: any)=> el.id === e.value);
    if (!bank) return;
    const currencyId = this.project?.currency_id;
    if (bank?.currency?.id !== currencyId) {
      this.showRateInput = true;
      this.form.get('convertion_rate').setValidators([Validators.required]);
    } else {
      this.showRateInput = false;
      this.form.get('convertion_rate').clearValidators();
    }
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return (
      field &&
      field.invalid &&
      (this.submitted || field.touched)
    );
  }

  getErrorMessage (controlName: string): string {

    const field = this.form.get(controlName);

    if(!field || !field.invalid) return null

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    return 'Debe indicar un valor válido'
  }

  subscribeToFormChanges(): void {
    this.form.get('transaction_amount').valueChanges.subscribe(() => {
      const selectedMethod = this.form.get('payment_method').value;
      const selectedBank = this.bankAccounts.find((el: any)=> el.id ===this.form.get('bank_account_id').value);
      const selectedCurrencyId = selectedBank?.currency?.id;
      const isMioPay = selectedMethod === 'Tarjeta de crédito/débito' && selectedCurrencyId !== 2 ? true : false;
      this.calculateTotalAmount(isMioPay);
    });

    this.form.get('convertion_rate').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.form.get('mio_convertion_rate').valueChanges.subscribe(() => {
      const selectedMethod = this.form.get('payment_method').value;
      const selectedBank = this.bankAccounts.find((el: any)=> el.id ===this.form.get('bank_account_id').value);
      const selectedCurrencyId = selectedBank?.currency?.id;
      const isMioPay = selectedMethod === 'Tarjeta de crédito/débito' && selectedCurrencyId !== 2 ? true : false;
      this.calculateTotalAmount(isMioPay);
    });

    this.form.get('bank_account_id').valueChanges.subscribe(() => {
      const selectedMethod = this.form.get('payment_method').value;
      const currencyId = this.project?.currency_id;
      const selectedBank = this.bankAccounts.find((el: any)=> el.id ===this.form.get('bank_account_id').value);
      const selectedCurrencyId = selectedBank?.currency?.id;
      this.showRateInput = selectedCurrencyId !== currencyId;
      this.showMioFields = selectedMethod === 'Tarjeta de crédito/débito' && selectedCurrencyId !== 2;
      this.mioMessage = this.showMioFields ? 'Para procesar el pago con Tarjeta de crédito/débito debe ingresar la tasa de conversión' : '';
      const isMioPay = this.showMioFields ? true : false;
      this.calculateTotalAmount(isMioPay);
    });

    this.form.get('payment_method').valueChanges.subscribe(() => {
      const selectedMethod = this.form.get('payment_method').value;
      const selectedBank = this.bankAccounts.find((el: any)=> el.id ===this.form.get('bank_account_id').value);
      const selectedCurrencyId = selectedBank?.currency?.id;
      this.showMioFields = selectedMethod === 'Tarjeta de crédito/débito' && selectedCurrencyId !== 2;
      this.showTransactionFields = selectedMethod !== 'Tarjeta de crédito/débito' ? true : false;
      this.mioMessage = this.showMioFields ? 'Para procesar el pago con Tarjeta de crédito/débito debe ingresar la tasa de conversión' : '';
      const isMioPay = this.showMioFields ? true : false;
      this.calculateTotalAmount(isMioPay);
      if (!this.showTransactionFields) {
        this.form.get('transaction_number').setValidators([Validators.required]);
        this.form.get('transaction_date').setValidators([Validators.required]);
        this.form.get('payment_receipt').setValidators([Validators.required]);
      } else {
        this.form.get('transaction_number').clearValidators();
        this.form.get('transaction_date').clearValidators();
        this.form.get('payment_receipt').clearValidators();
      }
      console.log(this.showTransactionFields);
    });
  }

  onSelectFile(event: any) {
    const file: File = event.currentFiles[0];
    this.form.patchValue({
      payment_receipt: file,
    });
  }

  onRemoveFile () {
    this.form.patchValue({
      payment_receipt: '',
    });
  }

  register(): void {
    this.submitted = true;
    const bankAccountId = this.form.get('bank_account_id').value;
    let bank: any;
    if (bankAccountId) {
      bank = this.bankAccounts.find((el)=> el.id === bankAccountId).bank;
    }
    console.log(bank);
    console.log(this.form.value)
    if (this.form.invalid) {
      const message = {
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor, complete los datos del formulario.',
      };
      this.showErrorMsg.emit(message);
      return;
    }


    let formData = new FormData();
    const date = this.form.get('transaction_date').value ? this.datePipe.transform(new Date(this.form.get('transaction_date').value),'yyyy-MM-dd') : this.datePipe.transform(new Date(), 'yyyy-MM-dd')

    formData.append('concept', this.form.get('concept').value);
    formData.append('bank_id', bank?.id);
    formData.append('bank_account_id', this.form.get('bank_account_id').value);
    formData.append('transaction_number', this.form.get('transaction_number').value);
    formData.append('transaction_amount', this.form.get('transaction_amount').value);
    formData.append('convertion_rate', this.form.get('convertion_rate').value || 1);
    formData.append('amount', this.form.get('amount').value);
    formData.append('payment_method', this.form.get('payment_method').value);
    formData.append('transaction_date', date);
    formData.append('payment_receipt', this.form.get('payment_receipt').value);
    formData.append('collection_type_id', this.form.get('collection_type_id').value);
    formData.append('collection_status_id', this.form.get('collection_status_id').value);
    formData.append('business_id', this.data.businessId);
    formData.append('client_id', this.data.clientId);

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    this.loading = true;

    const url =this._collection.create(formData);

  url.subscribe(
    (res: any) => {
      this.loading = false;
      console.log(res, 'creacion');
      const msg = 'Se ha registrado el cobro con éxito.'
      const message = {
        severity: 'success',
        summary: 'Cobro registrado',
        detail: msg,
      };
      if (this.form.get('payment_method').value !== 'Tarjeta de crédito/débito') {
        this.completeTransaction();
        return;
      }
      // this.showSuccessMsg.emit(message);
      this.newCollection = res?.data;
          this.newCollection.mio_amount = this.form.get('mio_amount').value.toFixed(2);
          this.newCollection.mio_rate = this.form.get('mio_convertion_rate').value.toFixed(2);
          const credentials = res?.data?.mioCredentials;
          const mioData = {
            grant_type: "client_credentials",
            client_id: credentials?.client_id,
            client_secret: credentials?.client_secret,
            scope: "api_orders_post"
          }
          this._mio.getMioToken(mioData, this.newCollection).subscribe(
            (res: any) => {
              console.log(res);
              if (res?.displayModal) {
                this.completeTransaction(true, res?.displayModal, res?.link);
              }
            },
            (error: any) => {
              this.loading = false;
              this.showSuccessMsg.emit(message);
              // this._snackBar.openSnackBar('Se ha registrado el cobro pero no se pudo crear la orden de pago', 'OK');
              // setTimeout(() => {
              //   this.router.navigate(['/charges']);
              // }, 4000);
            }
          );
      setTimeout(() => {
        this.hideModal();
      }, 2000);
    },
    (err: HttpErrorResponse) => {
      console.log(err);
      this.loading = false;
      if (!err.ok && err.error) {
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
        };
        this.showErrorMsg.emit(message);
      } else {
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Error. Por favor intente más tarde.',
        };
        this.showErrorMsg.emit(message);
      }
      setTimeout(() => {
        this.hideModal();
      }, 2000);
    }
  );

  }

  completeTransaction(noRedirect: boolean = false, showModal: boolean = false, link: any = null): void {
    this.loading = false;
    const msg = 'Se ha registrado el cobro con éxito.'
      const message = {
        severity: 'success',
        summary: 'Cobro registrado',
        detail: msg,
      };
      this.showSuccessMsg.emit(message);
      if (noRedirect) {
        this.displayModal = showModal;
        this.paymentLink = link;
        this.mioData.emit({displayModal: this.displayModal, paymentLink: this.paymentLink})
        return;
      }
  }

  async getCollections(): Promise<void> {
    this.loading = true;

    this._collection.byBusiness(this.data.businessId).subscribe(
      async (res: any) => {
        console.log(res);
        if (res && res.data) {
          const collections = res.data;
          if (collections.length === 0) {
             await this.setDefaultValues();
            this.form.controls['collection_type_id'].disable();
            this.form.controls['collection_status_id'].disable();
          }
        }
      },
      (err: any) => {
        const message = {
          severity: 'error',
          summary: 'Error',
          detail: 'Error al consultar cobros',
        };
        this.showErrorMsg.emit(message);
      },
      () => {
        this.loading = false; // Mover el cambio de loading a la función de completado del observable
      }
    );
  }

  setDefaultValues(): void {
    if(this.collectionStatus.length > 0 && this.collectionTypes.length > 0){
      this.form.get('collection_type_id')?.setValue(this.collectionTypes[0]?.id);
      this.form.get('collection_status_id')?.setValue(this.collectionStatus[0]?.id);
    }

  }


  convertirNumeroALetras(numero: number): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const decenas2 = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const centenas = ['cien', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];

    let entero = Math.floor(numero);
    let decimal = Math.round((numero - entero) * 100);

    let enteroEnLetras = '';
    let decimalEnLetras = '';

    if (entero > 0) {
         enteroEnLetras = this.convertirNumeroALetrasEntero(entero);
    }

    if (decimal > 0) {
        decimalEnLetras = `con ${decimal} centavos`;
    }

    return `${enteroEnLetras} ${decimalEnLetras}`.trim();
}

  convertirNumeroALetrasEntero(numero: number): string {
    const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
    const decenas = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
    const decenas2 = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
    const centenas = ['cien', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];

    if (numero < 10) {
        return unidades[numero];
    } else if (numero < 20) {
        return decenas[numero - 10];
    } else if (numero < 100) {
        const u = numero % 10;
        const d = Math.floor(numero / 10);
        if (u === 0) {
            return decenas2[d];
        } else {
            return decenas2[d] + ' y ' + unidades[u];
        }
    } else if (numero < 1000) {
        const c = Math.floor(numero / 100);
        const resto = numero % 100;
        if (resto === 0) {
            return centenas[c];
        } else {
            return centenas[c] + ' ' + this.convertirNumeroALetrasEntero(resto);
        }
    } else {
        return 'Número fuera de rango';
    }
  }

}
