import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SpinnerComponent } from "./components/spinner/spinner.component";
// import { ApprovalModalComponent } from '../modules/support/pages/charges/approval-modal/approval-modal.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {FileUploadModule} from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {CardModule} from 'primeng/card';
import {TimelineModule} from 'primeng/timeline';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { ChipModule } from 'primeng/chip';
import {MenuModule} from 'primeng/menu';
import {InputTextModule} from 'primeng/inputtext';

import { SecurityCodeFormComponent } from './components/security-code-form/security-code-form.component';


@NgModule({
  declarations: [
    SpinnerComponent,
    UploadDocumentComponent,
    SecurityCodeFormComponent,
    // ApprovalModalComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSnackBarModule,
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    DropdownModule,
    InputTextareaModule,
    CardModule,
    TimelineModule,
    ScrollPanelModule,
    ChipModule,
    MenuModule,
    InputTextModule
  ],
  exports: [
    SpinnerComponent,
    UploadDocumentComponent,
    SecurityCodeFormComponent,
    UploadDocumentComponent,
  ]
})
export class ToolsModule { }
