import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@support/services/project.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { CollectionService } from '@support/services/collection.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RejectionReasonsService } from '@support/services/rejectionReasons.service';
import { Collection } from '@support/models/collection.model';

@Component({
  selector: 'app-reject-charges',
  templateUrl: './reject-charges.component.html',
  styleUrls: ['./reject-charges.component.css']
})
export class RejectChargesComponent implements OnInit {

  public form: FormGroup;
  public collection: Collection
  public loading: boolean = false
  public reasons: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private _project: ProjectService,
    private _snackBar: NotificationsService,
    private _collections:CollectionService,
    private _rejection: RejectionReasonsService,
  ) { }

  ngOnInit() {
    this.init();
  }

  init(): void{
    this.collection = this.config.data.collection
    this.createRejectionForm();
    this.getRejectionReasons();
  }

  getRejectionReasons():void{
    this._rejection.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.reasons = res.data
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar los motivos de rechazo', 'OK');
        // this.loading = false;
      }
    )
  }

  createRejectionForm(): void {
    this.form = this.fb.group({
      reason_id: ['', Validators.required],
    });
  }

  showError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && control.touched;
  }

  rejectPayment() {
    this.loading = true;
    const data = {
      ...this.form.getRawValue(),
      collection_id: this.collection.id,
    };
    this._collections.reject(data).subscribe(
      async(res: any) => {
        this._snackBar.openSnackBar('Cobro rechazado', 'OK');
        this.form.reset();
        this.closeDialog(true);
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.error) {
          console.log(err);
          this._snackBar.openSnackBar(err.error.error, 'OK');
        } else {
          this._snackBar.openSnackBar('Ocurrio un error al conciliar el cobro', 'OK');
        }
        this.closeDialog();
        this.loading = false;
      }
    )
  }

  closeDialog(success: boolean = false): void {
    this.ref.close(success);
  }
}
