import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {BookType, WritingOptions, utils, writeFile } from 'xlsx';

export const FORMAT_XLSX = 'xlsx'
export const FORMAT_CSV = 'csv'

export type ColumnExcel = {
  title: string
  datakey: string
  type?: string
}

export type ExporFileOptions <DataType> = {
  dataSource: DataType [],
  columns: ColumnExcel[],
  filename: string,
  format: BookType,
  options?: WritingOptions
}

export type configOptions = {
  dateFormat: string
}

@Injectable({
  providedIn: 'root'
})

export class ExcelService <DataType> {

  private dataSource: DataType[] = []
  private columns: ColumnExcel[] = []
  private config: configOptions

  constructor(
    private _datePipe: DatePipe,
  ) {}

  private setData (data: DataType[], columns: ColumnExcel[]) {
    this.dataSource = data,
    this.columns = columns
  }

  private formatData (): Record<string, any>[]  {
    return this.dataSource.map((item) => {
      let object: Record<string, any> = {};

      this.columns.forEach((col => {
        let value = col.datakey.split('.').reduce((accumulator, key) => accumulator && accumulator[key], item) as string
        if (this.config?.dateFormat && col.type === 'datetime') {
          value = this._datePipe.transform(new Date(value), this.config.dateFormat)
        }
        object = {...object, [col.title]: value}
      }))

      return object
    })

  }

  setConfig (opts: configOptions) {

    if(!this.config){
      this.config = opts
      return
    }

    Object.keys(opts).forEach(key => {
      this.config[key] = opts[key]
    })

  }

  exportFile (opts: ExporFileOptions<DataType>) {
    const { dataSource, columns, filename, format, options  } = opts

    this.setData( dataSource, columns)
    const rows = this.formatData()
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet);
    const datetime = this._datePipe.transform(new Date(), 'yyyyMMddhhmmss')
    writeFile(workbook, `${filename}_${datetime}.${format}`, { compression: true, ...options});

  }
}
