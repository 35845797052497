<app-spinner *ngIf="loading"></app-spinner>
<div class="full-width">
  <form *ngIf="form" [formGroup]="form">
    <div class="row mx-0 md:mx-5">
      <div class="row mb-5">
        <div class="col-md-12 col-lg-12 mt-2">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="reason"
              placeholder="Seleccione un motivo"
              styleClass="w-100"
              [options]="reasons"
              (onChange)="changeCurrency($event)"
              formControlName="reason_id"
              optionLabel="name"
              optionValue="id">
            </p-dropdown>
            <label for="reason">Motivo de rechazo *</label>
            <small *ngIf="showError('reason_id')" class="p-error block">El campo es obligatorio</small>
          </span>
        </div>
      </div>
    </div>
    <div class="form-footer">
      <button pButton (click)="rejectPayment()" label="Guardar" class="p-button-raised p-button-secondary mr-1"></button>
      <button type="button" pButton (click)="closeDialog()" label="Cancelar" class="p-button-raised p-button-text"></button>
    </div>
  </form>
</div>
