<div class="container">
  <form [formGroup]="form">
    <div *ngIf="!valid" class="row mt-3">
      <div class="col-12">
        <p-message *ngFor="let message of messages;" styleClass="w-100" severity="error" [text]="message.label"></p-message>
      </div>
    </div>
    <div class="row my-4 justify-content-between">
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <input
            currencyMask
            formControlName="reserve_amount"
            class="w-100"
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            id="reserve_amount"
            type="text"
            pInputText
            (keypress)="calculateSeparation($event, 1)"
            (keyup)="calculateSeparation($event, 1)"
            (keydown)="calculateSeparation($event, 1)"
            (input)="calculateSeparation($event, 1)"
            (change)="calculateSeparation($event, 1)"
            (focus)="calculateSeparation($event, 1)"
          >
          <label for="reserve_amount">Monto de reserva *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <p-calendar appendTo="body" formControlName="reserve_date" styleClass="w-100" placeholder="Fecha de reserva" id="reserve_date" [minDate]="minDate"  [showIcon]="true" inputId="reserve_date" (onSelect)="onDateChange($event, 'reserve')"></p-calendar>
          <label for="reserve_date">Fecha de reserva *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <input
            formControlName="separation_percentage"
            inputId="separation_percentage"
            class="w-100"
            pInputText
            currencyMask
            [options]="{ prefix: '', thousands: ',', decimal: '.', allowNegative: false }"
            (keypress)="calculateSeparation($event, 2)"
            (keyup)="calculateSeparation($event, 2)"
            (keydown)="calculateSeparation($event, 2)"
            (input)="calculateSeparation($event, 2)"
            (change)="calculateSeparation($event, 2)"
            (focus)="calculateSeparation($event, 2)"
          >
        <!-- </p-inputNumber> -->
          <label for="separation_percentage">Separación (%) *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <input currencyMask formControlName="separation_amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="separation_amount" type="text" pInputText>
          <label for="separation_amount">Monto de separación *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <p-calendar appendTo="body" formControlName="separation_date" styleClass="w-100" placeholder="Fecha de separación" id="separation_date" [minDate]="minSeparationDate"  [showIcon]="true" inputId="separation_date" (onSelect)="onDateChange($event, 'separation')"></p-calendar>
          <label for="separation_date">Fecha de separación *</label>
        </span>
      </div>
    </div>
    <div class="row mb-4 justify-content-between">
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <input
            formControlName="quotes_percentage"
            class="w-100"
            inputId="quotes_percentage"
            pInputText
            currencyMask
            [options]="{ prefix: '', thousands: ',', decimal: '.', allowNegative: false }"
            (keypress)="calculateQuotes($event, 2)"
            (keyup)="calculateQuotes($event, 2)"
            (keydown)="calculateQuotes($event, 2)"
            (input)="calculateQuotes($event, 2)"
            (change)="calculateQuotes($event, 2)"
            (focus)="calculateQuotes($event, 2)"
          >
        <!-- </p-inputNumber> -->
          <label for="quotes_percentage">Cuotas (%) *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <input currencyMask formControlName="quote_amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="quote_amount" type="text" pInputText>
          <label for="quote_amount">Monto de cuotas *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <p-inputNumber
            class="w-100"
            inputId="number"
            formControlName="quote_count"
            (keypress)="calculateQuotes($event, 1)"
            (keyup)="calculateQuotes($event, 1)"
            (keydown)="calculateQuotes($event, 1)"
            (input)="calculateQuotes($event, 1)"
            (change)="calculateQuotes($event, 1)"
            (focus)="calculateQuotes($event, 1)"
          ></p-inputNumber>
          <label for="quote_count">Cantidad de cuotas *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <p-dropdown id="type" formControlName="frequency_id" styleClass="w-100" appendTo="body" [options]="frencuencies" placeholder="Seleccione una opcion" optionLabel="label" optionValue="id" [showClear]="false" (onChange)="changeOption()"></p-dropdown>
          <label for="frequency_id">Frecuencia *</label>
        </span>
      </div>
      <div class="col-lg-2 col-md-12">
        <span class="p-float-label">
          <p-calendar appendTo="body" formControlName="start_date" styleClass="w-100" placeholder="Fecha de inicio" id="start_date" [minDate]="minQuotesDate"  [showIcon]="true" inputId="start_date" (onSelect)="onDateChange($event, 'quotes')"></p-calendar>
          <label for="start_date">Fecha de inicio *</label>
        </span>
      </div>
    </div>
    <div class="row mb-4 justify-content-around">
      <div class="col-lg-4 col-md-12">
        <span class="p-float-label">
          <input
            formControlName="delivery_percentage"
            class="w-100" id="delivery_percentage"
            type="text"
            pInputText
            currencyMask
            [options]="{ prefix: '', thousands: ',', decimal: '.', allowNegative: false }"
            (keypress)="calculateDelivery($event)"
            (keyup)="calculateDelivery($event)"
            (keydown)="calculateDelivery($event)"
            (input)="calculateDelivery($event)"
            (change)="calculateDelivery($event)"
            (focus)="calculateDelivery($event)"
            min="0.00"
            max="100.00"
          >
          <label for="delivery_percentage">Entrega (%) *</label>
        </span>
      </div>
      <div class="col-lg-4 col-md-12">
        <span class="p-float-label">
          <input currencyMask formControlName="delivery_amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="delivery_amount" type="text" pInputText>
          <label for="delivery_amount">Monto de entrega *</label>
        </span>
      </div>
      <div class="col-lg-4 col-md-12">
        <span class="p-float-label">
          <p-calendar appendTo="body" formControlName="delivery_date" styleClass="w-100" placeholder="Fecha de entrega" id="delivery_date" [minDate]="minDeliveryDate"  [showIcon]="true" inputId="delivery_date" (onSelect)="onDateChange($event, 'delivery')"></p-calendar>
          <label for="delivery_date">Fecha de entrega *</label>
        </span>
      </div>
    </div>
  </form>
</div>
