import { BankAccountModel } from '@admin/models/bankAccount';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ClientResquestModel } from '@support/models/client.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<any> {
    let url = `${this.apiUrl}clients/list`;
    return this.http.get(url);
  }

  getBySubscriptionWithBusiness({subscription_id, filter_type}): Observable<any> {
    console.log(filter_type, 'clientes tipo');
    let url = `${this.apiUrl}clients/with-business?`;
    const params = [];

    if (subscription_id) {
        params.push(`subscription_id=${subscription_id}`);
    }
    if (filter_type) {
        params.push(`filter_type=${filter_type}`);
    }

    if (params.length > 0) {
        url += params.join('&');
    }

    return this.http.get(url);
}


  create(body:ClientResquestModel): Observable<any> {
    let url = `${this.apiUrl}clients/create`;
    return this.http.post(url, body);
  }

  update(body:ClientResquestModel, clientId:number): Observable<any> {
    let url = `${this.apiUrl}clients/update/${clientId}`;
    return this.http.post(url, body);
  }

  detail(clientId:number): Observable<any>{
    const url = `${this.apiUrl}client/${clientId}`
    return this.http.get(url);
  }

  delete(clientId:number): Observable<any> {
    let url = `${this.apiUrl}clients/delete/${clientId}`;
    return this.http.delete(url);
  }

  maritalStatus(): Observable<any> {
    let url = `${this.apiUrl}clients/marital/status`;
    return this.http.get(url);
  }

  typesClients(): Observable<any> {
    let url = `${this.apiUrl}clients/type/list`;
    return this.http.get(url);
  }

  originInconmes(): Observable<any> {
    let url = `${this.apiUrl}origin/inconmes`;
    return this.http.get(url);
  }

  statusClients(): Observable<any> {
    let url = `${this.apiUrl}clients/status/list`;
    return this.http.get(url);
  }

  originClients(): Observable<any> {
    let url = `${this.apiUrl}clients/origin/list`;
    return this.http.get(url);
  }

  getCountries(): Observable<any> {
    let url = `${this.apiUrl}countries/list`;
    return this.http.get(url);
  }

  getTypesDocuments(): Observable<any> {
    let url = `${this.apiUrl}types-documents/list`;
    return this.http.get(url);
  }

  getAdditionalTypesDocuments(): Observable<any> {
    let url = `${this.apiUrl}additional-types-documents/list`;
    return this.http.get(url);
  }

  setNewUser(clientId: number, userId: number): Observable<any> {
    let url = `${this.apiUrl}clients/set-user/${clientId}`;
    return this.http.post(url, {user_id: userId});
  }

  uploadDocument(body:FormData): Observable<any> {
    let url = `${this.apiUrl}client/document-upload`;
    return this.http.post(url, body);
  }

  deleteDocument(documentId:number): Observable<any> {
    let url = `${this.apiUrl}client/document/${documentId}`;
    return this.http.delete(url);
  }

}
