import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/services/auth.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { StorageService } from '@tools/services/storage.service';
import { PermissionUserService } from '@tools/services/permission-user.service';
import { BreadcrumbsService } from '@tools/services/breadcrumbs.service';
import { PrimeNGConfig } from 'primeng/api';
import trans from './translations/es.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nearbyCRM';
  isLoaded = true;

  constructor (
    private authSrv: AuthService,
    private _snackBar: NotificationsService,
    private _storage:StorageService,
    private router: Router,
    readonly _permission:PermissionUserService,
    readonly _breadCrumbs: BreadcrumbsService,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {

    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(trans.es);

    const isValidToken = this.authSrv.isAuthenticated();

    if (isValidToken) {
      this.updateUser();
    }
    // else {
    //   // navigate to auth page
    //   this.authSrv.isUserLogged$.next(false);
    //   this.authSrv.user$.next(undefined);
    //   localStorage.clear();
    //   this.router.navigate(['login']);
    // }

  }

  updateUser():void{
    this.isLoaded = false;
      this.authSrv.profile().subscribe(
        (response)=>{
          if(response && response.data){
            const { permissions } = response.data;
            this._storage.setPermission(permissions);
            console.log(response.data.user.isAdminSubs);
            localStorage.setItem('subscriptionId', response.data.user.subscription.id);
            localStorage.setItem('isAdmin', response.data.user.isAdminSubs);
            this.authSrv.isUserLogged$.next(true);
            this.authSrv.user$.next(response.data);
            this.isLoaded = true;
          }
        },
        (err: HttpErrorResponse) => {
          this.isLoaded = true;
          if (
            err.status === 422
          ){
            this._snackBar.openSnackBar(err.error.message, 'OK');
          } else {
            this._snackBar.openSnackBar('Error. Por favor intente más tarde.', 'OK');
          }
        }
      )
  }

}
