<p-dialog
  [header]="'Cargar documento'"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="content">
    <app-spinner *ngIf="loading"></app-spinner>
    <form *ngIf="!loading" [formGroup]="form">
      <div class="row">
        <div class="col-md-12 my-2">
          <span class="p-float-label">
            <textarea
              placeholder="Descripción"
              id="description"
              pInputTextarea
              rows="3"
              id="description"
              type="text"
              class="w-100"
              formControlName="description"
            ></textarea>
            <label for="textarea">Descripción *</label>
          </span>
        </div>
        <div class="col-md-12 my-2">
          <p-fileUpload
            id="document"
            [showUploadButton]="false"
            chooseLabel="Subir documento"
            accept="image/*,application/pdf"
            (onSelect)="onSelectFile($event)"
            (onClear)="onRemoveFile()"
            (onRemove)="onRemoveFile()">
          </p-fileUpload>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Subir"  (click)="saveDocument()" styleClass="p-button-sm p-button-secondary mr-2"></p-button>
    <p-button label="Cancelar" styleClass="p-button-sm p-button-text" (click)="closeModal()"></p-button>
  </ng-template>
</p-dialog>
