import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { CollectionService } from '@support/services/collection.service';
import { DialogService } from 'primeng/dynamicdialog';
// import { ApproveChargesComponent } from '../approve-charges/approve-charges.component';
// import { CancelChargesComponent } from '../cancel-charges/cancel-charges.component';
// import { RejectChargesComponent } from '../reject-charges/reject-charges.component';
import { Collection } from '@support/models/collection.model';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api'
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-list-by-transfers',
  templateUrl: './list-by-transfers.component.html',
  styleUrls: ['./list-by-transfers.component.scss'],
  providers: [MessageService, DialogService]
})
export class ListByTransfersComponent implements OnInit, OnChanges {

  @Input() collections: any;
  @Input() canEdit: any;
  @Input() canCreate: any;
  @Input() user: any;
  @Input() business: any;

  @Output() updateListTransfers = new EventEmitter<boolean>();

  public optionTypes: any[] = [];
  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public isLoading = false;
  public selectedTypeList = '';

  constructor(
    private _collections:CollectionService,
    private dialogService: DialogService,
    private messageService: MessageService,
    @Inject(HttpClient) private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['collections']) {
      this.setColumns();
    }
  }

  updateTransfers(event): void {
    this.updateListTransfers.emit((event.value && event.value != '') ? event.value : null);
  }

  setColumns(): void {
    this.collections.forEach(element => {
      element.user_name = element.user?.name || '';
      element.message = element.log?.message || '';
      element.type = element.log?.task == 'client' ? 'Cliente' : 'Unidad';
    });

    this.cols = [
      { field: 'dateTime', header: 'Fecha', dataKey: 'dateTime' },
      { field: 'type', header: 'Tipo', dataKey: 'type' },
      { field: 'message', header: 'Log', dataKey: 'message' },
      { field: 'user_name', header: 'Realizado por', dataKey: 'user_name' }
    ];

    this.globalFilterFields = this.cols.filter(col => col.dataKey).map(col => col.dataKey);

    this.optionTypes = [
      {
        label: 'Todos',
        value: ''
      },
      {
        label: 'Cliente',
        value: 'client'
      },
      {
        label: 'Unidad',
        value: 'unity'
      },
    ]
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return decodeURIComponent(fileName);
    }
    return '';
  }

  async downloadFile(fileUrl: string) {
    if (!this.isMobileDevice()) {
      const headers = new HttpHeaders({ 'Accept': 'image/jpeg, image/png' });
      this.http.get(fileUrl, { headers: headers, responseType: 'blob' })
      .subscribe(response => {
        const blob = response;
        const file = new File([blob], this.getFileNameFromUrl(fileUrl));
        saveAs(file, this.getFileNameFromUrl(fileUrl));
      });
      this.clear();
      this.showInfo({
        sticky: true,
        severity: 'success',
        summary: 'Comprobante de pago generado',
        detail: 'El comprobante de pago se ha generado correctamente'
      });
    } else {
      let a: any = document.createElement('a');
      a.download = true;
      a.target = '_blank';
      a.href= fileUrl;
      a.click();
    }
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  openApprovalPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    /* const approvalDialog = this.dialogService.open(ApproveChargesComponent, {
      data: {
        collection,
        business: this.business
      },
      header: 'Conciliación de cobro',
      width: '50vw',
      height: '80vh',
      maximizable: true,
    }); */

    /* approvalDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    }); */
  }

  openRejectionPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    /* const rejectDialog = this.dialogService.open(RejectChargesComponent, {
      data: {
        collection
      },
      header: 'Rechazo de cobro',
      width: '50vw',
      maximizable: true,
    }); */

    /* rejectDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    }); */
  }

  openCancelPaymentDialog(id: number) {
    console.log(id);
    const collection = this.collections.find((item: Collection) => item.id === id);
    this.isLoading = true;
    /* const cancelDialog = this.dialogService.open(CancelChargesComponent, {
      data: {
        collection
      },
      header: 'Anular cobro',
      width: '50vw',
      maximizable: true,
    }); */
    /* cancelDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.complete.emit(true);
      }
    }); */
    this.isLoading = false;
  }

  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

}
