<div class="full-width">
  <div class="form-wraper">

    <div class="form-container card">
      <div class="form-body">
        <app-spinner *ngIf="loading"></app-spinner>
        <form [formGroup]="form" *ngIf="!loading && form" class="row mx-0 md:mx-5">

          <div class="divider my-5">
            <h4><mat-icon class="mr-1"> manage_search </mat-icon> Registro de cobro</h4>
            <mat-divider></mat-divider>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Método de pago</mat-label>
                <mat-select formControlName="payment_method">
                  <mat-option value="Efectivo">
                    Efectivo
                  </mat-option>
                  <mat-option value="Transferencia">
                    Transferencia
                  </mat-option>
                  <mat-option value="Depósito">
                    Depósito
                  </mat-option>
                  <mat-option value="Cheque">
                    Cheque
                  </mat-option>
                  <mat-option *ngIf="project?.hasMioCredentials" value="Tarjeta de crédito/débito">
                    Tarjeta de crédito/débito
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-danger small">{{getError('bank')}}</div>
            </div>
            <div class="col-lg-4 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Concepto</mat-label>
                <input matInput formControlName="concept" placeholder="Concepto" type="text" class="form-input">
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Cuenta bancaria</mat-label>
                <mat-select formControlName="bank_account_id" (selectionChange)="setBank($event)">
                  <mat-option *ngFor="let account of bankAccounts" [value]="account.id">
                    {{account.name_account}} - {{account.number_account}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-danger small">{{getError('bank')}}</div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="showRateInput" class="col-md-12 col-lg-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Tasa de conversión</mat-label>
                <input matInput currencyMask formControlName="convertion_rate" placeholder="Tasa de conversión" type="text" [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('convertion_rate')}}</div>
            </div>
            <div class="col-md-12" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Monto de la transacción</mat-label>
                <input matInput currencyMask formControlName="transaction_amount" placeholder="Monto de la transacción" type="text" [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('transaction_amount')}}</div>
            </div>
            <div class="col-md-12" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Monto Total</mat-label>
                <input matInput currencyMask formControlName="amount" placeholder="Monto" type="text" [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('ammount')}}</div>
            </div>
          </div>
          <div *ngIf="showMioFields" class="row">
            <h5 *ngIf="mioMessage !== ''" style="color: brown;">{{ mioMessage }}</h5>
            <h5 *ngIf="registerDisabled">El monto máximo permitido a cobrar por tarjeta es de 500.000,00 DOP</h5>
            <div class="col-md-12 col-lg-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Tasa de conversión</mat-label>
                <input matInput currencyMask formControlName="mio_convertion_rate" placeholder="Tasa de conversión" type="text" [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('mio_convertion_rate')}}</div>
            </div>
            <div class="col-md-12" [ngClass]="showRateInput ? 'col-lg-4' : 'col-lg-6'">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Total a Cobrar</mat-label>
                <input matInput currencyMask formControlName="mio_amount" placeholder="Total a cobrar" type="text" [options]="{ prefix: '', thousands: ',', decimal: '.' }" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('mio_ammount')}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Tipo de pago</mat-label>
                <mat-select formControlName="collection_type_id" [disabled]="form.controls['collection_type_id'].disabled">
                  <mat-option *ngFor="let type of collectionTypes" [value]="type.id">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-danger small">{{getError('status')}}</div>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Estado</mat-label>
                <mat-select formControlName="collection_status_id" [disabled]="form.controls['collection_status_id'].disabled">
                  <mat-option *ngFor="let status of collectionStatus" [value]="status.id">
                    {{status.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="text-danger small">{{getError('status')}}</div>
            </div>
          </div>
          <div *ngIf="showTransactionFields" class="row">
            <div class="col-lg-4 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Número de transacción</mat-label>
                <input matInput formControlName="transaction_number" placeholder="Número de transacción" type="number" class="form-input">
              </mat-form-field>
              <div class="text-danger small">{{getError('amount')}}</div>
            </div>
            <div class="col-lg-4 col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Fecha de transacción</mat-label>
                <input matInput formControlName="transaction_date" type="date" class="form-input">
              </mat-form-field>
              <div *ngIf="onValidator('transaction_date', 'required')" class="text-danger small">
                Este campo es requerido.
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="col-lg-12 mb-3">
                <label for="payment_receipt" class="form-label">Comprobante:</label>
                <div class="col-lg-12">
                  <input class="form-control" type="file" id="payment_receipt" formControlName="payment_receipt" (change)="onFileSelected($event)">
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>

      <div class="form-footer">

        <button color="accent" mat-raised-button type="submit" [disabled]="registerDisabled" (click)="register()" class="btn custom-btn mr-1">
          <span *ngIf="!isEditing">Registrar</span>
          <span *ngIf="isEditing">Actualizar</span>
        </button>

        <button (click)="close()" color="accent" mat-raised-button
          class="btn custom-btn-alt">
          Cancelar
        </button>
      </div>

    </div>
  </div>
</div>
