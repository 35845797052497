<p-dialog [header]="'Detalle de tarea'" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }"
  [draggable]="false" [resizable]="false" (onHide)="hideModal()">
  <ng-template pTemplate="content">
    <div>

      <p-divider [align]="'center'" class="mx-auto flex w-full justify-center my-3 px-3">
        <div class="inline-flex align-items-center bg-[white] px-3 z-1">
          <i class="pi pi-file mr-2"></i>
          <b class="lead"><span style="font-size: 20px !important;">Información General</span></b>
        </div>
        <hr class="absolute my-3 w-[94%]" />
      </p-divider>

      <div class="row">

        <div class="col-6 col-md-6 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Asunto:</label>
              <span>{{ selectedTask.subject }}</span>
            </span>
          </div>
        </div>

        <div class="col-6 col-md-6 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Descripción:</label>
              <span>{{ selectedTask.description }}</span>
            </span>
          </div>
        </div>

        <div class="col-6 col-md-6 my-1" *ngIf="selectedTask?.client">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Duración:</label>
              <span>{{ selectedTask?.duration_time }}</span>
            </span>
          </div>
        </div>

        <div class="col-6 col-md-6 my-1">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Prioridad:</label>
              <span>{{ selectedTask.priority }}</span>
            </span>
          </div>
        </div>

        <div class="col-12 col-md-12 my-1" *ngIf="selectedTask?.client">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Fecha de expiración:</label>
              <span>{{ selectedTask?.expiration_date }}</span>
            </span>
          </div>
        </div>

      </div>

      <p-divider [align]="'center'" class="mx-auto flex w-full justify-center my-3 px-3">
        <div class="inline-flex align-items-center bg-[white] px-3 z-1">
          <i class="pi pi-file mr-2"></i>
          <b class="lead"><span style="font-size: 20px !important;">Referente a</span></b>
        </div>
        <hr class="absolute my-3 w-[94%]" />
      </p-divider>

      <div class="row">
        <div class="col-12 col-md-12 my-1" *ngIf="selectedTask?.client">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Cliente:</label>
              <span>{{ selectedTask?.client?.name }} {{ selectedTask?.client?.last_name }}</span>
            </span>
          </div>
        </div>
        <div class="col-12 col-md-12 my-1" *ngIf="selectedTask?.business">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Negocio:</label>
              <span>{{ selectedTask?.business?.name }} {{ selectedTask?.business?.last_name }}</span>
            </span>
          </div>
        </div>
      </div>

      <p-divider [align]="'center'" class="mx-auto flex w-full justify-center my-3 px-3">
        <div class="inline-flex align-items-center bg-[white] px-3 z-1">
          <i class="pi pi-file mr-2"></i>
          <b class="lead"><span style="font-size: 20px !important;">Propietario</span></b>
        </div>
        <hr class="absolute my-3 w-[94%]" />
      </p-divider>

      <div class="row">
        <div class="col-12 col-md-12 my-1" *ngIf="selectedTask?.salesTeams">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Equipo:</label>
              <span>{{ selectedTask?.salesTeams?.name }}</span>
            </span>
          </div>
        </div>
        <div class="col-12 col-md-12 my-1" *ngIf="selectedTask?.user">
          <div class="flex flex flex-column gap-2 pt-3">
            <span class="">
              <label class="mr-1">Usuario:</label>
              <span>{{ selectedTask?.user?.name }}</span>
            </span>
          </div>
        </div>
      </div>

    </div>
  </ng-template>
</p-dialog>
