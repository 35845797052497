import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

get(params: HttpParams|null = null): Observable<any> {
  let url = `${this.apiUrl}property/list`;
  return this.http.get(url,{
    params: params ?? null
  });
}

status(): Observable<any> {
  let url = `${this.apiUrl}property/status/list?`;
  return this.http.get(url);
}

types(): Observable<any> {
  let url = `${this.apiUrl}property/types/list?`;
  return this.http.get(url);
}

create(body:FormData): Observable<any> {
  let url = `${this.apiUrl}property/create`;
  return this.http.post(url, body);
}

update(body:FormData, propertyId:number): Observable<any> {
  let url = `${this.apiUrl}property/update/${propertyId}`;
  return this.http.post(url, body);
}

updateStatus(body:FormData, propertyId:number): Observable<any> {
  let url = `${this.apiUrl}property/update-status/${propertyId}`;
  return this.http.post(url, body);
}

detail(propertyId:number): Observable<any>{
  const url = `${this.apiUrl}property/${propertyId}`
  return this.http.get(url);
}

delete(propertyId:number): Observable<any> {
  let url = `${this.apiUrl}property/delete/${propertyId}`;
  return this.http.delete(url);
}

generatePdf(projectId, external_access?: boolean): Observable<any>{
  console.log(projectId,'servicio');
  let url = `${this.apiUrl}property/generate-property-report/${projectId}?`
  if(external_access) url +=`external_access=${external_access}`
  return this.http.get(url, { responseType: 'blob' });
}

deleteDocument(documentId:number): Observable<any> {
  let url = `${this.apiUrl}property/document/${documentId}`;
  return this.http.delete(url);
}

uploadDocument(body:FormData): Observable<any> {
  let url = `${this.apiUrl}property/document-upload`;
  return this.http.post(url, body);
}

}
