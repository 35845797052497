import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MioService {

public apiUrl = environment.apiUrl;
public mioAuth: string;
public mioChekout: string;

constructor(
  private http: HttpClient
) {
  this.mioAuth = environment.production ? 'https://auth.geopagos.com' : 'https://auth.preprod.geopagos.com';
  this.mioChekout = environment.production ? 'https://api.mio.com.do' : 'https://api-mio.preprod.geopagos.com';
  // this.mioAuth =  'https://auth.geopagos.com';
  // this.mioChekout =  'https://api.mio.com.do';
}

  loginMio(mioData: any): Observable<any> {
    const url = `${this.mioAuth}/oauth/token`;
    return this.http.post(url, mioData);
  }

  checkoutMio(token: string, data: any): Observable<any> {
    console.log({token_3: token});
    if (!token) {
      console.error('Token no proporcionado');
      // return throwError('Token no proporcionado');
  }
    const url = `${this.mioChekout}/api/v2/orders`;
    const headers = {
      'Authorization': `Bearer ${token}`,
      'skipInterceptor': 'true'
    };
    return this.http.post(url, data, { headers });
  }

  ordersMio(token: string, uuid: string): Observable<any> {
    if (!token) {
      console.error('Token no proporcionado');
      // return throwError('Token no proporcionado');
  }
    const url = `${this.mioChekout}/api/v2/orders/${uuid}`;
    const headers = {
      'Authorization': `Bearer ${token}`,
      'skipInterceptor': 'true'
    };
    return this.http.get(url, { headers });
  }

  verifyOrder(mioData: any, uuid: string): Observable<any> {
    return new Observable(observer => {
      this.loginMio(mioData).subscribe(
          (res: any) => {
              if (res?.access_token) {
                  this.ordersMio(res?.access_token, uuid).subscribe(
                      (data) => {
                          observer.next(data);
                          observer.complete();
                      },
                      (error) => {
                          console.log(error);
                          observer.error(error);
                      }
                  );
              } else {
                  observer.error('No access token received');
              }
          },
          (error: any) => {
              console.log(error);
              observer.error(error);
          }
      );
    });
  }

  createOrder(body:any): Observable<any> {
    let url = `${this.apiUrl}MIO/store-order`;
    return this.http.post(url, body);
  }

  updateOrder(id:number, status: string): Observable<any> {
    const data = {
      status
    }
    let url = `${this.apiUrl}MIO/update-order/${id}`;
    return this.http.patch(url, data);
  }

  sendPaymentLink(body: any): Observable<any> {
    let url = `${this.apiUrl}MIO/send-payment-link`;
    return this.http.post(url, body);
  }

  getMioToken(mioData: any, collection: any): any {
    return new Observable(observer => {
      this.loginMio(mioData).subscribe(
          (res: any) => {
              if (res?.access_token) {
                  this.checkout(res?.access_token, collection).subscribe(
                      (data) => {
                          observer.next(data);
                          observer.complete();
                      },
                      (error) => {
                          console.log(error);
                          observer.error(error);
                      }
                  );
              } else {
                  observer.error('No access token received');
              }
          },
          (error: any) => {
              console.log(error);
              observer.error(error);
          }
      );
    });
  }

  checkout(token: string, collection: any): any {
    return new Observable(observer => {
      if (!collection) {
          console.log('No hay datos para hacer checkout');
          observer.error('No hay datos para hacer checkout');
          return;
      }

      let amount = collection?.mio_amount;
      let monto = 0;
      if (typeof amount === 'number') {
        const toString = (amount).toFixed(2);
        monto = Math.floor(Number(toString?.replace('.', '')));
      } else {
        monto = Math.floor(amount?.replace('.', ''));
      }

      console.log(monto);

      const data = {
          currency: '214',
          items: [
              {
                  id: collection?.id,
                  name: `${collection?.business?.name} - ${collection?.concept} - ${collection?.transaction_date}`,
                  unitPrice: {
                      currency: '214',
                      amount: monto
                  },
                  quantity: 1,
              },
          ],
      };

      this.checkoutMio(token, data).subscribe(
          (res: any) => {
              if (res?.data) {
                  const mioData = {
                      order_id: res?.data?.id,
                      uuid: res?.data?.attributes?.uuid,
                      collection_id: collection?.id,
                      order_number: res?.data?.attributes?.orderNumber,
                      status: res?.data?.attributes?.status,
                      payment_link: res?.data?.attributes?.links?.checkout,
                      rate: collection?.mio_rate,
                      order_amount: amount
                  };

                  this.storeOrder(mioData).subscribe(
                      (storeComplete: boolean) => {
                          observer.next({
                              displayModal: storeComplete,
                              link: mioData.payment_link
                          });
                          observer.complete();
                      },
                      (error: any) => {
                          console.log(error);
                          observer.next({ displayModal: false, link: null });
                          observer.complete();
                      }
                  );
              } else {
                  observer.error('No data received from checkout');
              }
          },
          (error: any) => {
              console.log(error);
              observer.error(error);
          }
      );
    });
  }

  storeOrder(data: any): any {
    return new Observable(observer => {
      this.createOrder(data).subscribe(
          (res: any) => {
              console.log(res);
              observer.next(true);
              observer.complete();
          },
          (error: any) => {
              console.log(error);
              observer.next(false);
              observer.complete();
          }
      );
    });
  }
}
