import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '@support/services/project.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { PermissionUserService } from '@tools/services/permission-user.service';
import { Subscription } from 'rxjs';
import { Project } from '@support/models/project.model';
import { TableColumn } from '@shared/types/tables';
import { Collection } from '@support/models/collection.model';
import { CollectionService } from '@support/services/collection.service';
import { HttpParams } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { ApproveChargesComponent } from './approve-charges/approve-charges.component';
import { RejectChargesComponent } from './reject-charges/reject-charges.component';
import { CancelChargesComponent } from './cancel-charges/cancel-charges.component';
import { AuthService } from '@core/auth/services/auth.service';
import { UserData } from '@core/models/user.model';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { ExcelService, ExporFileOptions, FORMAT_CSV, FORMAT_XLSX } from '@tools/services/excel.service';
import { BusinessService } from '@support/services/business.service';
import { ApprovalPaymentsService } from '@support/services/approvalPayments.service';
import { MioService } from '@support/services/mio.service';
import { saveAs } from 'file-saver';
import { now } from 'moment';


type CollectionStatus = {
  id: number,
  name: string,
  cssClass: string
}

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss'],
  providers: [MessageService, DialogService]
})
export class ChargesComponent implements OnInit {
  public isLoading = false;
  public busy = false
  public columns: TableColumn[] = []
  public globalFilterFields: string[] = []
  public projects: Project[] = [];
  public selectedProject: Project | null = null;
  public canRead = false;
  public canEdit = false;
  public canDelete = false;
  public canCreate = false;
  public subscription: Subscription | null = null;
  public currentUser: UserData;

  public collections: Collection[] = [];
  public filteredCollections: Collection[] = []
  public collectionStatuses: CollectionStatus[] = []
  public selectedStatus: CollectionStatus | null = null
  public exportItems: MenuItem[] = [];
  public exportColumns: Array<{ title: string, datakey: string, type?: string }> = [];
  public business: any;
  public showRateModal = false;
  public selectedCollection: any = null;
  public displayModal: boolean = false;
  public paymentLink: string;


  constructor(
    private _project: ProjectService,
    private _collections: CollectionService,
    private _snackBar: NotificationsService,
    public _permission: PermissionUserService,
    private dialogService: DialogService,
    private _auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private messageService: MessageService,
    private _excelService: ExcelService<Collection>,
    private _business:BusinessService,
    private approvalPaymentsService: ApprovalPaymentsService,
    private _mio:MioService
  ) {
    this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.getProjects()
    this.setPermissions()
    this.setCollectionStatuses()
    this.setInitialStatus()
    this.setTableStructure()

    this.exportItems = [
      { label: 'CSV', icon: 'pi pi-file', command: () => this.exportExcel(FORMAT_CSV) },
      { label: 'Excel', icon: 'pi pi-file-excel', command: () => this.exportExcel(FORMAT_XLSX) },
      { label: 'PDF', icon: 'pi pi-file-pdf', command: () => this.exportPdf() }
    ]

    this.exportColumns = [
      { title: 'Cliente', datakey: 'client.fullname' },
      { title: 'Proyecto', datakey: 'business.project.name_project' },
      { title: 'Inmueble', datakey: 'business.property.name_property' },
      { title: 'Fecha de transacción', datakey: 'transaction_date', type: 'datetime' },
      { title: 'Monto', datakey: 'amount' },
      { title: 'Aplicado a cuota', datakey: 'applied' },
      { title: 'Cuenta bancaria', datakey: 'bank_account.name_account' },
      { title: 'Estado', datakey: 'collection_status.name' },
      { title: 'Nro. de Recibo', datakey: 'approval_payment.receipt_number' },
      { title: 'Razón de anulación', datakey: 'reason_name' },
      { title: 'Observaciones', datakey: 'approval_payment.observations' },
      { title: 'Fecha de anulación', datakey: 'approval_payment.cancel_date', type: 'datetime' },
      { title: 'Cancelado por', datakey: 'user_cancel' },
    ]
  }

  setPermissions(): void {
    this.canRead = this._permission.setPermission().includes('Leer');
    this.canEdit = this._permission.setPermission().includes('Editar');
    this.canCreate = this._permission.setPermission().includes('Crear');
    this.canDelete = this._permission.setPermission().includes('Desactivar');
  }

  setTableStructure(): void {
    this.columns = [
      { title: 'Cliente', dataKey: 'client.fullname', filterable: true, sortable: true },
      { title: 'Proyecto', dataKey: 'business.project.name_project', sortable: true },
      { title: 'Inmueble', dataKey: 'business.property.name_property', filterable: true, sortable: true },
      { title: 'Fecha de transacción', dataKey: 'transaction_date', filterable: true, sortable: true },
      { title: 'Monto', dataKey: 'amount', filterable: true, sortable: true },
      { title: 'Cuenta bancaria', dataKey: 'bank_account.name_account', filterable: true, sortable: true },
      { title: 'Aplicado a Cuota', dataKey: 'applied', filterable: true, sortable: true },
      { title: 'Estado', dataKey: 'collection_status.name', filterable: true, sortable: true },
      { title: 'Nro. de Recibo', dataKey: 'approval_payment.receipt_number', filterable: true, sortable: true },
      { title: 'Razón de anulación', dataKey: 'reason_name', filterable: true, hide: true },
      { title: 'Observaciones', dataKey: 'approval_payment.observations', filterable: true, sortable: true, hide: true },
      { title: 'Fecha de anulación', dataKey: 'approval_payment.cancel_date', filterable: true, sortable: true, hide: true },
      { title: 'Cancelado por', dataKey: 'user_cancel', filterable: true, sortable: true, hide: true },
      { title: 'Acción', hide: false }
    ]

    this.globalFilterFields = this.columns.filter(col => col.title !== 'Acción').map(col => col.dataKey);
  }

  setInitialStatus(): void {
    this.selectedStatus = this.collectionStatuses.find(status => status.id === 1)
  }

  setCollectionStatuses(): void {
    this.collectionStatuses = [
      { id: null, name: 'Todos', cssClass: '' },
      { id: 1, name: 'Sometidos', cssClass: 'p-button-warning' },
      { id: 2, name: 'Aprobados', cssClass: 'p-button-success' },
      { id: 3, name: 'Cancelados', cssClass: 'p-button-danger' },
      { id: 4, name: 'Anulados', cssClass: 'p-button-help' },
    ]
  }

  getProjects(): void {
    this._project.get().subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.projects = [
            { id: null, name_project: 'Todos' },
            ...res.data
          ]
        }
      },
      error: (err: any) => {
        this._snackBar.openSnackBar('Error al cargar lista de proyectos', 'OK');
        this.isLoading = false;
      }
    })
  }

  getCollections(): void {
    let query = {}

    if (this.selectedProject?.id) {
      query['project'] = this.selectedProject.id
    }

    this.busy = true
    const params = new HttpParams({ fromObject: query })
    this._collections.get(params).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.collections = res.data
          this.addClientFullnameToCollections()
          this.filterCollectionByStatus()
        }
        this.busy = false
      },
      error: (err: any) => {
        this._snackBar.openSnackBar('Error al cargar listas de cobros', 'OK');
        this.isLoading = false;
        this.busy = false
      }
    })
  }

  addClientFullnameToCollections(): void {
    this.collections = this.collections.map(item => {
      const fullname = item.client?.name && item.client?.last_name ? `${item.client?.name} ${item.client?.last_name}` : null
      return { ...item, client: { ...item.client, fullname } }
    })
  }

  onChangeProject() {
    if (!this.selectedProject) {
      this.collections = []
      this.filterCollectionByStatus()
      return
    }
    this.getCollections()
  }

  onChangeStatus(status: CollectionStatus) {
    this.selectedStatus = status
    this.filterCollectionByStatus()
  }

  filterCollectionByStatus() {
    this.filteredCollections = this.collections.filter(
      item => this.selectedStatus.id === null || item.collection_status.id === this.selectedStatus?.id
    );
  }

  async openApprovalPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    this.isLoading = true;
    const business = await this.getBusiness(collection?.business?.id);
    if (business) {
      const approvalDialog = this.dialogService.open(ApproveChargesComponent, {
        data: {
          collection,
          business: business
        },
        header: 'Conciliación de cobro',
        width: '50vw',
        height: '80vh',
        maximizable: true,
      });

      approvalDialog.onClose.subscribe((success: boolean | null) => {
        if (success) {
          this.getCollections()
        }
      });
    }
    this.isLoading = false;
  }

  getBusiness(id): any {
    return this._business.detail(id).toPromise().then(
      (response: any) => {
        if (response && response.data) {
          this.business = response.data;
          return this.business;
        }
      },
      (error: any) => {
        console.log(error);
        return null;
      }
    );
  }

  openRejectionPaymentDialog(collectionId: number) {
    const collection = this.collections.find((item: Collection) => item.id === collectionId);
    const rejectDialog = this.dialogService.open(RejectChargesComponent, {
      data: {
        collection
      },
      header: 'Rechazo de cobro',
      width: '50vw',
      maximizable: true,
    });

    rejectDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.getCollections()
      }
    });
  }

  generatePaymentReceipt(collectionId: number) {
    this._collections.getPdfVoucher(collectionId).subscribe((response: Blob) => {
      if (this.isMobileDevice()) {
        // Descargar el archivo PDF en dispositivos móviles
        saveAs(response);
        this.clear();
        this.showInfo({
          sticky: true,
          severity: 'success',
          summary: 'Recibo generado',
          detail: 'El Recibo se ha generado correctamente'
        });
      } else {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  openCancelPaymentDialog(id: number) {
    const collection = this.collections.find((item: Collection) => item.id === id);
    const cancelDialog = this.dialogService.open(CancelChargesComponent, {
      data: {
        collection
      },
      header: 'Anular cobro',
      width: '50vw',
      maximizable: true,
    });
    cancelDialog.onClose.subscribe((success: boolean | null) => {
      if (success) {
        this.getCollections()
      }
    });

  }

  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

  exportPdf() {
    this.showInfo({ sticky: true, severity: 'info', summary: 'Generando reporte', detail: 'El reporte está siendo generado, por favor espere' });
    let query: any = {};

    if (this.selectedProject?.id) {
      query['project_id'] = this.selectedProject.id;
    }

    this._collections.generatePdf(query['project_id'], this.selectedStatus?.id).subscribe((response: Blob) => {
      if (this.isMobileDevice()) {
        // Descargar el archivo PDF en dispositivos móviles
        saveAs(response, 'project_report.pdf');
        this.clear();
        this.showInfo({
          sticky: true,
          severity: 'success',
          summary: 'Reporte generado',
          detail: 'El reporte de disponibilidad se ha generado correctamente'
        });
      } else {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.clear();
        this.showInfo({ sticky: true, severity: 'success', summary: 'Reporte generado', detail: 'El reporte de disponibilidad se ha generado correctamente' });
      }
    },
      (err: any) => {
        this.clear();
        this.showInfo({ sticky: false, severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error al generar el reporte de disponibilidad' });
      });
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  exportExcel(format: string) {

    const options = {
      dataSource: this.filteredCollections,
      columns: this.exportColumns,
      filename: 'charges',
      format: format
    } as ExporFileOptions<Collection>

    this._excelService.exportFile(options)
  }

  showOrderStatus(collection: any): void {
    if(collection?.order && collection?.order?.status === 'SUCCESS') {
      let order = {
        ...collection.order,
        received_amount: collection?.order?.amount
      }
      this.approvePayment(collection, order, collection?.order?.rate);
      return;
    }
    const credentials = collection?.mioCredentials;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.verifyOrder(mioData, collection?.order?.uuid).subscribe(
      (res: any) => {
        console.log(res);
        const order = res?.data;
        if (order) {
          if (order?.attributes?.status === 'SUCCESS') {
            this.approvePayment(collection, order?.attributes, collection?.order?.rate);
          } else if (order?.attributes?.status === 'EXPIRED') {
            this.showInfo({sticky: true, severity: 'warn', summary: 'El link de pago expiró', detail: 'El link de pago ha expirado, debe generar una nueva orden'});
          } else if (order?.attributes?.status === 'REJECTED') {
            this.showInfo({sticky: true, severity: 'danger', summary: 'Pago rchazado', detail: 'El pago ha sido rechazado, por favor contacte al cliente'});
          } else {
            this.showInfo({sticky: true, severity: 'warn', summary: 'Estado Pendiente', detail: 'La orden aún no se ha procesado'});
          }
          setTimeout(() => {
            this._mio.updateOrder(collection?.order?.id, order?.attributes?.status).subscribe(
              (res: any) => {
                console.log(res);
                if(res?.ok) {
                  this.getCollections();
                }
              },
              (error: any) => {
                this.showInfo({sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: 'Hubo un error al intentar guardar la orden'});
                console.log(error);
              }
            );
          }, 8000);
        }
      },
      (error: any) => {
        this.showInfo({sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: 'Hubo un error al verificar el estado de la orden'});
        console.log(error);
      }
    );
  }

  approvePayment(collection: any, order: any, rate: any): void {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const day = String(date.getDate()).padStart(2, '0');
    let formattedAmount = 0;

    const formattedDate = `${year}-${month}-${day}`;
    if(order.received_amount) {
      formattedAmount = order?.received_amount;
    } else {
      formattedAmount = parseFloat((order?.price?.amount / 100).toFixed(2));
    }

    if (rate === '1.00' && collection?.convertion_rate !== '1.00') {
      rate = collection?.convertion_rate;
    }

    let conciliateData = {
      receipt_number: null,
      transaction_date: formattedDate,
      transaction_number: order?.payment?.reference_number,
      received_amount: formattedAmount,
      currency_id: 2,
      rate: rate,
      total_amount: collection?.amount,
      collection_id: collection?.id
    }

    this.approvalPaymentsService.get(collection?.id).subscribe(
      (res:any)=> {
        conciliateData.receipt_number = res.receipt_number;
        this.approve(conciliateData);
      },
      (err:any)=>{
        this.showInfo({sticky: true, severity: 'error', summary: 'Error inesperado', detail: 'Ha ocurrido un error al generar el numero de recibo'});
        console.log(err);
      }
    );
  }

  approve(data: any): void {
    this.approvalPaymentsService.create(data).subscribe(
      async(res: any) => {
        if (res.data) {
          this.showInfo({sticky: true, severity: 'success', summary: 'Operacion exitosa', detail: 'El cobro se ha conciliado con éxito'});
          setTimeout(() => {
            this.getCollections();
          }, 4000);
        }
        console.log(res, 'response');
      },
      (err: any) => {
        if (err.error) {
          console.log(err);
        this.showInfo({sticky: true, severity: 'error', summary: 'Cobro no conciliado', detail: 'La orden fue procesada pero el cobro no pudo ser conciliado'});
          // this._snackBar.openSnackBar(err.error.error, 'OK');
        } else {
        this.showInfo({sticky: true, severity: 'error', summary: 'Cobro no conciliado', detail: 'La orden fue procesada pero el cobro no pudo ser conciliado'});
          // this._snackBar.openSnackBar('Ocurrio un error al conciliar el cobro', 'OK');
          console.log(err);
        }
      }
    )
  }

  saveOrder(e: any): void {
    if (!this.selectedCollection) {
      return;
    }
    this.selectedCollection.mio_amount = e?.amount;
    this.selectedCollection.mio_rate = e?.rate;
    const credentials = this.selectedCollection?.mioCredentials;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.getMioToken(mioData, this.selectedCollection).subscribe(
      (res: any) => {
        console.log(res);
        if (res?.displayModal) {
          this.showInfo({sticky: true, severity: 'success', summary: 'Orden creada', detail: 'La orden de pago se ha creado, en breve podra ver el lin de pago'});
          this.displayModal = res?.displayModal;
          this.paymentLink = res?.link;
        }
      },
      (error: any) => {
        this.showInfo({sticky: true, severity: 'error', summary: 'Error inesperado', detail: 'Ha ocurrido un error al generar la orden de pago'});
        console.log(error);
      }
    );
    console.log(e, 'order 356');
  }

  createOrder(collection: any): void {
    let { order, letters_amount, convertion_rate, transaction_amount, amount } = collection || {};
    let rate, amountValue;

    if (!order) {
        if (letters_amount.includes('Dollar')) {
            if (convertion_rate !== '1.00') {
                rate = convertion_rate;
                amountValue = transaction_amount;
            } else {
              this.showRateModal = true;
              this.selectedCollection = collection;
              return;
            }
        } else {
            rate = 1;
            amountValue = amount;
        }
    } else {
        amountValue = order.amount;
        rate = order.rate;
    }
    const credentials = collection?.mioCredentials;
    collection.mio_amount = collection?.order?.amount;
    collection.mio_rate = collection?.order?.rate;
    const mioData = {
      grant_type: "client_credentials",
      client_id: credentials?.client_id,
      client_secret: credentials?.client_secret,
      scope: "api_orders_post"
    }
    this._mio.getMioToken(mioData, collection).subscribe(
      (res: any) => {
        if (res?.displayModal) {
          this.displayModal = res?.displayModal;
          this.paymentLink = res?.link;
          this.showInfo({sticky: true, severity: 'success', summary: 'Orden creada', detail: 'La orden de pago se ha creado, en breve podra ver el lin de pago'});
        }
      },
      (error: any) => {
        this.showInfo({sticky: true, severity: 'error', summary: 'Error inesperado', detail: 'Ha ocurrido un error al generar la orden de pago'});
        console.log(error);
      }
    );
  }

  closeLinkModal($event): void {
    this.displayModal = $event;
    this.getCollections();
  }

}
