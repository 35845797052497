import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CurrencyModel } from '@support/models/currency';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number): Observable<any> {
    let url = `${this.apiUrl}currency/list?`;
    if(status) url +=`status=${status}`

    return this.http.get(url);
  }

  getStatus(): Observable<any> {
    let url = `${this.apiUrl}currency/status/list`;
    return this.http.get(url);
  }

  create(body:CurrencyModel): Observable<any> {
    let url = `${this.apiUrl}currency/create`;
    return this.http.post(url, body);
  }

  update(body:CurrencyModel, id:number): Observable<any> {
    let url = `${this.apiUrl}currency/update/${id}`;
    return this.http.post(url, body);
  }

  detail(id:number): Observable<any>{
    const url = `${this.apiUrl}currency/${id}`
    return this.http.get(url);
  }

  delete(id:number): Observable<any> {
    let url = `${this.apiUrl}currency/delete/${id}`;
    return this.http.delete(url);
  }

}
