import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Parking } from '@support/models/parking.model';
import { ParkingService } from '@support/services/parking.service';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { NotificationsService } from '@tools/services/notifications.service';
import { Subscription } from 'rxjs';
import { CreateParkingComponent } from './create-parking/create-parking.component';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit, OnDestroy {
  public parkings: Parking[]=[];
  public isLoading = false;
  public displayedColumns: string[] = ['name', 'stage_project_id', 'price', 'status_parking_id', 'actions'];
  public dataSource: MatTableDataSource<Parking>;
  private subscriptions: Record<string, Subscription> = {};

  @Input() projectId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _parkings: ParkingService,
    private _snackBar: NotificationsService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscriptions['parkings'] = this._parkings.updateParkingList.subscribe(
      (update: boolean) => {
        if (update) {
          this.getParkings();
        }
      }
    );
    this.getParkings();
  }

  getParkings():void{
    this._parkings.get(null, this.projectId).subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.parkings = res.data
          this.dataSource = new MatTableDataSource(this.parkings);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar parqueos', 'OK');
        this.isLoading = false;
      }
    )
  }

  delete(id:number): void {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        title: 'Eliminar Parqueo',
        message: '¿Está seguro de que desea eliminar este parqueo? Perderá todos sus datos',
        btnConfirm: true,
        btnCancel: true,
        btnConfirmText:'Aceptar',
        btnCancelText: 'Cancelar'
      }
    })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this._parkings.delete(id).subscribe(
            (res:any)=>{
              this._snackBar.openSnackBar('Parqueo borrado exitosamente.', 'OK');
              this.getParkings();
            },
            (err:any)=>{
              this._snackBar.openSnackBar('Error al borrar Parqueo', 'OK');
              this.isLoading = false;
            }
          )
        }
      });
  }

  edit(parking:Parking): void {
    this.dialog.open(CreateParkingComponent, {
      width: '600px',
      data: {
        parking: parking,
        edit: true
      }
    })
  }

  detail(parking:Parking): void {
    this.dialog.open(CreateParkingComponent, {
      width: '600px',
      data: {
        parking: parking,
        detail: true
      }
    })
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(value => value.unsubscribe());
  }

}
