import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralLayoutComponent } from './core/layout/general-layout/general-layout.component';
import { IsNotLoginGuard } from './core/guards/is-not-login.guard';

const routes: Routes = [

  {
    path: '',
    component: GeneralLayoutComponent,
    loadChildren: () => import('@core/layout/general-layout/general-layout.module').then((m) => m.GeneralLayoutModule),
  },
  {
    path: 'login',
    loadChildren: () => import('@auth/pages/login/login.module').then((m) => m.LoginModule),
    canActivate:[IsNotLoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('@core/auth/pages/register/register.module').then((m) => m.RegisterModule),
    canActivate:[IsNotLoginGuard]
  },
  {
    path: 'forgot',
    loadChildren: () => import('@auth/pages/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    canActivate:[IsNotLoginGuard]
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('@auth/pages/recovery-password/recovery-password.module').then((m) => m.RecoveryPasswordModule),
    canActivate:[]
  },
  {
    path: 'confirm-password/:token',
    loadChildren: () => import('@auth/pages/recovery-password/recovery-password.module').then((m) => m.RecoveryPasswordModule),
    canActivate:[]
  },
  // {
  //   path: '',
  //   redirectTo: 'clients',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
