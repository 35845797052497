import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-reserves',
  templateUrl: './reserves.component.html',
  styleUrls: ['./reserves.component.scss']
})
export class ReservesComponent implements OnInit {
  public reserves:any=[];
  public isLoading = false;
  public displayedColumns: string[] = ['bank', 'date','ammount', 'status'];
  public dataSource: MatTableDataSource<any>;

  @Input() discountId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit(): void {
    this.getReserves();
  }

  getReserves() {
  this.reserves = [
    { bank: 'City Bank', date: '2023-09-20', ammount: 1000, status: 'Aprobado' },
    { bank: 'Wells Fargo', date: '2023-09-21', ammount: 1500, status: 'Pendiente' },
    { bank: 'Banco Panama', date: '2023-09-22', ammount: 800, status: 'Rechazado' },
    { bank: 'Banco del Tesoro', date: '2023-09-23', ammount: 2000, status: 'Aprobado' },
    { bank: 'Bank of America', date: '2023-09-24', ammount: 1200, status: 'Pendiente' },
  ],
  this.dataSource = new MatTableDataSource(this.reserves);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  }

}
