import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-amounts-plan',
  templateUrl: './amounts-plan.component.html',
  styleUrls: ['./amounts-plan.component.scss']
})
export class AmountsPlanComponent implements OnInit {

  public form: FormGroup;
  public frencuencies: any[] = [];
  public minDate: Date;
  public minSeparationDate: Date;
  public minQuotesDate: Date;
  public minDeliveryDate: Date;

  // @Input() business: any;
  @Input() valid: boolean = true;
  @Output() formData = new EventEmitter<object>();

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.setOptions();
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      reserve_amount: [null, Validators.required],
      reserve_date: [null, Validators.required],
      separation_amount: [null, Validators.required],
      separation_date: [null, Validators.required],
      quote_amount: [null, Validators.required],
      quote_count: [null, Validators.required],
      start_date: [null, Validators.required],
      frequency_id: [null, Validators.required],
      delivery_amount: [null, Validators.required],
      delivery_date: [null, Validators.required],
    });

    this.form.controls['separation_date'].disable();
    this.form.controls['start_date'].disable();
    this.form.controls['delivery_date'].disable();
  }

  setOptions(): void {
    this.frencuencies = [
      {id: 1, label: 'Mensual'},
      {id: 2, label: 'Bimensual'},
      {id: 3, label: 'Trimestral'},
      {id: 4, label: 'Semestral'},
      {id: 5, label: 'Anual'},
    ]
  }

  sendData(event): void {
    console.log(event);
    const data = {
      ...this.form.getRawValue()
    }
    this.formData.emit(data);
  }

  onDateChange(event, param): void {
    switch (param) {
      case 'reserve':
          this.minSeparationDate = new Date(this.form.get('reserve_date').value);
          this.minSeparationDate.setDate(this.minSeparationDate.getDate() + 1);
          this.form.controls['separation_date'].enable();
          break;

          case 'separation':
            this.minQuotesDate = new Date(this.form.get('separation_date').value);
            this.minQuotesDate.setDate(this.minQuotesDate.getDate() + 1);
            if(this.form.get('quote_count').value && this.form.get('frequency_id').value) {
              this.form.controls['start_date'].enable();
            } else {
              this.form.controls['start_date'].disable();
            }
            break;

            case 'quotes':
              if(this.form.get('quote_count').value && this.form.get('frequency_id').value) {
                const schedule = this.calculatePaymentSchedule(this.form.get('quote_count').value, this.form.get('frequency_id').value, this.form.get('start_date').value);
                const lastElementDate = schedule[schedule.length - 1].date;
                this.minDeliveryDate = new Date(lastElementDate);
                this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 2);
                this.form.controls['delivery_date'].enable();
              }
        break;

      default:
        break;
    }
    this.formData.emit({...this.form.getRawValue()});
  }

  calculatePaymentSchedule(quantity: number, frencuency: number, startPayment: Date): any[] {
    const paymentSchedule: any[] = [];
    const datePipe = new DatePipe('en-US');

    for (let i = 1; i <= quantity; i++) {
      let paymentDate: Date;
      switch (frencuency) {
        case 1: // Mensual
          paymentDate = new Date(startPayment);
          paymentDate.setMonth(paymentDate.getMonth() + (i - 1));
          break;
        case 2: // Bimestral
          paymentDate = new Date(startPayment);
          paymentDate.setMonth(paymentDate.getMonth() + (i - 1) * 2);
          break;
        case 3: // Trimestral
          paymentDate = new Date(startPayment);
          paymentDate.setMonth(paymentDate.getMonth() + (i - 1) * 3);
          break;
        case 4: // Semestral
          paymentDate = new Date(startPayment);
          paymentDate.setMonth(paymentDate.getMonth() + (i - 1) * 6);
          break;
        case 5: // Anual
          paymentDate = new Date(startPayment);
          paymentDate.setFullYear(paymentDate.getFullYear() + (i - 1));
          break;
      }


      paymentSchedule.push({
        date: datePipe.transform(paymentDate, 'yyyy-MM-dd'),
        quote_id: i,
      });
    }

    return paymentSchedule;
  }

  setQuantities(e): void {
    this.form.get('quote_count').setValue(e.target.value);
    const amount = this.form.get('quote_amount').value;
    const frequency = this.form.get('frequency_id').value;
    if (amount && frequency) {
      this.form.controls['start_date'].enable();
    } else {
      this.form.controls['start_date'].disable();
    }
    const data = {
      ...this.form.getRawValue()
    }
    this.formData.emit(data);
  }

  setAmount(e): void {
    const count = this.form.get('quote_count').value;
    const frequency = this.form.get('frequency_id').value;
    if (count && frequency) {
      this.form.controls['start_date'].enable();
    } else {
      this.form.controls['start_date'].disable();
    }
    const data = {
      ...this.form.getRawValue()
    }
    this.formData.emit(data);
  }

  setFrecuency(e): void {
    this.form.get('frequency_id').setValue(e.value);
    const count = this.form.get('quote_count').value;
    const amount = this.form.get('quote_amount').value;
    if (count && amount) {
      this.form.controls['start_date'].enable();
    } else {
      this.form.controls['start_date'].disable();
    }
    const data = {
      ...this.form.getRawValue()
    }
    this.formData.emit(data);
  }

}
