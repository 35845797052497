<div class="row mx-0 md:mx-5">
  <div class="col-12 d-flex justify-content-end px-5 flex-wrap">
    <p-button label="Añadir cuota" (click)="openCreateQuoteModal()" styleClass="p-button-sm"></p-button>
  </div>
  <div class="col-12 d-flex justify-content-center px-5 pt-3">
    <p-table
      *ngIf="quotes?.length > 0"
      [value]="quotes"
      styleClass="p-datatable-sm p-datatable-striped"
      [columns]="cols"
      [reorderableColumns]="true"
      [tableStyle]="{'min-width': '50rem'}"
      [scrollable]="true"
      scrollHeight="300px"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width:3rem"></th>
            <th *ngFor="let col of columns" pReorderableColumn>
              {{col.header}}
            </th>
            <th style="width:3rem"></th>
        </tr>
    </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
          <td>
            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="deleteQuote(rowData)"></button>
          </td>
      </tr>
      </ng-template>
  </p-table>
  </div>
  <p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptButtonStyleClass="p-button-text p-button-secondary" acceptLabel="Aceptar" rejectLabel="Cancelar"></p-confirmDialog>
  <app-manual-modal [displayModal]="showQuoteModal" (modalClosed)="closeQuoteModal()" (data)="addQuote($event)" [quotes]="quotes"></app-manual-modal>
</div>
