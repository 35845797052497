
<div class="full-width full-height">
  <p-toast></p-toast>
  <div class="form-wrapper">
    <div class="row mx-lg-5 mb-5">
      <!-- <div class="col-12 mt-2" *ngIf="validationErrors.length">
        <p *ngFor="let error of validationErrors">
          <small class="p-error block">{{ error.message }}</small>
        </p>
      </div> -->
        <div class="col-md-12 col-lg-12 mt-2">
          <p-pickList
            [source]="inactivecolumns"
            [target]="activeColumns"
            sourceHeader="Columnas disponibles"
            targetHeader="Columnas visibles"
            [dragdrop]="true"
            [responsive]="true"
            filterBy="column">
            <ng-template let-item pTemplate="item">
              {{ item.column }}
            </ng-template>
          </p-pickList>
        </div>
    </div>
    <div class="form-footer mt-auto">
      <button pButton (click)="register()" label="Guardar" class="p-button-raised p-button-secondary mr-1"></button>
      <button type="button" pButton (click)="closeDialog()" label="Cancelar" class="p-button-raised p-button-text"></button>
    </div>
  </div>
</div>
