import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowLinkComponentComponent } from './show-link-component/show-link-component.component';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';

@NgModule({
  declarations: [
    ShowLinkComponentComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    ToastModule
  ],
  exports: [
    ShowLinkComponentComponent
  ]
})
export class MioComponentModule { }
