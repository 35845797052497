import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CreateParking } from '@support/models/parking.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParkingService {

  public apiUrl = environment.apiUrl;
  public parkingListBehavior = new BehaviorSubject<any>(false);
  public updateParkingList = this.parkingListBehavior.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number, projectId?:number, stageId?:number): Observable<any> {
    let url = `${this.apiUrl}parkings/list?`;
    if(status) url +=`status_parking_id=${status}&`
    if(projectId) url +=`project_id=${projectId}&`
    if(stageId) url +=`stage_project_id=${stageId}&`
    return this.http.get(url);
  }

  create(body:CreateParking): Observable<any> {
    let url = `${this.apiUrl}parkings/create`;
    return this.http.post(url, body);
  }

  update(body:CreateParking, parkingId:number): Observable<any> {
    let url = `${this.apiUrl}parkings/update/${parkingId}`;
    return this.http.post(url, body);
  }

  status(): Observable<any> {
    let url = `${this.apiUrl}parkings/status/list?`;
    return this.http.get(url);
  }

  detail(parkingId:number): Observable<any> {
    let url = `${this.apiUrl}parkings/${parkingId}?`;
    return this.http.get(url);
  }

  delete(parkingId:number): Observable<any> {
    let url = `${this.apiUrl}parkings/delete/${parkingId}`;
    return this.http.delete(url);
  }

}
