<p-dialog header="Ingrese el código de seguridad" [(visible)]="displayCodeModal" [modal]="true" [style]="{width: '30vw'}"
  [draggable]="false" [resizable]="false" [closable]="false" [closeOnEscape]="false">
    <form *ngIf="form" [formGroup]="form">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 mb-4">
          <span class="p-float-label">
            <input
              id="code"
              type="text"
              pInputText
              class="w-100"
              formControlName="code">
            <label for="code">Código de seguridad</label>
          </span>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-times" (click)="closeModal()" label="Cancelar" styleClass="p-button-text p-button-secondary"></p-button>
      <p-button icon="pi pi-check" (click)="sendCode()" label="Enviar" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
