import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  public contracts:any=[];
  public isLoading = false;
  public displayedColumns: string[] = ['name', 'date', 'ammount', 'status'];
  public dataSource: MatTableDataSource<any>;

  @Input() discountId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit(): void {
    this.getContracts();
  }

  getContracts() {
  this.contracts = [
  { name: 'Reserva 1', date: '2023-09-20', ammount: 1000, status: 'Aprobado' },
  { name: 'Reserva 2', date: '2023-09-21', ammount: 1500, status: 'Pendiente' },
  { name: 'Reserva 3', date: '2023-09-22', ammount: 800, status: 'Rechazado' },
  { name: 'Reserva 4', date: '2023-09-23', ammount: 2000, status: 'Aprobado' },
  { name: 'Reserva 5', date: '2023-09-24', ammount: 1200, status: 'Pendiente' },
  ],
  this.dataSource = new MatTableDataSource(this.contracts);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  }

}
