<app-spinner *ngIf="loading"></app-spinner>
<div class="full-width">
  <div *ngIf="!loading" class="form-wraper">
    <div class="form-container card">
      <div class="form-body">
        <form [formGroup]="form" class="row mx-0 md:mx-5">
          <div class="divider my-5">
            <h4>
              <mat-icon class="mr-1"> manage_search </mat-icon> Nuevo Adicional
            </h4>
            <mat-divider></mat-divider>
          </div>

          <div *ngIf="!projectId">
            <div class="flex flex-column gap-2 pt-3">
              <span class="p-float-label">
                <p-dropdown
                  id="project_id"
                  class="w-full"
                  placeholder="Proyecto"
                  aria-describedby="name-help"
                  formControlName="project_id"
                  [options]="projects"
                  optionLabel="name_project"
                  optionValue="id"
                  [ngClass]="{
                    'ng-invalid ng-dirty': fiedIsInvalid('project_id')
                  }"
                ></p-dropdown>
                <label for="project_id">Proyecto</label>
              </span>
              <small
                *ngIf="fiedIsInvalid('project_id')"
                class="p-error block"
                >{{ getErrorMessage("project_id") }}</small
              >
            </div>
          </div>

          <div class="col-12">
            <div class="flex flex flex-column gap-2 pt-3">
              <span class="p-float-label">
                <input
                  id="name"
                  type="text"
                  pInputText
                  placeholder="Nombre"
                  class="w-100"
                  formControlName="name"
                  [ngClass]="{
                    'ng-invalid ng-dirty': fiedIsInvalid('name')
                  }"
                />
                <label for="name">Nombre</label>
              </span>
              <small *ngIf="fiedIsInvalid('name')" class="p-error block">{{
                getErrorMessage("name")
              }}</small>
            </div>
          </div>

          <div class="col-12">
            <div class="flex flex-column gap-2 pt-3">
              <span class="p-float-label">
                <p-dropdown
                  id="type_additional_id"
                  class="w-full"
                  placeholder="Tipo Adicional"
                  aria-describedby="name-help"
                  formControlName="type_additional_id"
                  [options]="types"
                  optionLabel="name"
                  optionValue="id"
                  [ngClass]="{
                    'ng-invalid ng-dirty': fiedIsInvalid('type_additional_id')
                  }"
                ></p-dropdown>
                <label for="type_additional_id">Tipo Adicional</label>
              </span>
              <small
                *ngIf="fiedIsInvalid('type_additional_id')"
                class="p-error block"
                >{{ getErrorMessage("type_additional_id") }}</small
              >
            </div>
          </div>

          <div class="col-12">
            <div class="flex flex flex-column gap-2 pt-3">
              <span class="p-float-label">
                <p-inputNumber
                  id="price"
                  inputId="price"
                  currency="USD"
                  mode="currency"
                  [minFractionDigits]="0"
                  [maxFractionDigits]="2"
                  class="w-full"
                  placeholder="Precio"
                  aria-describedby="name-help"
                  formControlName="price"
                  [ngClass]="{
                    'ng-invalid ng-dirty': fiedIsInvalid('price')
                  }"
                ></p-inputNumber>
                <label for="price">Precio</label>
              </span>
              <small *ngIf="fiedIsInvalid('price')" class="p-error block">{{
                getErrorMessage("price")
              }}</small>
            </div>
          </div>
        </form>
      </div>

      <div class="form-footer">
        <button
          color="accent"
          mat-raised-button
          type="submit"
          (click)="register()"
          class="btn custom-btn mr-1"
          *ngIf="!data.detail"
        >
          <span *ngIf="!isEditing">Registrar</span>
          <span *ngIf="isEditing">Actualizar</span>
        </button>

        <button
          (click)="close()"
          color="accent"
          mat-raised-button
          class="btn custom-btn-alt"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
