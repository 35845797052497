<p-dialog
  [header]="selectedTask ? 'Editar tarea' : 'Crear tarea'"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
<ng-template pTemplate="content">
    <!-- <p-progressSpinner *ngIf="isLoading" styleClass="custom-spinner" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner> -->
    <app-spinner *ngIf="isLoading"></app-spinner>
    <p-toast [baseZIndex]="20000"></p-toast>
    <form *ngIf="!isLoading" [formGroup]="form" class="my-2 px-2">
      <div class="row mb-3">
        <div class="col-12 col-md-12">
          <span class="p-float-label">
            <input
              type="text"
              pInputText
              placeholder="Asunto"
              class="w-100"
              formControlName="subject"
              id="subject"
              [ngClass]="{
                'ng-invalid ng-dirty': getErrorMessage('subject')
              }"
            />
            <label for="subject">Asunto *</label>
          </span>
          <small
            id="subject-help"
            class="p-error block"
            >{{ getErrorMessage("subject") }}</small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <span class="p-float-label">
            <textarea
              placeholder="Descripción"
              id="description"
              pInputTextarea
              rows="3"
              id="description"
              type="text"
              class="w-100"
              formControlName="description"
              [ngClass]="{
                'ng-invalid ng-dirty': getErrorMessage(
                  'description'
                )
              }"
            ></textarea>
            <label for="textarea">Descripción *</label>
          </span>
          <small id="description-help" class="text-danger small">{{
            getErrorMessage("description")
          }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 mb-3">
          <label for="selectedReferOption" class="fw-bold">Referente a</label>
          <div *ngFor="let option of referOptions" class="field-checkbox">
            <p-radioButton [(ngModel)]="selectedRefer" [value]="option.key" [label]="option.name" (onClick)="selectReferOption($event)" name="selectedReferOption" formControlName="selectedReferOption" [disabled]="moduleId !== null"></p-radioButton>
          </div>
        </div>
        <div *ngIf="selectedRefer === 1" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="client_id"
              class="w-full flex"
              placeholder="Seleccione un cliente"
              aria-describedby="client_id-help"
              formControlName="client_id"
              [options]="clients"
              [disabled]="clients.length === 0 || objectId !== null"
              (onChange)="changeClient($event)"
              optionLabel="fullName"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
            ></p-dropdown>
            <label for="client_id">Seleccione un cliente</label>
          </span>
          <small id="client_id-help" class="text-danger small">{{
            getErrorMessage("client_id")
          }}</small>
        </div>
        <div *ngIf="selectedRefer === 2" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="business_id"
              class="w-full flex"
              placeholder="Seleccione un negocio"
              aria-describedby="business_id-help"
              formControlName="business_id"
              [options]="businesses"
              [disabled]="businesses.length === 0 || objectId !== null"
              (onChange)="changeBusiness($event)"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
            ></p-dropdown>
            <label for="business_id">Seleccione un negocio</label>
          </span>
          <small id="business_id-help" class="text-danger small">{{
            getErrorMessage("business_id")
          }}</small>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-lg-6 col-md-12 mb-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="duration_time"
              class="w-full flex"
              placeholder="Duración"
              aria-describedby="duration_time-help"
              formControlName="duration_time"
              [options]="times"
              (onChange)="changeDuration($event)"
              optionLabel="name"
              optionValue="id"
            ></p-dropdown>
            <label for="duration_time">Duración *</label>
          </span>
          <small id="duration_time-help" class="text-danger small">{{
            getErrorMessage("duration_time")
          }}</small>
        </div>
        <div class="col-lg-6 col-md-12">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="priority"
              class="w-full flex"
              placeholder="Prioridad"
              aria-describedby="priority-help"
              formControlName="priority"
              [options]="priorities"
              (onChange)="changePriority($event)"
              optionLabel="name"
              optionValue="id"
            ></p-dropdown>
            <label for="priority">Prioridad *</label>
          </span>
          <small id="priority-help" class="text-danger small">{{
            getErrorMessage("priority")
          }}</small>
        </div>
      </div>
      <div class="row my-3" *ngIf="currentUser?.user?.isAdminSubs">
        <div class="col-lg-6 col-md-12 mb-3">
          <label for="selectedOwnerOption" class="fw-bold">Propietario</label>
          <div *ngFor="let option of ownerOptions" class="field-checkbox">
            <p-radioButton [(ngModel)]="selectedOwner" [value]="option.key" [label]="option.name" (onClick)="selectOwnerOption($event)" name="selectedOwnerOption" formControlName="selectedOwnerOption"></p-radioButton>
          </div>
        </div>
        <div *ngIf="selectedOwner === 1" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="sales_team_id"
              class="w-full flex"
              placeholder="Seleccione un Equipo"
              aria-describedby="sales_team_id-help"
              formControlName="sales_team_id"
              [options]="salesTeams"
              [disabled]="salesTeams.length === 0"
              (onChange)="changeTeam($event)"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true"
            ></p-dropdown>
            <label for="sales_team_id">Seleccione un Equipo</label>
          </span>
          <small id="sales_team_id-help" class="text-danger small">{{
            getErrorMessage("sales_team_id")
          }}</small>
        </div>
        <div *ngIf="selectedOwner === 2" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="user_id"
              class="w-full flex"
              placeholder="Seleccione un usuario"
              aria-describedby="user_id-help"
              formControlName="user_id"
              [options]="users"
              [disabled]="users.length === 0"
              (onChange)="changeUser($event)"
              optionLabel="names"
              optionValue="id"
              [filter]="true"
              filterBy="names"
              [showClear]="true"
            ></p-dropdown>
            <label for="user_id">Seleccione un usuario</label>
          </span>
          <small id="user_id-help" class="text-danger small">{{
            getErrorMessage("user_id")
          }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <span class="p-float-label">
            <p-calendar
              inputId="expiration_date"
              appendTo="body"
              aria-describedby="expiration_date-help"
              [ngClass]="{'ng-invalid ng-dirty':getErrorMessage('expiration_date')}"
              styleClass="w-100"
              formControlName="expiration_date"
              (onChange)="changeExpirationDate($event)"
              [minDate]="minimumDate"
              [showIcon]="true">
            </p-calendar>
            <label for="expiration_date" [class.active]="paymentDate">Fecha de expiración *</label>
          </span>
          <small id="expiration_date-help" class="p-error block">{{ getErrorMessage('expiration_date') }}</small>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="verifySave()"
      label="Guardar"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
