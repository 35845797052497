<p-table
  #collectionsTable
  styleClass="p-datatable-striped p-datatable-sm"
  [value]="collections"
  [columns]="cols"
  [globalFilterFields]="globalFilterFields"
  [tableStyle]="{'min-width': '50rem'}"
  [paginator]="true"
  [rows]="20"
  scrollHeight="430px"
  [scrollable]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  >
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap align-items-center justify-content-between">
        <!-- <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="p-inputtext-sm" (input)="collectionsTable.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
        </span> -->

        <span class="p-input-icon-right">
          <p-dropdown
            [options]="optionTypes"
            [(ngModel)]="selectedTypeList"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione un tipo"
            style="min-width: 50%; width: 50%; margin-right: 1rem;"
            (onChange)="updateTransfers($event)">
          </p-dropdown>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <!-- <th>Comprobante de pago</th> -->
          <!-- <th style="text-align: center">Acciones</th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td *ngFor="let col of columns">
          {{rowData[col.field]}}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No se encontraron resultados.</td>
      </tr>
    </ng-template>
</p-table>

