<p-confirmPopup></p-confirmPopup>
<app-spinner *ngIf="isLoading"></app-spinner>
<p-table
*ngIf="!isLoading"
  #documentsTable
  styleClass="p-datatable-striped p-datatable-sm"
  [value]="documents"
  [columns]="cols"
  [globalFilterFields]="globalFilterFields"
  [tableStyle]="{ 'min-width': '50rem' }"
  [paginator]="true"
  [rows]="20"
  scrollHeight="430px"
  [scrollable]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
>
  <ng-template pTemplate="caption">
    <p-toolbar styleClass="d-flex justify-content-between flex-wrap max-w-100 gap-2 w-full p-0">

      <div class="p-toolbar-group-left gap-2 max-w-100">
        <span class="p-input-icon-left max-w-100">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="p-inputtext-sm" (input)="documentsTable.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
        </span>
        <button
          pButton
          class="p-button-rounded p-button-text"
          icon="pi pi-filter-slash"
          (click)="clearFilters(documentsTable)"
          pTooltip="Limpiar filtros"
          tooltipPosition="top">
        </button>
      </div>

      <div class="p-toolbar-group-right gap-2 max-w-100">
        <button pButton pRipple icon="pi pi-plus" label="Subir documento" class="p-button-raised p-button-secondary" (click)="showUploadDocument = true"></button>
      </div>

    </p-toolbar>
    </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
        <span *ngIf="col.dataKey">
          <p-sortIcon *ngIf="col.sortable" [field]="col.dataKey"></p-sortIcon>
          <p-columnFilter *ngIf="col.filterable" type="text" [field]="col.dataKey" display="menu" class="ml-auto"></p-columnFilter>
        </span>
      </th>
      <th style="text-align: center">Acciones</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-index="rowIndex"
  >
    <tr [pReorderableRow]="index">
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
      <td style="text-align: center">
        <div
          *ngIf="rowData?.collection_status?.id !== 3"
          class="flex align-items-center justify-content-center gap-2"
        >
          <button
            [disabled]="false"
            pButton
            type="button"
            class="p-button-rounded p-button-text p-button-info"
            icon="pi pi-download"
            pTooltip="Descargar"
            (click)="downloadDocument(rowData?.url)"
          ></button>

          <button
            [disabled]="false"
            pButton
            type="button"
            class="p-button-rounded p-button-text p-button-danger"
            icon="pi pi-trash"
            pTooltip="Eliminar"
            (click)="delete(rowData?.document_business_id)"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No se encontraron resultados.</td>
    </tr>
  </ng-template>
</p-table>

<app-upload-business-document
  *ngIf="business"
  [visible]="showUploadDocument"
  [business]="business"
  (emitVisible)="showUploadDocument = $event"
  (isSuccess)="getBusiness($event)"
  (updateDocuments)="updateDocuments($event)"
  (failed)="showFailedMessage($event)"
></app-upload-business-document>
