import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CreateStage } from '@support/models/stage.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StageService {

  public apiUrl = environment.apiUrl;
  public stageListBehavior = new BehaviorSubject<any>(false);
  public updateStageList = this.stageListBehavior.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number, projectId?:number): Observable<any> {
    let url = `${this.apiUrl}stages/list?`;
    if(status) url +=`status_stage_project_id=${status}&`
    if(projectId) url +=`project_id=${projectId}&`

    return this.http.get(url);
  }

  create(body:CreateStage): Observable<any> {
    let url = `${this.apiUrl}stages/create`;
    return this.http.post(url, body);
  }

  update(body:CreateStage, stageId:number): Observable<any> {
    let url = `${this.apiUrl}stages/update/${stageId}`;
    return this.http.post(url, body);
  }

  detail(stageId:number): Observable<any> {
    let url = `${this.apiUrl}stages/${stageId}?`;
    return this.http.get(url);
  }

  delete(stageId:number): Observable<any> {
    let url = `${this.apiUrl}stages/delete/${stageId}`;
    return this.http.delete(url);
  }

}
