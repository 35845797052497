<p-dialog
  [header]="header"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <ng-template pTemplate="content">
    <form style="" [formGroup]="form" class="team-sales-form">
      <div class="row">
        <div *ngIf="!isEdit" class="col-12 col-sm-6 col-md-6 my-2 md:my-0">
          <div class="flex flex flex-column gap-2">
            <label for="description">Proyecto</label>
            <p-listbox
              [(ngModel)]="project_id"
              formControlName="project_id"
              [options]="projectsOptions"
              optionLabel="name"
              optionValue="id"
              [placeholder]="getOptionName(projectsOptions, project_id)"
              [filterPlaceHolder]="getOptionName(projectsOptions, project_id)"
              (onChange)="changeProject($event)"
              [filter]="true"
              filterBy="name"
              styleClass="h-160"
              [listStyle]="{ 'max-height': '130px' }"
            ></p-listbox>
          </div>
        </div>

        <div *ngIf="!isEdit" class="col-12 col-sm-6 col-md-6 my-2 md:my-0">
          <div class="flex flex flex-column gap-2">
            <label for="description">Unidad</label>
            <p-listbox
              [(ngModel)]="property_id"
              formControlName="property_id"
              [options]="propertiesOptions"
              optionLabel="name"
              optionValue="id"
              [placeholder]="getOptionName(propertiesOptions, property_id)"
              [filterPlaceHolder]="getOptionName(propertiesOptions, property_id)"
              (onChange)="changeUnit($event)"
              [filter]="true"
              filterBy="name"
              styleClass="h-160"
              [listStyle]="{ 'max-height': '130px' }"
            ></p-listbox>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="save()"
      label="Guardar"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
