import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ClientsService } from '@support/services/clients.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { UserData } from '@core/models/user.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';
import { Router } from '@angular/router';
import { CreateClientFromBusinessComponent } from '@support/pages/clients/create-client-from-business/create-client-from-business.component';
import { MatDialog } from '@angular/material/dialog';
import { BusinessService } from '@support/services/business.service';
import { TypeDocuments } from '@support/models/client.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-upload-business-document',
  templateUrl: './upload-business-document.component.html',
  styleUrls: ['./upload-business-document.component.scss'],
})
export class UploadBusinessDocumentComponent implements OnInit, OnChanges {
  public clientsOptions: any[];
  public type_document_id: any;
  public loading: boolean = true;
  private _subcriptions: Record<string, Subscription> = {};
  public typesDocuments: TypeDocuments[] = [];
  public additionalTypesDocuments: TypeDocuments[] = [];

  @Input() name: string;
  @Input() description: string;
  @Input() visible: boolean = true;
  @Input() isEdit: boolean = false;
  @Input() business: any = null;
  @Input() clients: any[] = null;

  @Output() emitVisible = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();
  @Output() failed = new EventEmitter<string>();

  public header: string = 'Cargar Documentos';
  public form: FormGroup;
  public currentUser: UserData;
  public businessId: number;
  public showTypeInput: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _clients: ClientsService,
    private _snackBar: NotificationsService,
    private _auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private _business: BusinessService
  ) {
    this._subcriptions['user'] = this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.setHeader();
    this.getTypesDocs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('business') &&
      changes['business'].currentValue !== null
    ) {
      this.business = changes['business'].currentValue;
      this.businessId = this.business?.id;
    }

    this.setHeader();
    this.createForm();
  }

  getTypesDocs(): void {
    this._business.getTypesDocuments().subscribe(
      (response: any) => {
        if (response && response.data) {
          this.typesDocuments = response.data
          if (this.business?.documents?.length > 0) {
            const hasTypeDocumentId6 = this.business?.documents?.some(doc => doc?.business_type_document?.name === 'Contrato firmado');
            this.typesDocuments = hasTypeDocumentId6
              ? this.typesDocuments.filter(doc => doc?.name !== 'Contrato firmado')
              : this.typesDocuments;
          }
          // this.typesDocuments?.push({id: 0, name: 'Otro'});
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar(
          'Ha ocurrido un error al consultar el listado de tipos de documentos',
          'OK'
        );
      }
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      type_document_id: [null, Validators.required],
      description: [null],
      document: ['', Validators.required],
    });
    this.loading = false;
  }


  onSelectFile(event: any) {
    const file: File = event.currentFiles[0];
    this.form.patchValue({
      document: file,
    });
  }

  onRemoveFile() {
    this.form.patchValue({
      document: '',
    });
  }


  setHeader(): void {
    this.header = 'Cargar Documento';
  }

  hideModal(): void {
    this.emitVisible.emit(false);
  }

  save(): void {

    if (this.form.get('type_document_id').value === 0) {
      this.loading = true;
      const newType = this.form.get('description').value;
      const data = {
        name: newType
      };

      this._business.createTypeDocument(data).subscribe(
        (response: any) => {
          if (response.data) {
            const newId = response.data?.id;
            this.form.get('type_document_id').setValue(newId);
            this.form.get('description').clearValidators();

            // Crear FormData y subir el documento solo después de que se haya creado el tipo de documento
            this.uploadDocument();
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          this.loading = false; // Asegúrate de detener la carga en caso de error
        }
      );
    } else {
      // Validación del formulario (descomentado para uso)
      // if (this.form.invalid) {
      //   this._snackBar.openSnackBar(
      //     'Por favor, complete los datos del formulario.',
      //     'OK'
      //   );
      //   this.form.markAllAsTouched();
      //   return;
      // }
      this.loading = true;
      // Si ya existe type_document_id, simplemente sube el documento
      this.uploadDocument();
    }
  }

  private uploadDocument(): void {
    const formData = new FormData();
    formData.append('type_document_id', this.form.get('type_document_id').value !== 0 ? this.form.get('type_document_id').value : null);
    formData.append('document', this.form.get('document').value);
    formData.append('business_id', this.business?.id);

    const url = this._business.uploadDocument(formData);

    url.subscribe(
      (response) => {
        if (response.id) {
          this.isSuccess.emit(true);
        }
        this.loading = false;
        setTimeout(() => {
          this.hideModal();
        }, 4000);
      },
      (error: HttpErrorResponse) => {
        console.log({error});
        if (error?.error?.message) {
          this.failed.emit(error?.error?.message);
          this.loading = false;
          setTimeout(() => {
            this.hideModal();
          }, 4000);
          return;
        }
        this.isSuccess.emit(false);
        this.loading = false;
        setTimeout(() => {
          this.hideModal();
        }, 4000);
        console.log(error);
      }
    );
  }


  disableForm(): void {}

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
    }
    return '';
  }

  getErrorMessage(controlName: string): string {
    const field = this.form.get(controlName);

    if (!field || !field.invalid) return '';

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido';
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return field && field.invalid && field.touched;
  }

  changeType(e: any): void {
    const value = e?.value;
    if (value !== 0) {
      this.showTypeInput = false;
      this.form.get('description').clearValidators();
      this.form.get('type_document_id').setValidators([Validators.required]);
      return;
    }
    this.form.get('type_document_id').clearValidators();
    this.showTypeInput = true;
    this.form.get('description').setValidators([Validators.required]);
    console.log(value);
  }
}
