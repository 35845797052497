import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionStatusService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

get(status?:number): Observable<any> {
  let url = `${this.apiUrl}collections-status/list?`;
  if(status) url +=`status=${status}`

  return this.http.get(url);
}

status(): Observable<any> {
  let url = `${this.apiUrl}business/status/list?`;
  return this.http.get(url);
}

types(): Observable<any> {
  let url = `${this.apiUrl}property/types/list?`;
  return this.http.get(url);
}

create(body:FormData): Observable<any> {
  let url = `${this.apiUrl}business/create`;
  return this.http.post(url, body);
}

update(body:FormData, businessId:number): Observable<any> {
  let url = `${this.apiUrl}business/update/${businessId}`;
  return this.http.post(url, body);
}

detail(businessId:number): Observable<any>{
  const url = `${this.apiUrl}business/${businessId}`
  return this.http.get(url);
}

delete(propertyId:number): Observable<any> {
  let url = `${this.apiUrl}property/delete/${propertyId}`;
  return this.http.delete(url);
}

}
