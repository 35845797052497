<div class="table-responsive">
  <!-- Nueva tabla -->
  <table mat-table [dataSource]="dataSource" class="table" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
      <td mat-cell *matCellDef="let row"> {{row?.name || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
      <td mat-cell *matCellDef="let row">{{row?.date  || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="ammount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
      <td mat-cell *matCellDef="let row">{{row?.ammount  || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
      <td mat-cell *matCellDef="let row">{{row?.status  || '-'}}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No se encuentra "{{input?.value}}"</td>
    </tr>

  </table>

  <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[25, 50, 75, 100]"
    aria-label="Select page of users" *ngIf="contracts && contracts.length > 25">
  </mat-paginator>
</div>
