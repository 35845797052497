import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core';
import { CollectionService } from '@support/services/collection.service';
// import { ApproveChargesComponent } from '../approve-charges/approve-charges.component';
// import { CancelChargesComponent } from '../cancel-charges/cancel-charges.component';
// import { RejectChargesComponent } from '../reject-charges/reject-charges.component';
// import { Collection } from '@support/models/collection.model';
// import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
// import { HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { BusinessService } from '@support/services/business.service';
// import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-list-by-documents',
  templateUrl: './list-by-documents.component.html',
  styleUrls: ['./list-by-documents.component.scss'],
  providers: [MessageService, DatePipe],
})
export class ListByDocumentsComponent implements OnInit, OnChanges {
  @Input() documents: any;
  @Input() canEdit: any;
  @Input() canCreate: any;
  @Input() user: any;
  @Input() business: any;

  @Output() isSuccess = new EventEmitter<boolean>();
  @Output() failed = new EventEmitter<string>();

  public optionTypes: any[] = [];
  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public isLoading = false;
  public showPaymentForm = false;
  public selectedTypeList = '';

  constructor(
    private _collections: CollectionService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private _business: BusinessService,
    private confirmationService: ConfirmationService,
    @Inject(HttpClient) private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['documents']) {
      this.setColumns();
    }
  }

  getBusiness(event): void {
    this.isSuccess.emit(event);
  }

  setColumns(): void {
    this.documents.forEach((element) => {
      const name = this.getFileNameFromUrl(element?.url);
      element.name = name;
      element.type = element?.business_type_document?.name;
      element.user = element?.business_documents[0]?.upload_user?.name;
      element.date = this.datePipe.transform(
        element?.created_at,
        'dd/MM/yyyy'
      )!;
    });

    this.cols = [
      { field: 'name', header: 'Nombre', dataKey: 'name', filterable: true, sortable: true },
      { field: 'type', header: 'Tipo', dataKey: 'type', filterable: true, sortable: true },
      { field: 'user', header: 'Subido por', dataKey: 'user', filterable: true, sortable: true },
      { field: 'date', header: 'Fecha', dataKey: 'date', filterable: true, sortable: true },
    ];

    this.globalFilterFields = this.cols
      .filter((col) => col.dataKey)
      .map((col) => col.dataKey);
  }

  clearFilters(table: Table) {
    table.clear();
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return decodeURIComponent(fileName);
    }
    return '';
  }

  isMobileDevice(): boolean {
    // Implementa la lógica para detectar si el usuario está accediendo desde un dispositivo móvil
    // Puedes utilizar una biblioteca como 'mobile-detect.js' o implementar tu propia lógica
    // Ejemplo:
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|iphone|ipad|android/.test(userAgent);
  }

  // openDeleteDocumentDialog(documentBusinessId: number) {
  //   this.dialog
  //     .open(ModalConfirmComponent, {
  //       data: {
  //         title: 'Eliminar Documento',
  //         message: '¿Está seguro de que desea eliminar este Documento?',
  //         btnConfirm: true,
  //         btnCancel: true,
  //         btnConfirmText: 'Aceptar',
  //         btnCancelText: 'Cancelar',
  //       },
  //     })
  //     .afterClosed()
  //     .subscribe((confirm: Boolean) => {
  //       if (confirm) {
  //         const url = this._business.deleteDocument(documentBusinessId);

  //         url.subscribe(
  //           (response) => {
  //             if (response.id) {
  //               this.getBusiness(true);
  //             }
  //           },
  //           (error) => {
  //             console.log(error);
  //           }
  //         );
  //       }
  //     });
  // }


  showInfo({ sticky, severity, summary, detail }) {
    this.messageService.add({ sticky, severity, summary, detail });
  }

  clear() {
    this.messageService.clear();
  }

  async downloadDocument(fileUrl: string) {
    let a: any = document.createElement('a');
    a.download = true;
    a.target = '_blank';
    a.href= fileUrl;
    a.click();
  }

  delete(documentBusinessId: number): void {
    console.log(this.isLoading);
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Está seguro de eliminar este documento?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
        const url = this._business.deleteDocument(documentBusinessId);
          url.subscribe(
            (response) => {
              if (response) {
                this.getBusiness(true);
              }
            },
            (error) => {
              console.log(error);
              this.getBusiness(false);
            }
          );
      },
      reject: () => {
          //reject action
          console.log('rejected');
      }

    });
    setTimeout(() => {
      this.isLoading = false;
    }, 4000);
  }

  showFailedMessage(e: any): void {
    this.failed.emit(e);
  }

}
