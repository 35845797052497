import { Currency } from "@support/models/project.model"
import { Ilanguage } from "./language"

// export interface ISubscription {
//   id: number;
//   business_name: string;
//   rnc_number: string;
//   phone: string;
//   direction?: string;
//   bussines_image: string;
//   contact_name: string;
//   contact_last_name: string;
//   contact_email: string;
//   contact_phone: string;
//   contact_position: string;
//   contact_image: string;
//   number_project: number;
//   init_date_project: string;
//   number_user_project: number;
//   billing_info_bussines_name:string;
//   billing_info_rnc_number:string;
//   billing_info_direction?:string;
//   billing_info_send_bill: number;
//   currency:Currency;
//   language:Ilanguage;
//   scope:Scope;
// }

export interface StatusSubscription{
  id:number,
  name:string
}

export interface ISubscription {
  id: number
  business_name: string
  rnc_number: string
  phone: string
  direction: any
  bussines_image: string
  contact_name: string
  contact_last_name: string
  contact_email: string
  contact_phone: string
  contact_position: string
  contact_image: string
  number_project: number
  init_date_project: string
  number_user_project: number
  status: Status
  billing_info_bussines_name: string
  billing_info_rnc_number: string
  billing_info_direction: string
  billing_info_send_bill: number
  duplicate_client_send: number
  currency: Currency
  language: Ilanguage
  scope: Scope
  userAdmin: UserAdmin
  roleAdmin: RoleAdmin
  gateways: any
}

export interface Status {
  id: number
  name: string
}

export interface Language {
  id: number
  name: string
  code: string
}

export interface Scope {
  id: number
  name: string
  is_visible: number
}

export interface UserAdmin {
  id: number
  name: string
  email: string
  email_verified_at: any
  created_at: string
  updated_at: string
  role_id: number
  scope_role_id: any
  status_user_id: any
  subscription_id: number
  is_delete: number
  is_admin_subscription: number
}

export interface RoleAdmin {
  id: number
  name: string
  description: string
  status_role_id: number
  scope_role_id: number
  subscription_id: number
  is_admin_subscription: number
}
