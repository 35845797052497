<p-dialog
  [header]="'Asignar propietario'"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <ng-template pTemplate="content">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <p-toast [baseZIndex]="20000"></p-toast>
    <form *ngIf="!isLoading" [formGroup]="form" class="my-2 px-2">
      <div class="row">
        <div class="col-lg-6 col-md-12 mb-3">
          <label for="selectedOwnerOption" class="fw-bold">Propietario</label>
          <div *ngFor="let option of ownerOptions" class="field-checkbox">
            <p-radioButton 
              [(ngModel)]="selectedOwner" 
              [value]="option.key" 
              [label]="option.name" 
              (onClick)="selectOwnerOption($event)" 
              name="selectedOwnerOption" 
              formControlName="selectedOwnerOption">
            </p-radioButton>
          </div>
        </div>

        <div *ngIf="selectedOwner === 1" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="sales_team_id"
              class="w-full flex"
              placeholder="Seleccione un Equipo"
              aria-describedby="sales_team_id-help"
              formControlName="sales_team_id"
              [options]="salesTeams"
              [disabled]="salesTeams.length === 0"
              (onChange)="changeTeam($event)"
              optionLabel="name"
              optionValue="id"
              [filter]="true"
              filterBy="name"
              [showClear]="true">
            </p-dropdown>
            <label for="sales_team_id">Seleccione un Equipo</label>
          </span>
          <small id="sales_team_id-help" class="text-danger small">{{ getErrorMessage("sales_team_id") }}</small>
        </div>

        <div *ngIf="selectedOwner === 2" class="col-lg-6 col-md-12 lg:mt-3">
          <span class="p-float-label">
            <p-dropdown
              appendTo="body"
              id="user_id"
              class="w-full flex"
              placeholder="Seleccione un usuario"
              aria-describedby="user_id-help"
              formControlName="user_id"
              [options]="users"
              [disabled]="users.length === 0"
              (onChange)="changeUser($event)"
              optionLabel="names"
              optionValue="id"
              [filter]="true"
              filterBy="names"
              [showClear]="true">
            </p-dropdown>
            <label for="user_id">Seleccione un usuario</label>
          </span>
          <small id="user_id-help" class="text-danger small">{{ getErrorMessage("user_id") }}</small>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="assignmentSave()"
      label="Guardar"
      styleClass="p-button-text">
    </p-button>
  </ng-template>
</p-dialog>

