import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BankModel } from '@admin/models/IBank';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BanksService {


  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number): Observable<any> {
    let url = `${this.apiUrl}banks/list?`;

    if(status) url +=`status=${status}`

    return this.http.get(url);
  }

  getStatus(): Observable<any> {
    let url = `${this.apiUrl}banks/status/list`;
    return this.http.get(url);
  }

  create(body:BankModel): Observable<any> {
    let url = `${this.apiUrl}bank/create`;
    return this.http.post(url, body);
  }

  update(body:BankModel, id:number): Observable<any> {
    let url = `${this.apiUrl}bank/update/${id}`;
    return this.http.post(url, body);
  }

  detail(id:number): Observable<any>{
    const url = `${this.apiUrl}bank/${id}`
    return this.http.get(url);
  }

  delete(id:number): Observable<any> {
    let url = `${this.apiUrl}bank/delete/${id}`;
    return this.http.delete(url);
  }

  listTypesBanks(): Observable<any>{
    const url = `${this.apiUrl}types/accounts/list`
    return this.http.get(url);
  }

}
