import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { PaymentPlanComponent } from './payment-plan.component';
import { ManualQuotesListComponent } from './create-plan/manual-quotes-list/manual-quotes-list.component';
import { ManualModalComponent } from './create-plan/manual-modal/manual-modal.component';
import { PercentagePlanComponent } from './create-plan/percentage-plan/percentage-plan.component';
import { AmountsPlanComponent } from './create-plan/amounts-plan/amounts-plan.component';
import { PlanListComponent } from './plan-list/plan-list.component';
import { ButtonModule } from 'primeng/button';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';
import {InputNumberModule} from 'primeng/inputnumber';
import { CurrencyMaskModule } from "ng2-currency-mask";
import {DropdownModule} from 'primeng/dropdown';
import {SplitButtonModule} from 'primeng/splitbutton';
import {DividerModule} from 'primeng/divider';
import {SidebarModule} from 'primeng/sidebar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ProgressSpinnerModule} from 'primeng/progressspinner';


@NgModule({
  declarations: [
    CreatePlanComponent,
    PaymentPlanComponent,
    PlanListComponent,
    ManualQuotesListComponent,
    ManualModalComponent,
    PercentagePlanComponent,
    AmountsPlanComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    InputNumberModule,
    CurrencyMaskModule,
    DropdownModule,
    SplitButtonModule,
    DividerModule,
    SidebarModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    FormsModule,
    ProgressSpinnerModule
  ],
  exports: [
    CreatePlanComponent,
    PaymentPlanComponent,
    PlanListComponent,
    ManualQuotesListComponent,
    ManualModalComponent,
    PercentagePlanComponent,
    AmountsPlanComponent
  ]
})
export class PaymentPlanModule { }
