import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ModulePermission, Permission, Role, UserData } from '@core/models/user.model';
import { AuthService } from '@core/auth/services/auth.service';
import { StorageService } from '@tools/services/storage.service';
import { filter, map } from 'rxjs';

const MODULE_CONFIG_ID = 5

@Injectable({
  providedIn: 'root',
})
export class PermissionUserService {
  public permissions: ModulePermission[] = [];
  public configPermissions: Permission[] = [];
  public module: string;
  public role: Role;

  constructor(
    private router: Router,
    private _auth: AuthService,
    private _storage: StorageService
  ) {
      this.getCurrentModule();
      this.getUser();
  }

  getCurrentModule():void{
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null),
        map((event: ActivationEnd) => event.snapshot.data)
      )
      .subscribe((event) => {
        const { module } = event;
        this.module = module?.title;
        this.permissions = this._storage.getPermission();
        this.getUser();
      });
  }

  setPermission(): string[] {
    let permissions: string[] = [];
    const modules: ModulePermission = this.permissions.filter((p) =>
      p.names.includes(this.module)
    )[0];

    if (!modules) {
      return permissions;
    }
    permissions = modules.permissions.map((p) => p.name);
    return permissions;
  }

  getUser():void{
    this._auth.user$.subscribe(
      (user: UserData) => {
        if(user) {
          this.permissions = user.permissions;
          const config = this.permissions.find(item => item.id === MODULE_CONFIG_ID);
          if (config && config?.permissions.length) {
            this.configPermissions = config?.permissions
          }

          this.role = user.user?.role
        }
      }
    );
  }

  setConfigPermission(): string[] {
    let permissions: string[] = [];
    permissions = this.configPermissions.map((p) => p.name);
    return permissions;
  }

}
