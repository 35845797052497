import { Component,EventEmitter,Input,OnChanges,OnInit,Output, SimpleChanges } from '@angular/core';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import { NoteService } from '@tools/services/note.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
  providers: [MessageService]
})
export class CreateNoteComponent implements OnInit, OnChanges {
  public form: FormGroup;
  note = {
    title: '',
    description: '',
    subscription_id: '',
    business_id: '',
    client_id: '',
    property_id: '',
    document: null
  };
  public uploadedFiles: any[] = [];
  @Input() visible: boolean = true;
  @Input() moduleId: number;
  @Input() objectId: number;
  @Input() noteId: number;
  @Input() noteData: any;
  @Output() emitVisible = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();
  public loading: boolean = false;

  constructor(
    private noteService: NoteService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['moduleId']) {
      console.log(changes['moduleId']);
      this.createForm();
    }
  }

  createForm(): void {
    console.log(this.noteId,'motas');
    this.form = this.fb.group({
      title: [null, Validators.required],
      description: [null, Validators.required],
      document: [null],
    });
    if (this.noteId) this.completeForm();
  }

  completeForm(): void {
    console.log(this.noteData);
    this.form.patchValue({
      title: this.noteData?.title,
      description: this.noteData?.description,
      document: this.noteData?.document ? this.noteData.document.url : null,
    });

    if (this.noteData?.document) {
      this.uploadedFiles.push({
        name: this.getFileNameFromUrl(this.noteData.document.url),
        url: this.noteData.document.url,
      });
    }
  }

  onSubmit() {
    console.log({...this.form.getRawValue()}, this.form.valid);
    if (this.form.valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('title', this.form.get('title').value);
      formData.append('description', this.form.get('description').value);
      formData.append('module_id', this.moduleId.toString());
      formData.append('object_id', this.objectId ? this.objectId.toString() : '');

      const document = this.form.get('document').value;
      if (document) {
        formData.append('document', document);
      }

      if (this.noteId) {
        console.log(this.noteId,'nos');
        this.noteService.update( formData, this.noteId ).subscribe(
          response => {
            this.loading = false;
            this.isSuccess.emit(true);
            this.hideModal();
          },
          error => {
            this.loading = false;
            this.isSuccess.emit(false);
          }
        );
      } else {
        console.log('entro aqui');

      this.noteService.create(formData).subscribe(
        (response) => {
          this.loading = false;
          // this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Nota creada con éxito' });
          this.isSuccess.emit(true);
          this.hideModal();
        },
        (error) => {
          this.loading = false;
          this.isSuccess.emit(false);
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear la nota' });
        }
      );
    }
  }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    this.note.document = file;
  }

  create() {
    // Aquí puedes manejar la lógica para crear la nota
    console.log('Nota creada:', this.note);
    // Puedes agregar lógica para enviar la nota a un servicio
  }

  hideModal(): void {
    this.emitVisible.emit(false);
  }

  onSelectFile(event: any) {
    for (let file of event.currentFiles) {
      this.uploadedFiles.push(file);
      this.form.get('document').setValue(file);
    }
  }

  onRemoveFile (event: any) {
    const fileIndex = this.uploadedFiles.findIndex(f => f.name === event.file.name);
    if (fileIndex !== -1) {
      this.uploadedFiles.splice(fileIndex, 1);
    }
    this.form.get('document').setValue(null);
  }

  getFileNameFromUrl(url: string): string {
    if (url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return decodeURIComponent(fileName);
    }
    return '';
  }

}
