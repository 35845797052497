import { Injectable } from '@angular/core';
import { SecureStoreService } from './secure-store.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor(private secureStorageSrv: SecureStoreService) { }

  setToken(token:string):void{
    this.secureStorageSrv.secureStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem('token', JSON.stringify(token));
  }

  getToken():string{
    return JSON.parse(this.secureStorageSrv.secureStorage.getItem('token'));
    // return JSON.parse(localStorage.getItem('token'));

  }

  setUser(user:any): void{
    this.secureStorageSrv.secureStorage.setItem('user', JSON.stringify(user));
    // localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): any{
    return JSON.parse(this.secureStorageSrv.secureStorage.getItem('user'));
    // return JSON.parse(localStorage.getItem('user'));
  }

  setRole(role:string):void{
    this.secureStorageSrv.secureStorage.setItem('role', JSON.stringify(role));
    // localStorage.setItem('role', JSON.stringify(role));
  }

  getRole():string{
    return  JSON.parse(this.secureStorageSrv.secureStorage.getItem('role'));
    // return JSON.parse(localStorage.getItem('role'));
  }

  setPermission(permissions:any):void{
    this.secureStorageSrv.secureStorage.setItem('permissions', JSON.stringify(permissions));
    // localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  getPermission():any{
    return  JSON.parse(this.secureStorageSrv.secureStorage.getItem('permissions'));
    // return  JSON.parse(localStorage.getItem('permissions'))
  }

  setMenu(menu:any):void{
    this.secureStorageSrv.secureStorage.setItem('menu', JSON.stringify(menu));
    // localStorage.setItem('menu', JSON.stringify(menu));
  }

  getMenu():any{
    return  JSON.parse(this.secureStorageSrv.secureStorage.getItem('menu'));
    // return JSON.parse(localStorage.getItem('menu'));
  }

  setItem(itemName:string, value:any):void{
    this.secureStorageSrv.secureStorage.setItem(itemName, value);
    // localStorage.setItem(itemName, value);
  }

  getItem(itemName:string):string{
    return this.secureStorageSrv.secureStorage.getItem(itemName);
    // return localStorage.getItem(itemName);
  }




}
