import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { BreadcrumbsService } from '@tools/services/breadcrumbs.service';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {

  public module: string;
  public routeModule: string;
  public page: string;
  public routePage: string;
  public subPage: string;

  constructor(
    private _breadCrumb: BreadcrumbsService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event): event is ActivationEnd => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null),
        map((event: ActivationEnd) => event.snapshot.data)
      )
      .subscribe((event) => {
        const { module, page, subPage } = event;
        this.module = module?.title;
        this.routeModule = module?.route;
        this.page = page?.title;
        this.routePage = page?.route;
        this.subPage = subPage?.title;

        localStorage.setItem('module', JSON.stringify(module))
        localStorage.setItem('page', JSON.stringify(page))
        localStorage.setItem('subPage', JSON.stringify(subPage))
      })
  }

  ngOnInit(): void {
    let module = null;
    let subPage = null
    let page = null;

    try {
      module = (localStorage.getItem('module') && localStorage.getItem('module') !== '') ? JSON.parse(localStorage.getItem('module')) : null
    } catch (error) {
      module = null;
    }

    try {
      page = (localStorage.getItem('page') && localStorage.getItem('page') !== '') ? JSON.parse(localStorage.getItem('page')) : null
    } catch (error) {
      page = null;
    }

    try {
      subPage = (localStorage.getItem('subPage') && localStorage.getItem('subPage') !== '') ? JSON.parse(localStorage.getItem('subPage')) : null
    } catch (error) {
      subPage = null;
    }

    this.module = module?.title;
    this.routeModule = module?.route;
    this.page = page?.title;
    this.routePage = page?.route;
    this.subPage = subPage?.title;
  }

  // ngOnInit(): void {
  //   this.module = this._breadCrumb.module;
  //   this.routeModule = this._breadCrumb.routeModule;
  //   this.page = this._breadCrumb.page;
  //   this.routePage = this._breadCrumb.routePage;
  //   this.subPage = this._breadCrumb.subPage;
  //   console.log(this.module);
  //   console.log(this.routeModule);
  //   console.log(this.page);
  //   console.log(this.routePage);
  //   console.log(this.subPage);
  // }

}
