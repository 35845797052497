<div class="center flex row y-items-center" [ngClass]="{ 'wrapper': complete, 'auto':!complete, 'spinner-overlay': overlay }">
    <div>
        <mat-spinner diameter="50" class="custom-spinner"></mat-spinner>
      <!-- <mat-progress-spinner
        class="example-margin"
        [color]="color"
        [mode]="mode"
        [value]="value">
    </mat-progress-spinner> -->
    </div>
</div>
