<div class="container" *ngIf="business">
  <p-card styleClass="business-detail">
    <ng-template pTemplate="header">
      <div class="w-100 d-flex align-items-center justify-content-center">
        <p-avatar icon="pi pi-building" styleClass="mr-2" size="xlarge" [style]="{'background-color':'#FFFFFF', 'color': '#FC5A34'}" shape="circle"></p-avatar>
        <p-divider align="left" styleClass="business-detail_header">
          <b class="lead"><strong>Proyecto: {{ business?.project?.name_project }}</strong></b>
        </p-divider>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <p-scrollPanel [style]="{height: '170px'}">
        <div class="d-flex flex-column business-info">
            <span class="lead"><strong>Inmueble: </strong>{{business?.property?.name_property}}</span>
            <span class="lead"><strong>Precio base: </strong>{{ business?.property?.price_base }}</span>
            <span class="lead"><strong>Habitaciones: </strong>{{ business?.property?.bedrooms  }}</span>
            <span class="lead"><strong>Baños: </strong>{{ business?.property?.bathrooms }}</span>
            <span class="lead"><strong>M2: </strong>{{ business?.property?.solar_square_meters}}</span>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <p-divider align="right" styleClass="business-detail_subheader">
            <b class="lead"><strong>Cliente: {{ business?.client?.name }} {{ business?.client?.last_name }}</strong></b>
          </p-divider>
          <p-avatar icon="pi pi-user" styleClass="ml-2" size="large" [style]="{'background-color':'#FFFFFF', 'color': '#3D3E50'}" shape="circle"></p-avatar>
        </div>
      </p-scrollPanel>
    </ng-template>
  </p-card>
</div>
