import { Component, Input, OnInit } from '@angular/core';
import { ClientsService } from '@support/services/clients.service';
import { ProjectService } from '@support/services/project.service';
import {PrimeIcons} from 'primeng/api';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  providers: [PrimeIcons]
})
export class GeneralInfoComponent implements OnInit {

  @Input() business: any;
  events1: any[];

  constructor() { }

  ngOnInit(): void {
    if (this.business) {
      this.events1 = [
        {status: 'Ordered', date: '15/10/2020 10:30', icon: PrimeIcons.SHOPPING_CART, color: '#9C27B0', image: 'game-controller.jpg'},
        {status: 'Processing', date: '15/10/2020 14:00', icon: PrimeIcons.COG, color: '#673AB7'},
        {status: 'Shipped', date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
        {status: 'Delivered', date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, color: '#607D8B'}
    ];
    }
  }
}
