<div class="container">
  <form [formGroup]="form">
    <div *ngIf="!valid" class="row mt-3">
      <div class="col-12">
        <p-message styleClass="w-100" severity="error" text="Por favor complete el formulario"></p-message>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <input
            currencyMask
            formControlName="reserve_amount"
            class="w-100"
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            id="reserve_amount"
            type="text"
            pInputText
            (keypress)="sendData($event)"
            (keyup)="sendData($event)"
            (keydown)="sendData($event)"
            (input)="sendData($event)"
            (change)="sendData($event)"
            (focus)="sendData($event)"
          >
          <label for="reserve_amount">Monto de reserva *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-calendar [minDate]="minDate" appendTo="body" formControlName="reserve_date" styleClass="w-100" placeholder="Fecha de reserva" id="reserve_date"  [showIcon]="true" inputId="reserve_date" (onSelect)="onDateChange($event, 'reserve')"></p-calendar>
          <label for="reserve_date">Fecha de reserva *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <input
            currencyMask
            formControlName="separation_amount"
            class="w-100"
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            id="separation_amount"
            type="text"
            pInputText
            (keypress)="sendData($event)"
            (keyup)="sendData($event)"
            (keydown)="sendData($event)"
            (input)="sendData($event)"
            (change)="sendData($event)"
            (focus)="sendData($event)"
          >
          <label for="separation_amount">Monto de separación *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-calendar [minDate]="minSeparationDate" appendTo="body" formControlName="separation_date" styleClass="w-100" placeholder="Fecha de separación" id="separation_date"  [showIcon]="true" inputId="separation_date" (onSelect)="onDateChange($event, 'separation')"></p-calendar>
          <label for="separation_date">Fecha de separación *</label>
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-inputNumber
            class="w-100"
            inputId="number"
            formControlName="quote_count"
            (keypress)="setQuantities($event)"
            (keyup)="setQuantities($event)"
            (keydown)="setQuantities($event)"
            (input)="setQuantities($event)"
            (change)="setQuantities($event)"
            (focus)="setQuantities($event)"
          ></p-inputNumber>
          <label for="quote_count">Cantidad de cuotas *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <input
            currencyMask
            formControlName="quote_amount"
            class="w-100"
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            id="quote_amount"
            type="text"
            pInputText
            (keypress)="setAmount($event)"
            (keyup)="setAmount($event)"
            (keydown)="setAmount($event)"
            (input)="setAmount($event)"
            (change)="setAmount($event)"
            (focus)="setAmount($event)"
          >
          <label for="quote_amount">Monto de cuotas *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-dropdown id="type" formControlName="frequency_id" styleClass="w-100" appendTo="body" [options]="frencuencies" placeholder="Seleccione una opcion" optionLabel="label" optionValue="id" [showClear]="false" (onChange)="setFrecuency($event)"></p-dropdown>
          <label for="frequency_id">Frecuencia *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-calendar [minDate]="minQuotesDate" appendTo="body" formControlName="start_date" styleClass="w-100" placeholder="Fecha de inicio" id="start_date"  [showIcon]="true" inputId="start_date" (onSelect)="onDateChange($event, 'quotes')"></p-calendar>
          <label for="start_date">Fecha de inicio *</label>
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <input
            currencyMask
            formControlName="delivery_amount"
            class="w-100"
            [options]="{ prefix: '', thousands: ',', decimal: '.' }"
            id="delivery_amount"
            type="text"
            pInputText
            (keypress)="sendData($event)"
            (keyup)="sendData($event)"
            (keydown)="sendData($event)"
            (input)="sendData($event)"
            (change)="sendData($event)"
            (focus)="sendData($event)"
          >
          <label for="delivery_amount">Monto de entrega *</label>
        </span>
      </div>
      <div class="col-lg-3 col-md-12">
        <span class="p-float-label">
          <p-calendar [minDate]="minDeliveryDate" appendTo="body" formControlName="delivery_date" styleClass="w-100" placeholder="Fecha de entrega" id="delivery_date"  [showIcon]="true" inputId="delivery_date" (onSelect)="onDateChange($event, 'delivery')"></p-calendar>
          <label for="delivery_date">Fecha de entrega *</label>
        </span>
      </div>
    </div>
  </form>
</div>

