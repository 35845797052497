<p-dialog
[header]="'Crear nota'"
[(visible)]="visible"
[modal]="true"
[style]="{ width: '50vw' }"
[draggable]="false"
[resizable]="false"
(onHide)="hideModal()"
>
<ng-template pTemplate="content">
    <app-spinner *ngIf="loading"></app-spinner>
    <form *ngIf="!loading" [formGroup]="form">
      <div class="row">
        <div class="col-md-12 my-2">
            <span class="p-float-label">
              <input
                placeholder="Título"
                id="title"
                pInputText
                rows="3"
                id="title"
                type="text"
                class="w-100"
                formControlName="title"
              >
              <label for="textarea">Título *</label>
            </span>
          </div>
        <div class="col-md-12 my-2">
          <span class="p-float-label">
            <textarea
              placeholder="Descripción"
              id="description"
              pInputTextarea
              rows="3"
              id="description"
              type="text"
              class="w-100"
              formControlName="description"
            ></textarea>
            <label for="textarea">Descripción *</label>
          </span>
        </div>
        <div class="col-md-12 my-2">
          <p-fileUpload
            id="document"
            [files]="uploadedFiles"
            [showUploadButton]="false"
            chooseLabel="Subir documento"
            accept="image/*,application/pdf"
            (onSelect)="onSelectFile($event)"
            (onClear)="onRemoveFile()"
            (onRemove)="onRemoveFile()">
            <ng-template pTemplate="content">
              <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">
                  <div>
                    <img *ngIf="file.url.endsWith('.jpg') || file.url.endsWith('.png')" [src]="file.url" alt="{{ file.name }}" style="width: 100px; height: auto;" />
                    {{ file.name }}
                    <a [href]="file.url" target="_blank">Ver</a>
                  </div>
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Subir"  (click)="onSubmit()" styleClass="p-button-sm p-button-secondary mr-2"></p-button>
    <p-button label="Cancelar" styleClass="p-button-sm p-button-text" (click)="hideModal()"></p-button>
  </ng-template>
</p-dialog>
