import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from '@tools/services/notifications.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {

  public parameters: any = [];
  public isLoading = false;
  public displayedColumns: string[] = ['share', 'date', 'ammount', 'applied_ammount', 'balance', 'status'];
  public dataSource: MatTableDataSource<any>;

  @Input() parametersId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _snackBar: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.getParameters()
  }

  getParameters(): void {
    this.parameters = [
      { share: '1 Reserva', date: '2023-09-20', ammount: 1000, applied_ammount: 500, balance: 500, status: 'Aprobado' },
      { share: '2 Reserva', date: '2023-09-21', ammount: 1500, applied_ammount: 800, balance: 700, status: 'Pendiente' },
      { share: '3 Reserva', date: '2023-09-22', ammount: 800, applied_ammount: 300, balance: 500, status: 'Rechazado' },
      { share: '4 Reserva', date: '2023-09-23', ammount: 2000, applied_ammount: 1000, balance: 1000, status: 'Aprobado' },
      { share: '5 Reserva', date: '2023-09-24', ammount: 1200, applied_ammount: 600, balance: 600, status: 'Pendiente' },
    ];
    this.dataSource = new MatTableDataSource(this.parameters);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
