import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
  public discounts:any=[];
  public isLoading = false;
  public displayedColumns: string[] = ['description', 'ammount'];
  public dataSource: MatTableDataSource<any>;

  @Input() discountId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit(): void {
    this.getDiscounts();
  }

  getDiscounts() {
  this.discounts = [
    { description: 'Aliados Comerciales 1', ammount: 1000 },
    { description: 'Aliados Comerciales 2', ammount: 1500 },
    { description: 'Aliados Comerciales 3', ammount: 800 },
    { description: 'Aliados Comerciales 4', ammount: 2000 },
    { description: 'Aliados Comerciales 5', ammount: 1200 },
  ],
  this.dataSource = new MatTableDataSource(this.discounts);
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
  }

}
