<p-toast [baseZIndex]="20000"></p-toast>
<p-dialog header="Link de pago" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
[draggable]="false" [resizable]="false" [closable]="false" [closeOnEscape]="false">
  <h5 style="color: cornflowerblue;">* Por favor copie el link mostrado a continuación para realizar el pago con tarjeta, el mismo tiene una <strong>duración de 10 minutos.</strong>
    Luego de que el link expire debe crear una nueva orden de pago
  </h5>
    <form *ngIf="form" [formGroup]="form">
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-4 mt-2">
          <div class="p-inputgroup">
            <span class="p-float-label">
              <input
                id="payment_link"
                type="text"
                pInputText
                class="w-100"
                formControlName="payment_link">
              <label for="payment_link">Copy link</label>
            </span>
            <button type="button" pButton pRipple icon="pi pi-copy" (click)="copiarAlPortapapeles()"></button>
          </div>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-send" label="Enviar por Correo" (click)="sendByMail()" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" label="Cerrar" (click)="close()" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

