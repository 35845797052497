<div class="full-width">
    <div class="form-wraper">
        <div class="form-container card">
            <div class="form-header">
                <h4 class="form-title">{{data?.title}}</h4>
            </div>

            <div class="form-body">
                <div mat-dialog-content>
                    <p>{{data?.message}}</p>
                </div>
            </div>

            <div class="form-footer">
                <button *ngIf="data?.btnConfirm" color="accent" mat-raised-button mat-dialog-close (click)="confirm()" class="btn custom-btn">
                    {{data?.btnConfirmText}}
                </button>

                <button *ngIf="data?.btnCancel" (click)="closeDialog()" color="accent" mat-raised-button class="btn custom-btn-alt">
                    {{data?.btnCancelText}}
                </button>
            </div>
        </div>
    </div>
</div>
