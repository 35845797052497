import { filter } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BusinessService } from '@support/services/business.service';
import { ClientsService } from '@support/services/clients.service';
import { SalesTeamsService } from "@support/services/salesTeams.service";
import { UsersService } from '@admin/services/users.service';
import { TasksService } from '@support/services/tasks.service';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';
import { UserData } from '@core/models/user.model';
import { AuthService } from '@core/auth/services/auth.service';
import { MessageService } from 'primeng/api'
import {ConfirmationService, ConfirmEventType} from 'primeng/api';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
  providers: [MessageService]
})
export class CreateTaskComponent implements OnInit, OnChanges {

  @Input() visible: boolean = false;
  @Input() selectedTask: any = null;
  @Input() objectId: any = null;
  @Input() moduleId: any = null;
  @Input() messageService: MessageService = null;

  @Output() isShowing = new EventEmitter<boolean>();
  @Output() complete = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();

  public minimumDate: Date = new Date();
  public form: FormGroup;
  public isLoading = true;
  public currentUser: UserData;

  public referOptions: any[] = [
    {name:'Cliente', key: 1},
    {name:'Negocio', key: 2}
  ]

  public ownerOptions: any[] = [
    {name:'Equipo de ventas', key: 1},
    {name:'Usuario', key: 2}
  ]

  public selectedRefer: any = null;
  public selectedOwner: any = null;

  public businesses: any[] = [];
  public clients: any[] = [];
  public salesTeams: any[] = [];
  public users: any[] = [];
  public formCreated: boolean = false;

  public times: any[] = [
    {id: '00:01', name: '1 minuto'},
    {id: '00:15', name: '15 minutos'},
    {id: '00:30', name: '30 minutos'},
    {id: '00:45', name: '45 minutos'},
    {id: '01:00', name: '1 hora'},
    {id: '01:30', name: '1.5 horas'},
    {id: '02:00', name: '2 horas'},
    {id: '02:30', name: '2.5 horas'},
    {id: '03:00', name: '3 horas'},
    {id: '03:30', name: '3.5 horas'},
    {id: '04:00', name: '4 horas'},
    {id: '04:30', name: '4.5 horas'},
    {id: '05:00', name: '5 horas'},
    {id: '05:30', name: '5.5 horas'},
    {id: '06:00', name: '6 horas'},
    {id: '06:30', name: '6.5 horas'},
    {id: '07:00', name: '7 horas'},
    {id: '07:30', name: '7.5 horas'},
    {id: '08:00', name: '8 horas'},
    {id: '24:00', name: '1 dia'},
    {id: '48:00', name: '2 dias'},
    {id: '72:00', name: '3 dias'},
  ];

  public priorities: any[] = [
    {id: 'Alta', name: 'Alta'},
    {id: 'Media', name: 'Media'},
    {id: 'Baja', name: 'Baja'},
  ]

  constructor(
    private fb: FormBuilder,
    private _business: BusinessService,
    private _clients:ClientsService,
    private _salesTeams: SalesTeamsService,
    private _users:UsersService,
    private _tasks:TasksService,
    private datePipe: DatePipe,
    private _auth: AuthService,
    private confirmationService: ConfirmationService,
    // private messageService: MessageService,
  ) {
    this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.formCreated = this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('visible') && changes['visible'].currentValue !== null && changes['visible'].currentValue) {
      if (this.selectedTask) {
        this.completeForm();
        this.setMessageAlert()
      } else {
        this.formCreated = this.createForm()
      }
      if ((this.moduleId && this.objectId && this.formCreated) || (this.moduleId && this.objectId && this.selectedTask)) {
        const value = this.moduleId === 7 ? 1 : 2;
        this.selectReferOption({ value });
      }
    }
  }

  createForm(): boolean {
    this.form = this.fb.group({
      subject: [null, Validators.required],
      description: [null, Validators.required],
      duration_time: [null, Validators.required],
      expiration_date: [null, Validators.required],
      client_id: [null],
      business_id: [null],
      user_id: [null],
      sales_team_id: [null],
      priority: [null, Validators.required],
      status: ['Sin iniciar', Validators.required],
      selectedReferOption: [null],
      selectedOwnerOption: [null],
    });
    this.isLoading = false;
    return true;
  }

  setMessageAlert(): void {
    // let resp = this.getColorByDate(this.selectedTask?.expiration_date)

    if (this.selectedTask?.expiration_message.indexOf("Su tarea expiró") >= 0) {
      this.messageService.add({ sticky: true, severity: 'error', summary: 'Tarea vencida', detail: this.selectedTask?.expiration_message });
    } else if (this.selectedTask?.expiration_message.indexOf("Su tarea vencerá") >= 0) {
      this.messageService.add({ sticky: true, severity: 'warn', summary: 'Tarea por vencer', detail: this.selectedTask?.expiration_message });
    }
  }

  clearForm(): void {

  }

  completeForm(): void {
    if (this.form && this.selectedTask) {
      if (this.selectedTask?.client?.id) {
        this.selectReferOption({ value: 1 })
        this.selectedRefer = 1;
      } else if (this.selectedTask?.business?.id) {
        this.selectReferOption({ value: 2 })
        this.selectedRefer = 2;
      }

      if (this.selectedTask?.sales_team?.id) {
        this.selectOwnerOption({ value: 1 })
        this.selectedOwner = 1;
      } else if (this.selectedTask?.user?.id) {
        this.selectOwnerOption({ value: 2 })
        this.selectedOwner = 2;
      }

      this.form.patchValue({
        subject: this.selectedTask?.subject || null,
        description: this.selectedTask?.description || null,
        duration_time: this.selectedTask?.duration_time.slice(0, 5) || null,
        expiration_date: this.selectedTask?.expiration_date ? moment(this.selectedTask?.expiration_date).tz(moment.tz.guess()).toDate() : null,
        priority: this.selectedTask?.priority || '',
        status: this.selectedTask?.status || '',
        selectedReferOption: this.selectedTask?.selectedReferOption || null,
        selectedOwnerOption: this.selectedTask?.selectedOwnerOption || null,

        client_id: this.selectedTask?.client?.id || null,
        business_id: this.selectedTask?.business?.id || null,
        user_id: this.selectedTask?.user?.id || null,
        sales_team_id: this.selectedTask?.sales_team?.id || null,
      });

    }
  }

  getErrorMessage (controlName: string): string {

    const field = this.form.get(controlName);

    if(!field || !field.invalid) return ''

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido'
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return (
      field &&
      field.invalid &&
      (field.touched)
    );
  }

  hideModal(): void {
    this.visible = false;
    this.isShowing.emit(this.visible);
  }

  async selectReferOption(e: any): Promise<void> {
    this.isLoading = true;
    this.selectedRefer = e.value;
    const isActivity = this.objectId !== null;
    switch (this.selectedRefer) {
      case 1:
        this.form.get('client_id').setValidators([Validators.required]);
        this.form.get('business_id').clearValidators();
        this.form.get('business_id').setValue(null);
        await this.getClients(isActivity);
        this.businesses = [];
        this.isLoading = false;
        break;
      case 2:
        this.form.get('business_id').setValidators([Validators.required]);
        this.form.get('client_id').clearValidators() ;
        this.form.get('client_id').setValue(null);
        await this.getBusiness(isActivity);
        this.clients = [];
        this.isLoading = false;
        break;
      default:
        this.isLoading = false;
        break;
    }
  }

  async selectOwnerOption(e: any): Promise<void> {
    this.isLoading = true;
    this.selectedOwner = e.value;
    switch (this.selectedOwner) {
      case 1:
        this.form.get('sales_team_id').setValidators([Validators.required]);
        this.form.get('user_id').clearValidators();
        this.form.get('user_id').setValue(null);
        await this.getTeams();
        this.users = [];
        this.isLoading = false;
        break;
      case 2:
        this.form.get('user_id').setValidators([Validators.required]);
        this.form.get('sales_team_id').clearValidators() ;
        this.form.get('sales_team_id').setValue(null);
        await this.getUsers();
        this.salesTeams = [];
        this.isLoading = false;
        break;
      default:
        this.isLoading = false;
        break;
    }
  }

  changeClient(e: any): void {
    console.log(e);
  }

  changeBusiness(e: any): void {
    console.log(e);
  }

  changeTeam(e: any): void {
    console.log(e);
  }

  changeUser(e: any): void {
    console.log(e);
  }

  changeDuration(e: any): void {
    console.log(e);
  }

  changePriority(e: any): void {
    console.log(e);
  }

  changeExpirationDate(e: any): void {
    console.log(e);
  }

  verifySave(): void {
    let data = this.form.getRawValue();
    if (!data?.client_id && !data?.business_id) {
      this.confirmationService.confirm({
        target: event.target,
        message: '¿Desea guardar sin asociar un negocio o cliente?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save()
        },
        reject: () => {
            //reject action
            console.log('rejected');
        },
        key: "confirmDelete"
      });
    } else {
      this.save()
    }
  }

  save(): void {
    console.error('this.form:', this.form.getRawValue());

    this.isLoading = true;

    if(!this.selectedOwner) {
      this.form.get('sales_team_id').setValue(null);
      this.form.get('sales_team_id').clearValidators();
      this.form.get('user_id').setValue(null);
      this.form.get('user_id').clearValidators();
    }

    if (this.form.invalid) {
      console.error('Formulario inválido:', this.form.errors);
      console.log('primero error log');
      this.isLoading = false;
      return;
    }

    // Eliminar los campos seleccionados
    this.form.removeControl('selectedReferOption');
    this.form.removeControl('selectedOwnerOption');
    const formattedExpirationDate = this.datePipe.transform(this.form.get('expiration_date').value, 'yyyy-MM-dd');
    this.form.get('expiration_date').setValue(formattedExpirationDate);

    if (this.selectedTask) {
      this._tasks.update({...this.form.getRawValue()}, this.selectedTask.id).subscribe(
        (res) => {
          this.finishRequest();
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.error.emit(err.error.message);
        }
      );
    } else {
      this._tasks.create({...this.form.getRawValue()}).subscribe(
        (res) => {
          this.finishRequest();
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.error.emit(err.error.message);
        }
      );
    }
  }

  finishRequest(): void {
    this.isLoading = false;
    this.form.reset({
      subject: null,
      description: null,
      duration_time: null,
      expiration_date: null,
      client_id: null,
      business_id: null,
      user_id: null,
      sales_team_id: null,
      priority: null,
      status: 'Sin iniciar',
      selectedReferOption: null,
      selectedOwnerOption: null,
    });
    this.complete.emit(true);
    this.hideModal();
  }

  getBusiness(isAcivity: boolean = false): void {
    // this.isLoading = true;
    console.log(this.isLoading);
    this._business.get().subscribe({
      next: async (res:any)=>{
        if (res && res.data) {
          this.businesses = await res.data.filter((business) => business?.business_status?.id !== 7);
          if (isAcivity && this.objectId) {
            const referValue = this.businesses.find(el => parseInt(el.id) === this.objectId);
            if (referValue) {
              const {id} = referValue
              this.form.controls['business_id'].disable();
              this.form.get('business_id').setValue(id);
              this.form.get('selectedReferOption').setValue(this.selectedRefer);
            }
          }
          // this.isLoading = false;
        }
      },
      error: (err:any)=>{
        console.log(err);
        // this.isLoading = false;
      },
      complete: () => {
        console.log('complete');
        // this.isLoading = false;
      }
    })
  }

  getClients(isAcivity: boolean = false): void {
    // this.isLoading = true;
    this._clients.get().subscribe({
      next: async (res: any) => {
        if (res && res.data) {
          this.clients = await res.data.map((client: any) => {
            return {
              ...client,
              fullName: `${client.name} ${client.last_name}`
            };
          });
          if (isAcivity && this.objectId) {
            const referValue = this.clients.find(el => el.id === this.objectId);
            if (referValue) {
              const {id} = referValue
              this.form.controls['client_id'].disable();
              this.form.get('client_id').setValue(id);
              this.form.get('selectedReferOption').setValue(this.selectedRefer);
            }
          }
        }
      },
      error: (err: any) => {
        console.log(err);
        // this.isLoading = false;
      }
    });
  }


  getTeams(): void {
    // this.isLoading = true;
    this._salesTeams.get().subscribe(
      (res) => {
        this.salesTeams = res.data;
        // this.isLoading = false;
      },
      (error) => {
        console.log(error);
        // this.isLoading = false;
      }
    );
  }

  getUsers():void{
    // this.isLoading = true;
    this._users.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.users = res.data;
          // this.isLoading = false;
        }
      },
      (err:any)=>{
        console.log(err);
        // this.isLoading = false;
      }
    )
  }

  getColorByDate(dateString) {
    try {
      const date = moment(dateString);
      const today = moment();

      if (date.isBefore(today)) {
        return 2;
      } else if (date.diff(today, 'days') <= 1) {
        return 1;
      } else {
        return 0;
      }

    } catch (error) {
      return 0;
    }
  }
}
