import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesTeamsService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<any> {
    let url = `${this.apiUrl}sales-teams/list`;
    return this.http.get(url);
  }

  detail(id: number): Observable<any> {
    let url = `${this.apiUrl}sales-teams/${id}`;
    return this.http.get(url);
  }

  create(body:any): Observable<any> {
    let url = `${this.apiUrl}sales-teams/store`;
    return this.http.post(url, body);
  }

  getByBusiness(businessId:number): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${businessId}/list`;
    return this.http.get(url);
  }

  delete(businessId: number): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${businessId}`;
    return this.http.delete(url);
  }
  
  deleteSalesTeam(teamId: number): Observable<any> {
    let url = `${this.apiUrl}sales-teams/${teamId}`;
    return this.http.delete(url);
  }

  update(teamId: number, body: any): Observable<any> {
    let url = `${this.apiUrl}sales-teams/${teamId}`;
    return this.http.patch(url, body);
  }

  reconciliate(planId: number, body: any = null): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${planId}/reconciliate`;
    return this.http.patch(url, body);
  }

  getTeamDetails(teamId: number): Observable<any> {
    let url = `${this.apiUrl}sales-teams/${teamId}`;
    return this.http.get(url);
  }

  removeProject(teamId: number, projectId: number): Observable<any> {
    let url = `${this.apiUrl}sales-teams/remove/${teamId}`;
    return this.http.patch(url, {
      id: projectId,
      type: 'projects'
    });
  }

  removeUser(teamId: number, userId: number): Observable<any> {
    let url = `${this.apiUrl}sales-teams/remove/${teamId}`;
    return this.http.patch(url, {
      id: userId,
      type: 'users'
    });
  }

  getProjectTeamSale(): Observable<any> {
    let url = `${this.apiUrl}sales-teams/project-list`;
    return this.http.get(url);
  }

  getUserTeamSale(): Observable<any> {
    let url = `${this.apiUrl}sales-teams/users-list`;
    return this.http.get(url);
  }
}
