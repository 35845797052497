import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  color: ThemePalette = 'primary';
  @Input() mode: ProgressSpinnerMode = 'determinate';
  @Input() value = 50;
  @Input() complete = true;
  @Input() overlay = false;
  constructor() { }

  ngOnInit(): void {
  }

}
