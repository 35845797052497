import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCrashImage]'
})
export class CrashImageDirective{

  constructor(private elementRef: ElementRef) { }

  @HostListener('error')
  uploadDeafultImage():void{
    const element = this.elementRef.nativeElement;
    element.src = "https://via.placeholder.com/150";
  }

}
