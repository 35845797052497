import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivitiesService } from '@support/services/activities.service';
import { TasksService } from '@support/services/tasks.service';
import {MenuItem} from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@tools/services/notifications.service';
import { MessageService } from 'primeng/api';
import { NoteService } from '@tools/services/note.service';
import {ConfirmationService} from 'primeng/api';
import { BusinessService } from '@support/services/business.service';
import { PermissionUserService } from '@tools/services/permission-user.service';
import { AuthService } from '@core/auth/services/auth.service';
import { UserData } from '@core/models/user.model';

@Component({
  selector: 'app-activity-chart',
  templateUrl: './activity-chart.component.html',
  styleUrls: ['./activity-chart.component.scss'],
  providers: [MessageService]
})
export class ActivityChartComponent implements OnInit {

  public events: any[];
  public filteredEvents: any[];
  public items: MenuItem[] = [];
  public search: any;
  public note: number;
  public business: any = null;
  public loading = true;
  public showNoteForm: boolean = false;
  public taskId: number;

  public showTaskAssignmentForm: boolean = false;
  public showModalTask: boolean = false;
  public selectedTask: boolean = false;
  public selectedNote: boolean = false;
  public showDetailTask: boolean = false;
  public showDetailNote: boolean = false;
  public showBusinessDoc: boolean = false;
  public showClientDoc: boolean = false;
  public isAdmin: boolean = false;

  public canEdit: boolean = false;
  public canCreate: boolean = false;
  public canDelete: boolean = false;
  public canRead: boolean = false;
  public canUploadDocument: boolean = false;
  public canEditTask: boolean = false;
  public canCreateTask: boolean = false;
  public canDeleteTask: boolean = false;
  public canReadTask: boolean = false;
  public currentUser: UserData;

  @Output() isSuccess = new EventEmitter<boolean>();
  @Output() isSuccessMessage = new EventEmitter<any>();

  @Input() moduleId: number = null;
  @Input() objectId: number = null;

  constructor(
    private _activities: ActivitiesService,
    private _snackBar: NotificationsService,
    public messageService: MessageService,
    private _notes: NoteService,
    private _tasks: TasksService,
    private confirmationService: ConfirmationService,
    private _business: BusinessService,
    private _permission: PermissionUserService,
    private _auth: AuthService,
  ) {
    this._auth.user$.subscribe((user: UserData) => {
      if (user) {
        this.currentUser = user;
        this.isAdmin = this.currentUser?.user?.isAdminSubs === 1 ? true : false;
      }
    });
  }

  async ngOnInit() {
    const moduleName = this.moduleId === 7 ? 'Clientes' : this.moduleId === 10 ? 'Inmuebles' : 'Negocios';
    this.canEdit = this.getSelectedPermission('Actividades').includes('Editar');
    this.canCreate = this.getSelectedPermission('Actividades').includes('Crear');
    this.canDelete = this.getSelectedPermission('Actividades').includes('Desactivar');
    this.canRead = this.getSelectedPermission('Actividades').includes('Leer');

    this.canUploadDocument = this.getSelectedPermission(moduleName).includes('Editar');

    this.canEditTask = this.getSelectedPermission('Tareas').includes('Editar');
    this.canCreateTask = this.getSelectedPermission('Tareas').includes('Crear');
    this.canDeleteTask = this.getSelectedPermission('Tareas').includes('Desactivar');
    this.canReadTask = this.getSelectedPermission('Tareas').includes('Leer');

    const permissions = {
      module: moduleName ,
      edit: this.canEdit ,
      create: this.canCreate ,
      delete: this.canDelete ,
      read: this.canRead ,
      task1: this.canEditTask,
      task2: this.canCreateTask,
      task3: this.canDeleteTask,
      task4: this.canReadTask,
      document: this.canUploadDocument,
    }

    console.log(permissions);

    if(this.canCreate) {
      const noteItem = { label: 'Crear nota', icon: 'pi pi-fw pi-file-edit', command: () => this.showCreateNote() }
      this.items?.push(noteItem);
    }

    if (this.canUploadDocument) {
      const documentItem = {label: 'Subir documento', icon: 'pi pi-fw pi-cloud-upload', command: () => this.showDocumentModal()}
      this.items?.push(documentItem);
    }

    if (this.moduleId !== 10 && this.canCreateTask) {
      const taskItem = {label: 'Crear tarea', icon: 'pi pi-fw pi-check-circle', command: () => this.showTaskForm()}
      this.items?.push(taskItem);
    }

    console.log(this.items);

    await this.getEvents();
    if (this.moduleId === 11) {
      this.getBusiness();
    }
  }

  getSelectedPermission(param) {
    let permissions: string[] = [];
    const perm = this._permission.permissions.filter((p) =>
      p.names.includes(param)
    )[0];

    if (!perm) {
      return permissions;
    }
    permissions = perm.permissions.map((p) => p.name);
    return permissions;
  }

  async getEvents(): Promise<void> {
    const params = new HttpParams({fromObject: {
      module_id: this.moduleId,
      object_id: this.objectId,
    }})

    this._activities.get(params).subscribe(
      async (response: any) => {
        if (response && response.data) {
          this.events = response.data;
          this.filteredEvents = this.events;
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log('error: ', error);
        this.loading = false;
        this._snackBar.openSnackBar(
          'Ha ocurrido un error al consultar el detalle del negocio',
          'OK'
        );
      }
    );
  }

  getStatusClass(status: string, isDeleted: boolean): string {
    if (isDeleted) {
      return 'task_status task_status-deleted';
    } else {
      if (status === 'Sin iniciar') {
        return 'task_status task_status-no-started';
      } else if (status === 'Iniciado') {
        return 'task_status task_status-started';
      } else if (status === 'Completado') {
        return 'task_status task_status-completed';
      } else {
        return 'task_status task_status-closed';
      }
    }
  }

  changeSearch(event: any): void {
    const normalize = (text: string) => {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    };

    const normalizedSearch = normalize(this.search);

    this.filteredEvents = this.events.filter((item) => {
      return (
        normalize(item.message).includes(normalizedSearch) ||
        normalize(item.type).includes(normalizedSearch) ||
        normalize(item.date).includes(normalizedSearch) ||
        normalize(item.time).includes(normalizedSearch) ||
        (item?.submodule ? normalize(item.submodule).includes(normalizedSearch) : false) ||
        (item?.task?.title ? normalize(item.task.title).includes(normalizedSearch) : false) ||
        (item?.note?.title ? normalize(item.note.title).includes(normalizedSearch) : false) ||
        (item?.document?.name ? normalize(item.document.name).includes(normalizedSearch) : false) ||
        (item?.document?.type ? normalize(item.document.type).includes(normalizedSearch) : false) ||
        (item?.collection?.concept ? normalize(item.collection.concept).includes(normalizedSearch) : false)
      );
    });
  }


  showCreateNote() {
    this.note = null;
    this.showNoteForm = true;
    console.log(this.moduleId, this.showNoteForm);
  }

  deleteNote(noteId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Está seguro de eliminar esta nota?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this._notes.delete(noteId).subscribe({
          next: () => {
            const noteResult = { severity: 'success', summary: 'Éxito', detail: 'Nota eliminada con éxito' };
            this.isSuccessMessage.emit(noteResult)
            this.getEvents();
            this.loading = false;
            // Aquí puedes agregar lógica adicional, como actualizar la lista de notas
          },
          error: (err) => {
            const noteResult = { severity: 'error', summary: 'Error', detail: 'No se pudo eliminar la nota' };
            this.isSuccessMessage.emit(noteResult)
            this.loading = false;
            console.error(err);
          }
        });
      },
      reject: () => {
          //reject action
          console.log('rejected');
      },
      key: "confirmTaskDelete"
    });
  }

  editNote(noteId: any) {
    console.log(noteId,'esra');
    this._notes.detail(noteId).subscribe(
      (response: any) => {
        if(response?.data) {
          this.note = response?.data;
          this.showNoteForm = true;
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

  hideModal(e) {
    console.log(e);
    this.showNoteForm = e;
  }
  hideAssignmentModal(e) {
    console.log(e);
    this.showTaskAssignmentForm = e;
  }

  getSucess(e: boolean): void {
    this.isSuccess.emit(e);
    if(!e) {
      const noteResult = { sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: 'Hubo un error al crear la nota' };
      this.isSuccessMessage.emit(noteResult)
      return;
    }

    const noteResult = { sticky: true, severity: 'success', summary: 'Nota creada', detail: 'Se ha creado la nota de forma exitosa!' };
    this.isSuccessMessage.emit(noteResult)

    setTimeout(() => {
      this.getEvents();
    }, 4000);
  }

  getSucessTask(e: boolean): void {
    this.isSuccess.emit(e);
    if(!e) {
      const noteResult = { sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: 'Hubo un error al asignar el propietario' };
      this.isSuccessMessage.emit(noteResult)
      return;
    }

    const noteResult = { sticky: true, severity: 'success', summary: 'Propietario asignado', detail: 'Se ha asignado el propietario de forma exitosa!' };
    this.isSuccessMessage.emit(noteResult)

    setTimeout(() => {
      this.getEvents();
    }, 4000);
  }

  showTaskError(e: any): void {
    console.log(e)
    const successMsg = { sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: e };
    this.isSuccessMessage.emit(successMsg)
  }

  showNoteError(e: any): void {
    console.log(e)
    const successMsg = { sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: e };
    this.isSuccessMessage.emit(successMsg)
  }

  taskCreationComplete(e: any): void {
    if(!e) {
      return;
    }
    this.loading = true;
    const successMsg = { sticky: true, severity: 'success', summary: 'Tarea creada!', detail: 'Se ha creado la tarea con éxito!' };
    this.isSuccessMessage.emit(successMsg)

    setTimeout(() => {
      this.getEvents();
      this.loading = false;
    }, 4000);
  }

  showTaskForm(): void {
    this.showModalTask = true;
  }

  taskDetail(taskId: number): void {
    this._tasks.detail(taskId).subscribe((response) =>
    {
      if(response?.data) {
        this.selectedTask = response?.data;
        this.showDetailTask = true;
      }
    },
  (err)=> {console.log(err)})
  }

  deleteTask(taskId: number): void {
    console.log(taskId);
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Está seguro de eliminar esta tarea?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
          this._tasks.delete(taskId).subscribe(
            (res: any) => {
              const taskResult = { sticky: true, severity: 'success', summary: 'Tarea eliminada', detail: 'Se ha eliminado correctamente la tarea!' };
              this.isSuccessMessage.emit(taskResult)
              this.getEvents();
            },
            (err: any) => {
              const taskResult = { sticky: true, severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar la tarea' };
              this.isSuccessMessage.emit(taskResult)
              this.loading = false;
            }
          );
      },
      reject: () => {
          //reject action
          console.log('rejected');
      },
      key: "confirmTaskDelete"
    });
  }

  taskStatusChange(task: any, status: string) {
    this.confirmationService.confirm({
      target: event.target,
      message: `¿Estás seguro de que deseas cambiar el estado a "${status}"?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
          this._tasks.updateStatus(task.id, status).subscribe(
            (res: any) => {
              const taskResult = { severity: 'success', summary: 'Éxito', detail: 'Estado de la tarea cambiada con éxito' };
              this.isSuccessMessage.emit(taskResult)
              this.getEvents();
            },
            (err: any) => {
              const taskResult = { severity: 'error', summary: 'Error', detail: 'No se pudo actualizar el estado de la tarea' };
              this.isSuccessMessage.emit(taskResult)
              this.loading = false;
            }
          );
      },
      reject: () => {
          //reject action
          console.log('rejected');
      },
      key: "confirmTaskComplete"
    });
  }

  showDocumentModal(): void {
    switch (this.moduleId) {
      case 7:
        this.showClientDoc = true;
        this.showBusinessDoc = false;
        break;
      case 10:
        this.showClientDoc = true;
        this.showBusinessDoc = false;
        break;
      case 11:
        this.showBusinessDoc = true;
        this.showClientDoc = false;
        break;
      default:
        break;
    }
  }

  getBusiness(): void {
    this._business.detail(this.objectId).subscribe(
      async (response: any) => {
        if (response && response.data) {
          this.business = response.data;
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log('error: ', error);
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error al cargar el negocio' });
      }
    );
  }

  completeUpload(e: boolean): void {
    console.log(e);
    if (e) {
      this.showClientDoc = false;
      this.showBusinessDoc = false;
      const documentResult = { sticky: true, severity: 'success', summary: 'Carga completada', detail: 'El documento ha sido cargado correctamente' };
      this.isSuccessMessage.emit(documentResult)
      setTimeout(() => {
        this.getEvents();
      }, 5000);
    } else {
      this.showClientDoc = false;
      this.showBusinessDoc = false;
      const documentResult = { sticky: true, severity: 'error', summary: 'Error inesperado', detail: 'Ha ocurrido un error al cargar el documento' };
      this.isSuccessMessage.emit(documentResult)
    }
  }

  closeModal(e: boolean): void {
    this.showClientDoc = e;

  }

  showFailedMessage(e: any): void {
    const failed = { sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: e};
    this.isSuccessMessage.emit(failed)
  }

  assignmentTask(id: number): void {
    console.log('assignment');
    this.taskId = id;
    this.showTaskAssignmentForm = true;
  }

  noteDetail(noteId: number): void {
    this._notes.detail(noteId).subscribe((response) =>
    {
      if(response?.data) {
        this.selectedNote = response?.data;
        this.showDetailNote = true;
      }
    },
    (err)=> {console.log(err)})
  }
}
