import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { PropertyService } from '@support/services/property.service';
import { MessageService } from 'primeng/api'
import { HttpClient } from '@angular/common/http';
import { Table } from 'primeng/table';
import {ConfirmationService, ConfirmEventType} from 'primeng/api';
import { ClientsService } from '@support/services/clients.service'
import { TasksService } from '@support/services/tasks.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { PermissionUserService } from '@tools/services/permission-user.service';
import { UserData } from '@core/models/user.model';
import { AuthService } from '@core/auth/services/auth.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  providers: [MessageService]
})
export class TasksComponent implements OnInit {

  public tasks: any[] = [];
  public cols: any[] = [];
  public globalFilterFields: string[] = [];
  public isLoading = false;
  public isVisible = false;
  public isVisibleDetail = false;
  public showModal = false;
  public canViewTask = false;
  public canCreateTask = false;
  public canEditTask = false;
  public selectedTask: any|null = null;
  public canDeleteTask = false;
  public loadingAction: boolean = false;
  public currentUser: UserData;
  public isConfirming: boolean = false;
  public tasksStatuses: any = []
  public selectedStatus: any | null = null

    referenteOptions = [
      { label: 'Clientes', value: 'clientes' },
      { label: 'Negocios', value: 'negocios' },
      { label: 'Sin referente', value: 'sinReferente' },
    ];

    propietarioOptions = [
      { label: 'Usuarios', value: 'usuarios' },
      { label: 'Equipo de ventas', value: 'equipoVentas' }
    ];

  public selectedReferente: string[] = [];
  public selectedPropietario: string[] = [];
  public filteredTasks: string[];

  constructor(
    private _snackBar: NotificationsService,
    private _permission: PermissionUserService,
    private _tasks: TasksService,
    public messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialog: MatDialog,
    private _auth: AuthService,
    @Inject(HttpClient) private http: HttpClient
  ) {
    this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.getTasks()
    this.canViewTask = this.getSelectedPermission('Tareas').includes('Leer');
    this.canCreateTask = this.getSelectedPermission('Tareas').includes('Crear');
    this.canEditTask = this.getSelectedPermission('Tareas').includes('Editar');
    this.canDeleteTask = this.getSelectedPermission('Tareas').includes('Desactivar');
    this.setTasksStatuses();
    console.log('this._permission: ', this._permission)
  }

  getSelectedPermission(param) {
    let permissions: string[] = [];
    const perm = this._permission.permissions.filter((p) =>
      p.names.includes(param)
    )[0];

    if (!perm) {
      return permissions;
    }
    permissions = perm.permissions.map((p) => p.name);
    return permissions;
  }

  setColumns(): void {
    this.tasks?.forEach(element => {
      element.refer = element?.business ? element?.business?.name : element?.client?.name
      element.owner = element?.sales_team ? element?.sales_team?.name : element?.user?.name
    });

    this.cols = [
      { field: 'subject', header: 'Asunto', dataKey: 'subject', filterable: true, sortable: true },
      { field: 'refer', header: 'Referente a', dataKey: 'refer', filterable: true, sortable: true  },
      { field: 'priority', header: 'Prioridad', dataKey: 'priority', filterable: true, sortable: true },
      { field: 'status', header: 'Estado', dataKey: 'status', filterable: true, sortable: true  },
      { field: 'owner', header: 'Propietario', dataKey: 'owner', filterable: true, sortable: true  },
      { field: 'expiration_date_format', header: 'Fecha de Vencimiento', dataKey: 'expiration_date_format', filterable: true, sortable: true  },
    ];

    this.globalFilterFields = this.cols.filter(col => col.dataKey).map(col => col.dataKey);
  }

  // clearFilters(table: Table) {
  //   table.clear();
  // }

  showCreationModa(task = null): void {
    this.selectedTask = task
    this.isVisible = true;
  }

  showDetailModal(task = null): void {
    if (task) {
      this.selectedTask = task
      this.isVisibleDetail = true;
    }
  }

  showError(e): void {
    console.log(e);
    this.messageService.add({ sticky: true, severity: 'error', summary: 'Ha ocurrido un error', detail: e });
  }

  creationCompleted(e): void {
    if(!e) {
      console.log(e);
      return;
    }

    if (this.selectedTask) {
      this.messageService.add({ sticky: true, severity: 'success', summary: 'Tarea actualizada', detail: 'Se ha actualizado la tarea de forma exitosa!' });
    } else {
      this.messageService.add({ sticky: true, severity: 'success', summary: 'Tarea creada', detail: 'Se ha creado la tarea de forma exitosa!' });
    }

    setTimeout(() => {
      this.getTasks();
    }, 4000);
    console.log(e);
  }

  getTasks():void {
    this.isLoading = true;
    this._tasks.get().subscribe({
      next: (res:any) => {
        if (res && res.data) {
          console.log(res.data, "listado de tareas");
          this.tasks = res.data.map((item: any) => {
            return {
              ...item,
              expiration_date_format: moment(item.expiration_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            }
          })
          this.setColumns();
        } else {
          this.tasks = []
        }
        this.isLoading = false;
      },
      error: (err:any) => {
        this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error al consultar el listado de tareas' });
        this.isLoading = false;
      }
    })
  }

  deleteElement(id: number): void {
    this.confirmationService.confirm({
      target: event.target,
      message: '¿Está seguro de eliminar esta tarea?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading = true;
          this._tasks.delete(id).subscribe(
            (res: any) => {
              this.messageService.add({ sticky: true, severity: 'success', summary: 'Tarea eliminada', detail: 'Se ha eliminado correctamente la tarea!' });
              this.getTasks();
            },
            (err: any) => {
              this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar la tarea' });
              this.isLoading = false;
            }
          );
      },
      reject: () => {
          //reject action
          console.log('rejected');
      },
      key: "confirmDelete"

    });
  }

  onStatusChange(rowData: any) {
    if (this.isConfirming) return;

    this.isConfirming = true;
    const tasksId = rowData.id;
    const newStatus = rowData.status;

    this.confirmationService.confirm({
      message: `¿Estás seguro de que deseas cambiar el estado a "${newStatus}"?`,
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log('2');
        this.updateStatus(tasksId, newStatus);
        this.isConfirming = false;
      },
      reject: (type) => {
        this.isConfirming = false;
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      },
      key: "statusTask"
    });
  }


  updateStatus(id, status): void {
    console.log('3');
    this.isLoading = true;
    this._tasks.updateStatus(id, status).subscribe(
      (response) => {
        console.log('Estado actualizado:', response);
        this.messageService.add({ severity: 'success', summary: 'Estado actualizado', detail: 'Se ha cambiado el estado de la tarea correctamente' });
        this.isLoading = false;
      },
      (error) => {
        console.error('Error al actualizar el estado:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error al cambiar el estado' });
        this.isLoading = false;
      }
    );
  }

  applyReferenteFilter(): void {
    let filteredTasks = this.tasks;
    console.log(this.selectedReferente);

    if (this.selectedReferente.length === 0) {
      this.filteredTasks = this.tasks;
      return;
    }

    // Filtrar tareas según las opciones seleccionadas
    this.filteredTasks = filteredTasks.filter(task => {
      const isClient = this.selectedReferente.includes('clientes') && task.business_id === null && task.client_id;
      const isBusiness = this.selectedReferente.includes('negocios') && task.business_id;
      const isWithoutReferente = this.selectedReferente.includes('sinReferente') && task.business_id === null && task.client_id === null;

      return isClient || isBusiness || isWithoutReferente;
    });
    console.log('Filtro de Referente aplicado:', this.filteredTasks);
  }

  applyPropietarioFilter() {
    let filteredTasks = this.tasks;

    if (this.selectedPropietario.length === 0) {
      this.filteredTasks = this.tasks;
      return;
    }

    this.filteredTasks = filteredTasks.filter(task => {
      const users = this.selectedPropietario.includes('usuarios') && task.user_id;
      const salesTeams = this.selectedPropietario.includes('equipoVentas') && task.user_id === null && task.sales_team_id;

      return users || salesTeams;
    });
    console.log('Filtro de Propietario aplicado:', this.filteredTasks);
  }

  clearFilters(table: any) {
    this.selectedReferente = [];
    this.selectedPropietario = [];
    table.filterGlobal('', 'contains');
    table.clear();
  }

  clearReferFilter(): void {
    this.selectedPropietario = [];
    this.applyReferenteFilter();
  }

  getColorByDate(expiration_message) {
    try {

      if (expiration_message.indexOf("Su tarea expiró") >= 0) {
        return 2;
      } else if (expiration_message.indexOf("Su tarea vencerá") >= 0) {
        return 1;
      } else {
        return 0;
      }

    } catch (error) {
      return 0;
    }
  }

  setTasksStatuses(): void {
    this.tasksStatuses = [
      { id: null, name: 'Todos', cssClass: '' },
      { id: 1, name: 'Sin iniciar', cssClass: 'p-button-help' },
      { id: 2, name: 'Iniciado', cssClass: 'p-button-info' },
      { id: 3, name: 'Completado', cssClass: 'p-button-success'},
      { id: 4, name: 'Cerrado', cssClass: 'p-button-danger'},
    ]
  }

  onChangeStatus(status: any) {
    this.selectedStatus = status
    this.filterTasksByStatus()
  }

  filterTasksByStatus() {
    this.filteredTasks = this.tasks.filter(
      item => (!this.selectedStatus || this.selectedStatus.id ===  null || item.status === this.selectedStatus?.name)
    );
  }
}
