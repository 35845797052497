import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FullscreenService {
  constructor(private ngZone: NgZone) { }

  onFullscreenChange(): Observable<Event> {
    return new Observable(observer => {
      const handler = (event: Event) => this.ngZone.run(() => observer.next(event));

      document.addEventListener('fullscreenchange', handler);
      document.addEventListener('webkitfullscreenchange', handler);
      document.addEventListener('mozfullscreenchange', handler);
      document.addEventListener('msfullscreenchange', handler);

      return () => {
        document.removeEventListener('fullscreenchange', handler);
        document.removeEventListener('webkitfullscreenchange', handler);
        document.removeEventListener('mozfullscreenchange', handler);
        document.removeEventListener('msfullscreenchange', handler);
      };
    });
  }

  isFullscreen(): boolean {
    return document.fullscreenElement !== null
      || ((document as any).webkitFullscreenElement !== null && (document as any).webkitFullscreenElement !== undefined)
      || ((document as any).mozFullScreenElement !== null && (document as any).mozFullScreenElement !== undefined)
      || ((document as any).msFullscreenElement !== null && (document as any).msFullscreenElement !== undefined)
  }
}
