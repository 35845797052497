import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsPlanService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  create(body:any): Observable<any> {
    let url = `${this.apiUrl}payment-plans/store`;
    return this.http.post(url, body);
  }

  getByBusiness(businessId:number): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${businessId}/list`;
    return this.http.get(url);
  }

  delete(businessId: number): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${businessId}`;
    return this.http.delete(url);
  }

  update(planId: number, body: any): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${planId}/quotes`;
    return this.http.patch(url, body);
  }

  reconciliate(planId: number, body: any = null): Observable<any> {
    let url = `${this.apiUrl}payment-plans/${planId}/reconciliate`;
    return this.http.patch(url, body);
  }

}
