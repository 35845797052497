import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Injector,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BusinessService } from '@support/services/business.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { Client } from '@support/models/client.model';
import { Project } from '@support/models/project.model';
import { Property } from '@support/models/property.model';
import { CurrencyService } from '@admin/services/currency.service';
import { CollectionService } from '@support/services/collection.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { BanksService } from '@admin/services/banks.service';
import { IBank } from '@admin/models/IBank';
import { UsersService } from '@admin/services/users.service';
import { PermissionUserService } from '@tools/services/permission-user.service';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { CreateReservesComponent } from '@support/pages/reserves/create-reserves/create-reserves.component';
import { AuthService } from '@core/auth/services/auth.service';
import { UserData } from '@core/models/user.model';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { PaymentsPlanService } from '@support/services/payments-plan.service';
import { ProjectService } from '@support/services/project.service';
import { PropertyService } from '@support/services/property.service';
import { ClientsService } from '@support/services/clients.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-detail-business',
  templateUrl: './detail-business.component.html',
  styleUrls: ['./detail-business.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class DetailBusinessComponent implements OnInit {
  public businessId: number;
  public business: any;
  public form: FormGroup;
  public clients: Client[] = [];
  public projects: Project[] = [];
  public propertys: Property[];
  public collections: any = [];
  public transfers: any = [];
  public loading = false;
  public currencys: any;
  public currency: any;
  visible: boolean = false;
  disabled: boolean = true;
  private stripe: any;
  public selectedCollection: any = null;
  public showCheckInfo: boolean = false;
  public showUploadDocument: boolean = false;
  public banks: IBank[] = [];
  public canEdit = false;
  public canEditCollection = false;
  public canCreateCollection = false;
  public paymentPermissions: any = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  public canCreatePaymentPlan = false;
  public canEditPaymentPlan = false;
  public items: any = [];
  public canSetUser = false;
  public canTransfer = false;
  public users: any = [];
  public documents: any = [];
  public showModal = false;
  public selectedUser: any;
  chipLabel: string = 'Ejemplo';
  public currentUser: UserData;
  public showLostBusinessButton: boolean = false;
  public steps: MenuItem[];
  public activeIndex: number = null;
  public tabIndex: number = 0;
  public showPaymentForm: boolean = false;
  public showTransferUnit: boolean = false;
  public showTransferClient: boolean = false;
  public paymentsPlan: any = null;
  public savePaymentButton: boolean = true;
  public loadingAction: boolean = false;
  public showCollectionModal: boolean = false;
  public collectionData: any = null;
  public moduleId: number = 11;
  public displayModal: boolean = false;
  public paymentLink: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _business: BusinessService,
    private _snackBar: NotificationsService,
    private _collections: CollectionService,
    private _bank: BanksService,
    private _project: ProjectService,
    private _users: UsersService,
    private _clients:ClientsService,
    private _property: PropertyService,
    private _permission: PermissionUserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private _auth: AuthService,
    private _paymentsPlan: PaymentsPlanService
  ) {
    this._auth.user$.subscribe((user: UserData) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    this.loading = true;
    this.steps = [
      {
        label: 'Bloqueo',
        command: (event: any) => {},
      },
      {
        label: 'Reserva',
        command: (event: any) => {},
      },
      {
        label: 'Contrato',
        command: (event: any) => {},
      },
      {
        label: 'Entrega',
        command: (event: any) => {},
      },
    ];
    this.canEdit = this._permission.setPermission().includes('Editar');
    this.canEditCollection =
      this.getSelectedPermission('Cobros').includes('Editar');
    this.canCreateCollection =
      this.getSelectedPermission('Cobros').includes('Crear');
    this.canTransfer = this.getSelectedPermission('Negocios').includes('Traspasos');
    this.canSetUser = this._permission
      .setConfigPermission()
      .includes('Asignar registro');
    this.stripe = await loadStripe(
      'pk_test_51O9rmKARUYO35CG4gAHWGk8aRDbKZWTGOqUVYjGLRvNmH4JmRwv6cYVpRed2uNjYCGEmJgec37KeP7L8fTGKwLvw007ilIiOjt'
    );
    // this.injector.get<Stripe>('Stripe', { providedIn: 'root', factory: () => stripe });
    // const stripeService = { provide: 'Stripe', useValue: stripe };
    // this.injector.get(stripeService)
    // this.stripe = await loadStripe('pk_test_51O9rmKARUYO35CG4gAHWGk8aRDbKZWTGOqUVYjGLRvNmH4JmRwv6cYVpRed2uNjYCGEmJgec37KeP7L8fTGKwLvw007ilIiOjt');
    await this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.businessId = Number(params.get('id'));
      if (this.businessId) {
        await this.getBusiness();
      }
    });
  }

  setConditions() {
    this.items = [];
    const asign = this.items.find((item) => item.label === 'Asignar');
    const collection = this.items.find(
      (item) => item.label === 'Registrar Cobro'
    );
    const lost = this.items.find(
      (item) => item.label === 'Cerrar como perdido'
    );
    const stripe = this.items.find((item) => item.label === 'Pagar con Stripe');
    const paymentPlan = this.items.find(
      (item) => item.label === 'Crear plan de pagos'
    );
    if (this.canSetUser) {
      const setOptions = {
        label: 'Asignar',
        icon: 'pi pi-users',
        command: () => {
          this.getUsers();
        },
      };
      if (!asign) this.items.push(setOptions);
    }

    if (this.canEdit) {
      if (!collection)
        this.items.push({
          label: 'Registrar Cobro',
          icon: 'pi pi-dollar',
          command: () => {
            this.openCollectionModal();
            // this.openReservesDialog();
          },
        });
    }

    // if (this.business?.business_status?.id == 2) {
      if(this.canTransfer) {
        const setOptions = {label: 'Realizar traspaso / Cliente', icon: 'pi pi-sort-alt', command: () => {this.tranferClient()}}
        if (!asign) this.items.push(setOptions);
      }

      if(this.canTransfer && this.business?.is_combine !== 1) {
        const setOptions = {label: 'Realizar traspaso / Unidad', icon: 'pi pi-sort-alt', command: () => {this.tranferUnit()}}
        if (!asign) this.items.push(setOptions);
      }
    // }

    if (!paymentPlan && this.paymentPermissions.create) this.items.push(
          {label: 'Crear plan de pagos', icon: 'pi pi-book', command: () => {
          this.openCreatePlanForm()
        }}
    );

    if(this.showLostBusinessButton) {
      if (!lost) this.items.push(
        {label: 'Cerrar como perdido', icon: 'pi pi-times-circle', command: () => {
          this.openUpdateStatusToLostDialog(this.business?.id)
        }}
      );
    }

    if(this.business?.project?.payment_link) {
      const stripeOption = {label: 'Pagar con Stripe', icon: 'pi pi-credit-card', url: this.business?.project?.payment_link}
      if (!stripe) this.items.splice(this.items.length > 0 ? this.items.length - 1 : 1, 0, stripeOption);
    }

    this.loading = false;
  }

  mapCollections() {
    if (this.canEdit) {
      const businessStatus1 = this.business?.business_status?.id === 1;
      const noStatus2 = !this.business.collections.some(
        (collection) => collection.collection_status_id === 2
      );
      const noStatus1 = !this.business.collections.some(
        (collection) => collection.collection_status_id === 1
      );
      return businessStatus1 || (noStatus2 && noStatus1);
    } else {
      return false;
    }
  }

  showDialog() {
    this.visible = true;
  }

  getCollections(): void {
    this._collections.byBusiness(this.businessId).subscribe(
      (res: any) => {
        if (res && res.data) {
          this.collections = res.data;
        }
      },
      (err: any) => {
        this._snackBar.openSnackBar('Error al consultar cobros', 'OK');
        this.loading = false;
      }
    );
  }

  getBusiness(): void {
    this.loading = true;
    this._business.detail(this.businessId).subscribe(
      async (response: any) => {
        if (response && response.data) {
          this.business = response.data;
          if (this.business){
            switch (this.business?.business_status?.id) {
              case 1:
                this.activeIndex = 0;
                break;
              case 2:
                this.activeIndex = 1;
                break;
              case 3:
                this.activeIndex = 2;
                break;
              case 7:
                this.activeIndex = null;
                break;

              default:
                break;
            }
            this.transferList();
            await this.getCollections();
            this.showLostBusinessButton = await this.mapCollections();
            if (this.business?.activePaymentPlan?.length > 0) {
              await this.getPaymentPlan();
            }
            await this.setPaymentsPermissions();
            this.currency = this.business?.project?.currency_id;
          }
        }
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log('error: ', error);
        this.loading = false;
        this._snackBar.openSnackBar(
          'Ha ocurrido un error al consultar el detalle del negocio',
          'OK'
        );
      }
    );
  }

  documentComplete(e: boolean = false): void {
    if (!e) {
      this.showNotify(
        'error',
        'Error',
        'Ha ocurrido un error con el documento'
      );
      return;
    }
    this.showNotify(
      'success',
      'Operación exitosa',
      'Se ha realizado la operación con éxito'
    );
    this.getBusiness();
    if (this.business?.activePaymentPlan?.length === 0) {
      this.tabIndex = 2;
      return;
    }
    this.tabIndex = 3;
  }

  async setPaymentsPermissions(): Promise<void> {
    const paymentPlanExist =
      this.business?.activePaymentPlan?.length > 0 || false;
    const canCreate =
      this.getSelectedPermission('Plan de pagos').includes('Crear');
    const canEdit =
      this.getSelectedPermission('Plan de pagos').includes('Editar');
    const canRead =
      this.getSelectedPermission('Plan de pagos').includes('Leer');
    const canDelete =
      this.getSelectedPermission('Plan de pagos').includes('Desactivar');
    const canExport =
      this.getSelectedPermission('Reportes').includes('Estado de cuenta');
    this.paymentPermissions = {
      create: (canCreate && !paymentPlanExist) || false,
      read: (canRead && paymentPlanExist) || false,
      update: (canEdit && paymentPlanExist) || false,
      delete: (canDelete && paymentPlanExist) || false,
      export: (canExport && paymentPlanExist) || false,
    };
    await this.setConditions();
  }

  redirectToStripeCheckout(): void {
    window.open('https://buy.stripe.com/test_7sI17kdw06I88SsfYY', '_blank');
  }

  changeBank(event: any): void {
    console.log('changeBank: ', event);
  }

  getBanks(): void {
    this._bank.get(1).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.banks = response.data;
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar(
          'Ha ocurrido un error al consultar el listado de bancos',
          'OK'
        );
      }
    );
  }

  transferList(event = null): void {
    const params = new HttpParams({ fromObject: {
      businessId: this.business?.id,
      type: event
    }});

    this._business.transferList(params).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.transfers = response.data;
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar('Ha ocurrido un error al consultar el listado de traspasos', 'OK');
      }
    );
  }

  getSelectedPermission(param) {
    let permissions: string[] = [];
    const perm = this._permission.permissions.filter((p) =>
      p.names.includes(param)
    )[0];

    if (!perm) {
      return permissions;
    }
    permissions = perm.permissions.map((p) => p.name);
    return permissions;
  }

  tranferUnit():void{
    this.loadingAction = true;

    this._project.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.projects = res.data
          this.showTransferUnit = true;
          this.loadingAction = false;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.loading = false;
      }
    )
  }

  tranferClient():void{
    this.loadingAction = true;

    this._clients.get().subscribe(
      (res:any)=>{
        if (res && res.data) {
          this.clients = res.data
          this.showTransferClient = true;
          this.loadingAction = false;
        }
      },
      (err:any)=>{
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.loading = false;
      }
    )
  }

  getUsers():void{
    this._users.get().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.users = res.data;
          this.showModal = true;
        }
      },
      (err: any) => {
        this._snackBar.openSnackBar('Error al consultar usuarios', 'OK');
        this.loading = false;
      }
    );
  }

  async documentList() {
    this._business.listDocuments(this.businessId).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.business.documents = response.data;
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar(
          'Ha ocurrido un error al consultar el listado de documentos',
          'OK'
        );
      }
    );
  }

  async asignNewUSer() {
    this.confirmationService.confirm({
      message:
        'Al realizar esta acción la negociacion será asignada a otro usuario',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //Actual logic to perform a confirmation
        this._business
          .setNewUser(this.business.id, this.selectedUser)
          .subscribe(
            (res: any) => {
              console.log(res);
              if (res.res) {
                const message = 'Asignacion exitosa';
                this._snackBar.openSnackBar(message, 'OK');
                // this.router.navigate(['/card-project']);
              }
              this.loading = false;
              this.showModal = false;
            },
            (err: HttpErrorResponse) => {
              this.loading = false;
              if (err.status === 422) {
                this._snackBar.openSnackBar(err.error.message, 'OK');
              } else {
                this._snackBar.openSnackBar(
                  'Error. Por favor intente más tarde.',
                  'OK'
                );
              }
              this.showModal = false;
            }
          );
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            this.showModal = false;
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            this.showModal = false;
            break;
        }
      },
      key: "businessDialog"
    });
  }

  openUpdateStatusToLostDialog(id: number): void {
    this.dialog
      .open(ModalConfirmComponent, {
        data: {
          title: 'Cerrar negocio como Perdido',
          message:
            '¿Está seguro de que desea cambiar el status del negocio a "Perdido"?',
          btnConfirm: true,
          btnCancel: true,
          btnConfirmText: 'Aceptar',
          btnCancelText: 'Cancelar',
        },
      })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          this._business.updateStatusToLost(id).subscribe({
            next: async (res: any) => {
              this._snackBar.openSnackBar(
                'Negocio actualizado correctamente',
                'OK'
              );
              await this.getBusiness();
              await this.getCollections();
              this.router.navigate(['/card-project']);
            },
            error: (err: any) => {
              this.loading = false;
              if (err.status === 422) {
                this._snackBar.openSnackBar(err.error.message, 'OK');
              } else {
                this._snackBar.openSnackBar(
                  'Error. Por favor intente más tarde.',
                  'OK'
                );
              }
            },
          });
        }
      });
  }

  openCollectionModal(): void {
    this.collectionData = {
      businessId: this.businessId,
      clientId: this.business?.client?.id,
      projectId: this.business?.project?.id,
    }
    this.showCollectionModal = true;
  }

  openReservesDialog(): void {
    const dialogRef = this.dialog.open(CreateReservesComponent, {
      data: {
        businessId: this.businessId,
        clientId: this.business?.client?.id,
        projectId: this.business?.project?.id,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.getBusiness();
      if (result !== undefined) {
        if (result?.displayModal) {
          this.showNotify('success', 'Orden de pago creada', 'Se ha creado una orden de pago con tarjeta, en breve obtendrá el link de pago');
        } else if (result?.orderError) {
          this.showNotify('error', 'Error', result?.orderError);
        }
        setTimeout(() => {
          this.paymentLink = result?.paymentLink;
          this.displayModal = result?.displayModal;
        }, 4000);
      }
    });
  }

  openCreatePlanForm(): void {
    this.showPaymentForm = true;
  }

  getPaymentPlan(): void {
    this.loading = true;
    this._paymentsPlan.getByBusiness(this.businessId).subscribe(
      async (res: any) => {
        this.loading = false;
        if (res) {
          this.paymentsPlan = res.data ? res.data : null;
          this.loading = false;
        }
      },
      (err:any)=>{
        console.log('err: ', err)
    });
  }

  updatePlan(): void {
    this.loading = true;
    this.paymentsPlan.balance = this.paymentsPlan?.balance.toString();
    this._paymentsPlan
      .update(this.paymentsPlan?.id, this.paymentsPlan)
      .subscribe(
        async (res: any) => {
          if (res) {
            this.showNotify(
              'success',
              'Actualización exitosa',
              'El plan de pagos se ha actualizado con éxito'
            );
            this.getBusiness();
            this.savePaymentButton = true;
            setTimeout(() => {
              this.loading = false;
            }, 4000);
          }
        },
        (err: any) => {
          this.showNotify(
            'error',
            'Falló',
            'Ha ocurrido un error al actualizar el plan de pagos'
          );
          this.loading = false;
          console.log(err);
        });
  }

  showNotify(severity, summary, detail): void {
    this.messageService.add({
      key: 'tr',
      severity: severity,
      summary: summary,
      detail: detail,
    });
  }

  setPaymentButtonStatus(e): void {
    this.savePaymentButton = e;
    console.log('setPaymentButtonStatus: ', this.tabIndex, this.savePaymentButton);
  }

  showFailedMessage(e: any): void {
    if (e) {
      this.showNotify(
        'error',
        'Ha ocurrido un error',
        e
      );
    }
  }

  showCollectionMsg(e: any): void {
    if (e && e?.detail) {
      this.showNotify(
       e?.severity,
       e?.summary,
       e?.detail
      );
      if(e.severity === 'success') {
        this.showCollectionModal = false;
        this.getBusiness();
      }
    }
  }

  showSuccessActivity(e: any) {
    console.log(e);
    if (e && e?.detail) {
      this.showNotify(
       e?.severity,
       e?.summary,
       e?.detail
      );
      setTimeout(() => {
        this.getBusiness();
      }, 3000);
    }
  }

  showLink($event): void {
    const data = $event;
    if(data?.displayModal) {
      this.showNotify('success', 'Orden de pago creada', 'Se ha creado una orden de pago con tarjeta, en breve obtendrá el link de pago');
      setTimeout(() => {
        this.paymentLink = data?.paymentLink;
        this.displayModal = data?.displayModal;
      }, 4000);
    }
  }

  showCardNotify($event): void {
    const {status} = $event;
    console.log(status);
    switch (status) {
      case 'conciliate':
        this.messageService.add({key: 'tr', severity: 'success', summary: 'Operacion exitosa', detail: 'La orden fue procesada y el cobro ha sido conciliado'});
        setTimeout(() => {
          this.getCollections();
        }, 8000);
        break;
        case 'conciliation failed':
          this.messageService.add({key: 'tr', severity: 'error', summary: 'Cobro no conciliado', detail: 'La orden fue procesada pero el cobro no pudo ser conciliado'});
          setTimeout(() => {
            this.getCollections();
          }, 8000);
          break;
          case 'expired':
            this.messageService.add({key: 'tr', severity: 'warn', summary: 'El link de pago expiró', detail: 'El link de pago ha expirado, debe generar una nueva orden'});
            setTimeout(() => {
              this.getCollections();
            }, 8000);
            break;
          case 'pendding':
            this.messageService.add({key: 'tr', severity: 'warn', summary: 'Estado Pendiente', detail: 'La orden aún no se ha procesado'});
            setTimeout(() => {
              this.getCollections();
            }, 8000);
            break;
      default:
        break;
    }
    // if ($event) {
    // } else {
    //   this.messageService.add({key: 'tr', severity: 'error', summary: 'No completado', detail: 'Ocurrio un erro al verificar el estado de la orden'});
    // }
  }
  closeLinkModal($event): void {
    this.displayModal = $event;
    this.getCollections();
  }

  openPaymentModal($event: any): void {
    if($event.paymentLink) {
      this.messageService.add({key: 'tr', severity: 'info', summary: 'Orden de pago creada', detail: 'En un momento podrá ver el link de pago'});
      this.paymentLink = $event.paymentLink;
      this.displayModal = $event.displayModal;
    }
  }
}
