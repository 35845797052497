<p-toast [baseZIndex]="20000"></p-toast>
<p-table
  #collectionsTable
  styleClass="p-datatable-striped p-datatable-sm"
  *ngIf="collections?.length > 0"
  [value]="collections"
  [columns]="cols"
  [globalFilterFields]="globalFilterFields"
  [tableStyle]="{'min-width': '50rem'}"
  [paginator]="true"
  [rows]="20"
  scrollHeight="430px"
  [scrollable]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first} - {last} de {totalRecords}"
  >
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap align-items-center justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="p-inputtext-sm" (input)="collectionsTable.filterGlobal($event.target.value, 'contains')" placeholder="Buscar" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th>Comprobante de pago</th>
          <th style="text-align: center">Acciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td *ngFor="let col of columns">
          {{rowData[col.field]}}
        </td>
        <td>
          <span>
            {{ getFileNameFromUrl(rowData?.payment_document?.url) }}
          </span>
          <span>
            <button pButton (click)="downloadFile(rowData?.payment_document?.url)" class="p-button-text download-button" type="button" icon="pi pi-download" iconPos="left"></button>
          </span>
        </td>
        <td style="text-align: center">
          <div *ngIf="rowData?.collection_status?.id !== 3" class="flex align-items-center justify-content-center gap-2">
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                   *ngIf="
                    rowData?.collection_status?.id === 1 && rowData?.payment_method === 'Tarjeta de crédito/débito' && rowData?.order?.available && !rowData?.order?.complete
                    "
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-info"
                    icon="pi pi-sync"
                    pTooltip="Verificar estado"
                    (click)="showOrderStatus(rowData)"></button>
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                   *ngIf="
                    (rowData?.collection_status?.id === 1 && rowData?.payment_method === 'Tarjeta de crédito/débito' && !rowData?.order) ||
                    (rowData?.collection_status?.id === 1 && rowData?.payment_method === 'Tarjeta de crédito/débito' && !rowData?.order?.available && !rowData?.order?.complete)
                    "
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-help"
                    icon="pi pi-credit-card"
                    pTooltip="Generar orden de pago"
                    (click)="createOrder(rowData)"></button>
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                   *ngIf="rowData?.collection_status?.id === 1 && rowData?.payment_method !== 'Tarjeta de crédito/débito'"
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-success"
                    icon="pi pi-check-circle"
                    pTooltip="Conciliar"
                    (click)="openApprovalPaymentDialog(rowData?.id)"></button>
            <button [disabled]="(!canEdit && !canCreate) || user?.role?.external_access === 1"
                    *ngIf="rowData?.collection_status?.id === 1 && rowData?.payment_method !== 'Tarjeta de crédito/débito'"
                    pButton type="button"
                    class="p-button-rounded p-button-text p-button-danger"
                    icon="pi pi-times-circle"
                    pTooltip="Rechazar"
                    (click)="openRejectionPaymentDialog(rowData?.id)"></button>
            <button *ngIf="rowData?.collection_status?.id === 2" pButton type="button" class="p-button-rounded p-button-text p-button-info" icon="pi pi-file" pTooltip="Generar recibo" (click)="generatePaymentReceipt(rowData?.id)"></button>
            <button *ngIf="rowData?.collection_status?.id === 2"
            pButton type="button"
            class="p-button-rounded p-button-text p-button-info"
            icon="pi pi-ban"
            pTooltip="Anular cobro"
            (click)="openCancelPaymentDialog(rowData?.id)"
            [disabled]="(!canCreate && !canEdit) || user?.role?.external_access === 1"></button>
          </div>
          <span *ngIf="rowData?.collection_status?.id === 3">-</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No se encontraron resultados.</td>
      </tr>
    </ng-template>
</p-table>
<app-card-payment-rate [displayModal]="showRateModal" [collection]="selectedCollection" (orderData)="saveOrder($event)" (showModal)="showRateModal = $event"></app-card-payment-rate>

