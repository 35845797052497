<div class="table-responsive">
  <!-- Nueva tabla -->
  <table mat-table [dataSource]="dataSource" class="table" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
      <td mat-cell *matCellDef="let row"> {{row?.name || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="stage_project_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Etapa </th>
      <td mat-cell *matCellDef="let row"> {{row?.stage_project_id.name || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
      <td mat-cell *matCellDef="let row">{{row?.price  || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="status_parking_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
      <td mat-cell *matCellDef="let row"> {{row?.status_parking_id.name || '-'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button class="btn action-btn" (click)="edit(row)">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button class="btn action-btn" (click)="detail(row)">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button class="btn action-btn-delete" (click)="delete(row.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No se encuentra "{{input?.value}}"</td>
    </tr>

  </table>

  <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[25, 50, 75, 100]"
    aria-label="Select page of users" *ngIf="parkings && parkings.length > 25">
  </mat-paginator>
</div>
