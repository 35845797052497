import { Injectable } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  public module: string;
  public routeModule:string;
  public page:string;
  public routePage:string;
  public subPage:string;

  constructor(
    private router: Router
  ) {

    this.router.events
    .pipe(
      filter((event): event is ActivationEnd => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      map((event: ActivationEnd) => event.snapshot.data)
    )
    .subscribe((event) =>{
      const { module, page, subPage} = event;
      this.module = module?.title;
      this.routeModule = module?.route;
      this.page = page?.title;
      this.routePage = page?.route;
      this.subPage = subPage?.title;
    })

  }
}
