<app-spinner *ngIf="loading"></app-spinner>
<div class="full-width">
  <div *ngIf="!loading" class="form-wraper">

    <div class="form-container card">
      <div class="form-body">
        <form *ngIf="data && data.fromBusiness" [formGroup]="form" class="row mx-0 md:mx-5">
          <div class="divider my-5">
            <div class="flex justify-content-between mb-3">
              <h4><mat-icon class="mr-1"> contacts </mat-icon> Datos generales del cliente</h4>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Nombre</mat-label>
                  <input matInput formControlName="name" placeholder="Nombre" type="text" class="form-input">
                </mat-form-field>
                <div class="text-danger small">{{getError('name')}}</div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Apellido</mat-label>
                  <input matInput formControlName="last_name" placeholder="Apellido" type="text" class="form-input">
                </mat-form-field>
                <div class="text-danger small">{{getError('last_name')}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <div>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Correo Eléctronico</mat-label>
                  <input matInput formControlName="email" placeholder="Correo Eléctronico" type="text" class="form-input">
                  <mat-icon matSuffix>alternate_email</mat-icon>
                </mat-form-field>
                <div class="text-danger small">{{getError('email')}}</div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Teléfono Móvil</mat-label>
                  <input matInput formControlName="mobile_phone" type="text" class="form-input"  mask="(000) 000-0000" placeholder="Ex. (000) 000-0000">
                  <mat-icon matSuffix>smartphone</mat-icon>
                </mat-form-field>
                <div class="text-danger small">{{getError('mobile_phone')}}</div>
              </div>
            <div class="col-md-12 col-lg-4">
              <div class="margin-1">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>País de Nacimiento</mat-label>
                  <mat-select formControlName="country_origin_id">
                    <mat-option *ngFor="let item of countries" [value]="item.id">
                      {{ item.translations?.es || item.translations?.en }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="text-danger small">{{getError('country_origin_id')}}</div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="form-footer">

        <button color="accent" mat-raised-button type="submit" (click)="register()" class="btn custom-btn mr-1">
          <span *ngIf="!isEditing">Registrar</span>
          <span *ngIf="isEditing">Actualizar</span>
        </button>

        <button (click)="close({completed: false})" color="accent" mat-raised-button class="btn custom-btn-alt">
          Cancelar
        </button>
      </div>

    </div>
  </div>
</div>
