import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolsModule } from './tools/tools.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GeneralLayoutModule } from './core/layout/general-layout/general-layout.module';
import { TokenInterceptor } from '@core/interceptors/token-interceptor.service';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DetailBusinessModule } from './modules/support/pages/business/detail-business/detail-business.module';
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;
import { CurrencyMaskModule } from "ng2-currency-mask";
import { RippleModule } from 'primeng/ripple';
import {ConfirmationService, MessageService} from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ToolsModule,
    HttpClientModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DetailBusinessModule,
    CurrencyMaskModule,
    RippleModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ConfirmationService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    DecimalPipe,
    DatePipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function scrollFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}
