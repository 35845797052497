import { StoreUserModel, UpdateUserModel } from '@admin/models/store-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(id?:number, name?:string, email?:string, companyId?:number, roleId?:number, scopeId?:number): Observable<any> {

    let url = `${this.apiUrl}users/list?`;

    if(id) url += `id=${id}&`;

    if(name) url += `name=${name}&`;

    if(email) url += `email=${email}&`;

    if(companyId) url += `company_id=${companyId}&`;

    if(roleId) url += `role_id=${roleId}&`;

    if(scopeId) url += `scope_id=${scopeId}&`;

    return this.http.get(url);
  }

  create(body:StoreUserModel): Observable<any> {
    let url = `${this.apiUrl}user/store`;
    return this.http.post(url, body);
  }

  update(body:UpdateUserModel, userId:number): Observable<any> {
    let url = `${this.apiUrl}users/update/${userId}`;
    return this.http.post(url, body);
  }

  detail(userId:number): Observable<any>{
    const url = `${this.apiUrl}user/${userId}`
    return this.http.get(url);
  }

  generatePassword(longitud):any {
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var password = '';

    for (var i = 0; i < longitud; i++) {
      var indice = Math.floor(Math.random() * caracteres.length);
      password += caracteres[indice];
    }

    return password;
  }

  delete(userId:number): Observable<any> {
    let url = `${this.apiUrl}user/delete/${userId}`;
    return this.http.delete(url);
  }
}
