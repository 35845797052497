import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovalPaymentsService {

  public apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  get(collectionId: number): Observable<any> {
    let url = `${this.apiUrl}approval-payment/generate-receipt-number/${collectionId}`;
    return this.http.get(url);
  }

  create(body:any): Observable<any> {
    let url = `${this.apiUrl}approval-payment/create`;
    return this.http.post(url, body);
  }

}
