import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

  generateAccountStatement(body:any): Observable<any> {
    let url = `${this.apiUrl}reports/account-statement`;

    return this.http.post(url, body, { responseType: 'blob' });
  }

}
