import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import { ApprovalModalComponent } from '../modules/support/pages/charges/approval-modal/approval-modal.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {FileUploadModule} from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {CardModule} from 'primeng/card';
import {TimelineModule} from 'primeng/timeline';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { ChipModule } from 'primeng/chip';
import {MenuModule} from 'primeng/menu';
import {InputTextModule} from 'primeng/inputtext';
import { ToolsModule } from "@tools/tools.module";
import { ActivityChartComponent } from './activity-chart.component';
import { CreateNoteComponent } from './create-note/create-note.component';
import { DetailNoteComponent } from './detail-note/detail-note.component';
import { TasksModule } from "@support/pages/tasks/tasks.module";
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { UploadBusinessDocumentModule } from "@support/pages/business/detail-business/upload-business-document/upload-business-document.module";
import { CreateAssignmentTaskComponent } from './create-assignment-task/create-assignment-task.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SkeletonModule} from 'primeng/skeleton';


@NgModule({
  declarations: [ActivityChartComponent, CreateNoteComponent, CreateAssignmentTaskComponent, DetailNoteComponent],
  imports: [
    CommonModule,
    ToolsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    DropdownModule,
    InputTextareaModule,
    CardModule,
    TimelineModule,
    ScrollPanelModule,
    ChipModule,
    MenuModule,
    InputTextModule,
    TasksModule,
    ConfirmPopupModule,
    UploadBusinessDocumentModule,
    RadioButtonModule,
    SkeletonModule
  ],
  exports: [ActivityChartComponent, DetailNoteComponent]
})
export class ActivityChartModule { }
