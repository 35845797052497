import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Parking, StatusParking } from '@support/models/parking.model';
import { Project } from '@support/models/project.model';
import { Stage } from '@support/models/stage.model';
import { ParkingService } from '@support/services/parking.service';
import { ProjectService } from '@support/services/project.service';
import { StageService } from '@support/services/stage.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { EMAIL_REGEXP, NAME_PATTERN } from 'src/app/shared/Enums/regexAuth.enum';

@Component({
  selector: 'app-create-parking',
  templateUrl: './create-parking.component.html',
  styleUrls: ['./create-parking.component.scss']
})
export class CreateParkingComponent implements OnInit {

  public form: FormGroup;
  public loading = false;
  public isLoadingProject = false;
  public isLoadingStage = false;
  public isSubmit = false;
  public parkingId: number;
  public parking: Parking;
  public isEditing = false;
  public stages:Stage[];
  public statusParking: StatusParking[];
  public projects: Project[];
  public projectIdSelected:number
  public projectId: number;

  constructor(
    private _stages: StageService,
    private _projects: ProjectService,
    private _parkings: ParkingService,
    private _snackBar: NotificationsService,
    private router:Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<CreateParkingComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init():void{
    this.projectId = this.data.projectId;
    this.createForm();
    this.getProjects();
    this.getStages();
    this.getStatus();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parkingId = Number(params.get('id'));
      if (this.parkingId) {
        this.isEditing = true;
        this.getParking();
      }
    });
    this.parking = this.data.parking;
    if(this.parking && this.data.edit){
      this.completeForm();
      this.isEditing = true;
    }

    if(this.parking && this.data.detail){
      this.completeForm();
      this.disableForm();
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      price: new FormControl(null, Validators.required),
      project_id: new FormControl(this.projectId || '', Validators.required),
      stage_project_id: new FormControl('', Validators.required),
      status_parking_id: new FormControl('', Validators.required)
    });
  }

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
    }
    return '';
  }

  disableForm():void{
    this.form.controls['name'].disable();
    this.form.controls['price'].disable();
    this.form.controls['project_id'].disable();
    this.form.controls['stage_project_id'].disable();
    this.form.controls['status_parking_id'].disable();

  }

  getParking(): void {
    this._parkings.detail(this.parkingId).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.parking = response.data;
          if (this.parking) this.completeForm();
        }
      },
      (error: HttpErrorResponse) => {
        this._snackBar.openSnackBar('Ha ocurrido un error al consultar el detalle del rol', 'OK');
      }
    );
  }

  completeForm(): void {
    this.form.patchValue({
      name: this.parking.name,
      price: this.parking.price,
      project_id: this.parking.project_id.id,
      stage_project_id: this.parking.stage_project_id.id,
      status_parking_id: this.parking.status_parking_id.id
    });
  }

  getProjects():void{
    this.isLoadingProject = true;
    this._projects.get().subscribe(
      (res:any)=>{
      if(res && res.data){
        this.projects = res.data;
      }
      this.isLoadingProject = false;
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this._snackBar.openSnackBar('Error. Ha ocurrido un error al cargar los proyectos.', 'OK');
      this.isLoadingProject = false;
    })
  }

  getStages(projectId=null):void{
    this.isLoadingStage = true;
    this._stages.get(1, projectId).subscribe(
      (res:any)=>{
      if(res && res.data){
        this.stages = res.data;
      }
      this.isLoadingStage = false;
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this._snackBar.openSnackBar('Error. Ha ocurrido un error al cargar los etapas.', 'OK');
      this.isLoadingStage = false;
    })
  }

  getStatus():void{
    this._parkings.status().subscribe(
      (res:any)=>{
      if(res && res.data){
        this.statusParking = res.data;
      }
    },(err:HttpErrorResponse)=>{
      console.log('Error', err);
      this._snackBar.openSnackBar('Error. Ha ocurrido un error al cargar los estados de los parqueos.', 'OK');
    })
  }


  onSelectionChangepProject(event: any) {
    const projectId = event.value;
    this.projectIdSelected = event.value;

   this.getStages(projectId);
  }

  register(): void {
    console.log(this.form.value)
    if (this.form.invalid) {
      this._snackBar.openSnackBar('Por favor, complete los datos del formulario.', 'OK');
      return;
    }
    this.loading = true;

    const url = this.isEditing
    ? this._parkings.update(this.form.value, this.parking.id)
    : this._parkings.create(this.form.value);

    url.subscribe(
      (res: any) => {
        this.loading = false;
        this.close();
        const message = this.isEditing ? 'Parqueo modificado exitosamente.' : 'Parqueo creado exitosamente.'
        this._parkings.parkingListBehavior.next(true);
        this._snackBar.openSnackBar(message, 'OK');
        // this.router.navigate(['/projects']);
      },
      (err: HttpErrorResponse) => {
        console.log(err.status);
        this.loading = false;
        this._snackBar.openSnackBar(err.error.message, 'OK');
      }
    );
  }


  close(): void {
    this.dialogRef.close();
  }

  getErrorMessage (controlName: string): string {

    const field = this.form.get(controlName);

    if(!field || !field.invalid) return ''

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido'
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return (
      field &&
      field.invalid &&
      (field.touched)
    );
  }

}
