import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CreateAdditional } from '@support/models/additional.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdditionalsService {

  public apiUrl = environment.apiUrl;
  public additionalListBehavior = new BehaviorSubject<any>(false);
  public updateAddditionalList = this.additionalListBehavior.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number, projectId?:number): Observable<any> {
    let url = `${this.apiUrl}additional/list?`;
    if(status) url +=`status=${status}&`
    if(projectId) url +=`project_id=${projectId}&`
    return this.http.get(url);
  }

  create(body:CreateAdditional): Observable<any> {
    let url = `${this.apiUrl}additional/create`;
    return this.http.post(url, body);
  }

  update(body:CreateAdditional, additionalId:number): Observable<any> {
    let url = `${this.apiUrl}additional/update/${additionalId}`;
    return this.http.post(url, body);
  }

  detail(additionalId:number): Observable<any> {
    let url = `${this.apiUrl}additional/${additionalId}`;
    return this.http.get(url);
  }

  delete(additionalId:number): Observable<any> {
    let url = `${this.apiUrl}additional/delete/${additionalId}`;
    return this.http.delete(url);
  }
}
