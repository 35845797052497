import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CreateType } from '@support/models/type-adittional.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeAdditionalService {

  public apiUrl = environment.apiUrl;
  public additionalTypeListBehavior = new BehaviorSubject<any>(false);
  public updateAddditionalTypeList = this.additionalTypeListBehavior.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  get(status?:number): Observable<any> {
    let url = `${this.apiUrl}type-additional/list?`;

    if(status) url +=`status=${status}`

    return this.http.get(url);
  }

  create(body:CreateType): Observable<any> {
    let url = `${this.apiUrl}type-additional/create`;
    return this.http.post(url, body);
  }

  update(body:CreateType, additionalId:number): Observable<any> {
    let url = `${this.apiUrl}type-additional/update/${additionalId}`;
    return this.http.post(url, body);
  }

  detail(additionalId:number): Observable<any> {
    let url = `${this.apiUrl}type-additional/${additionalId}`;
    return this.http.get(url);
  }

  delete(additionalId:number): Observable<any> {
    let url = `${this.apiUrl}type-additional/delete/${additionalId}`;
    return this.http.delete(url);
  }

}
