import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

public apiUrl = environment.apiUrl;

constructor(
  private http: HttpClient
) { }

get(params: HttpParams|null = null): Observable<any> {
  let url = `${this.apiUrl}tasks/list`;

  return this.http.get(url,{
    params: params ?? null
  });
}

create(body:FormData): Observable<any> {
  let url = `${this.apiUrl}tasks/create`;
  return this.http.post(url, body);
}

update(body:FormData, tasksId:number): Observable<any> {
  let url = `${this.apiUrl}tasks/update/${tasksId}`;
  return this.http.put(url, body);
}

updateStatus(tasksId: number, status: string): Observable<any> {
  let url = `${this.apiUrl}tasks/status/${tasksId}`;
  return this.http.put(url, { status }); 
}


detail(tasksId:number): Observable<any>{
  const url = `${this.apiUrl}tasks/${tasksId}`
  return this.http.get(url);
}

delete(tasksId:number): Observable<any> {
  let url = `${this.apiUrl}tasks/delete/${tasksId}`;
  return this.http.delete(url);
}

asignment(body:FormData, tasksId:number): Observable<any> {
  let url = `${this.apiUrl}tasks/assign/${tasksId}`;
  return this.http.post(url, body);
}

}
